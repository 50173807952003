/* eslint-disable */
import * as types from './graphql';
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  '\n  fragment FiresTable_ReportData on ReportData {\n    burnedAreaEvents {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.FiresTable_ReportDataFragmentDoc,
  '\n  fragment Report_Report on Report {\n    id\n    type\n    createdAt\n    sections\n    status\n    data {\n      reportMetadata {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n':
    types.Report_ReportFragmentDoc,
  '\n  fragment ClimateChangePage_Report on Report {\n    data {\n      ...ClimateChangeRainfall_ReportData\n      ...ClimateChangeTempMin_ReportData\n      ...ClimateChangeTempMax_ReportData\n    }\n  }\n':
    types.ClimateChangePage_ReportFragmentDoc,
  '\n  fragment ClimateChangeRainfall_ReportData on ReportData {\n    climateSSP126RainfallYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126RainfallYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.ClimateChangeRainfall_ReportDataFragmentDoc,
  '\n  fragment ClimateChangeTempMax_ReportData on ReportData {\n    climateSSP126TempMaxYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMaxYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.ClimateChangeTempMax_ReportDataFragmentDoc,
  '\n  fragment ClimateChangeTempMin_ReportData on ReportData {\n    climateSSP126TempMinYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMinYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.ClimateChangeTempMin_ReportDataFragmentDoc,
  '\n  fragment DeforestationOverTimePage_Report on Report {\n    data {\n      ...DeforestationOverTimeChart_ReportData\n      ...UseDeforestationImages_ReportData\n    }\n  }\n':
    types.DeforestationOverTimePage_ReportFragmentDoc,
  '\n  fragment DeforestationOverTimeChart_ReportData on ReportData {\n    deforestationYearly {\n      result {\n        id\n        values\n      }\n    }\n    deforestationColorMap {\n      result {\n        id\n        values\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.DeforestationOverTimeChart_ReportDataFragmentDoc,
  '\n  fragment UseDeforestationImages_ReportData on ReportData {\n    deforestationYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.UseDeforestationImages_ReportDataFragmentDoc,
  '\n  fragment EnvironmentRiskPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      ...TemperatureMeanHistory_ReportData\n    }\n  }\n':
    types.EnvironmentRiskPage_ReportFragmentDoc,
  '\n  fragment TemperatureMeanHistory_ReportData on ReportData {\n    temperatureMeanHistory {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtd {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.TemperatureMeanHistory_ReportDataFragmentDoc,
  '\n  fragment ForestsAndFiresPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n':
    types.ForestsAndFiresPage_ReportFragmentDoc,
  '\n  fragment GrassAndForestFireRiskPage_Report on Report {\n    data {\n      ...FireRiskChart_ReportData\n      ...TemperatureMeanHistory_ReportData\n      grasslandFireMaximum {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n':
    types.GrassAndForestFireRiskPage_ReportFragmentDoc,
  '\n  fragment FireRiskChart_ReportData on ReportData {\n    grasslandFireMonthly {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.FireRiskChart_ReportDataFragmentDoc,
  '\n  fragment LandAndNaturePage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n      landCoverClasses {\n        result {\n          id\n          values\n        }\n      }\n      landCoverClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n':
    types.LandAndNaturePage_ReportFragmentDoc,
  '\n  fragment LandUsePage_Report on Report {\n    data {\n      ...LandUse_ReportData\n      ...PropertyDetailsLandUseTable_ReportData\n      ...UseTerrainSummary_ReportData\n    }\n  }\n':
    types.LandUsePage_ReportFragmentDoc,
  '\n  fragment PropertyDetailsLandUseTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.PropertyDetailsLandUseTable_ReportDataFragmentDoc,
  '\n  fragment LandUse_ReportData on ReportData {\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.LandUse_ReportDataFragmentDoc,
  '\n  fragment UseTerrainSummary_ReportData on ReportData {\n    satelliteRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    landUseClassesImage {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    satelliteNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n  }\n':
    types.UseTerrainSummary_ReportDataFragmentDoc,
  '\n  fragment OverviewPage_Report on Report {\n    data {\n      ...OverviewMap_ReportData\n      ...PropertyDetailsTable_ReportData\n      ...LandmarksTable_ReportData\n    }\n  }\n':
    types.OverviewPage_ReportFragmentDoc,
  '\n  fragment LandmarksTable_ReportData on ReportData {\n    landmarksTowns {\n      result {\n        id\n        values\n      }\n    }\n    landmarksCities {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSchools {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSilos {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.LandmarksTable_ReportDataFragmentDoc,
  '\n  fragment OverviewMap_ReportData on ReportData {\n    propertyParcelsGeoJson {\n      result {\n        id\n        values\n      }\n    }\n    buildingsGeometries {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.OverviewMap_ReportDataFragmentDoc,
  '\n  fragment PropertyDetailsTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n    landmarksLocation {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.PropertyDetailsTable_ReportDataFragmentDoc,
  '\n  fragment ParcelsAndBuildingsPage_Report on Report {\n    data {\n      propertyParcelsList {\n        result {\n          id\n          values\n        }\n      }\n      buildingsList {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n':
    types.ParcelsAndBuildingsPage_ReportFragmentDoc,
  '\n  fragment ProductivityPage_Report on Report {\n    data {\n      ...EviAreaProductivity_ReportData\n      ...EviAreaAndRainfall_ReportData\n      ...YieldCropTypes_ReportData\n    }\n  }\n':
    types.ProductivityPage_ReportFragmentDoc,
  '\n  fragment EviAreaAndRainfall_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.EviAreaAndRainfall_ReportDataFragmentDoc,
  '\n  fragment EviAreaProductivity_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.EviAreaProductivity_ReportDataFragmentDoc,
  '\n  fragment YieldCropTypes_ReportData on ReportData {\n    yieldModelYearly {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.YieldCropTypes_ReportDataFragmentDoc,
  '\n  fragment PropertyAndClimateSummaryPage_Report on Report {\n    data {\n      ...SummaryTable_ReportData\n      ...ProductivityCard_ReportData\n      ...AnnualRainfallCard_ReportData\n      ...ClimateRiskCard_ReportData\n      elevationArableImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      landUseClassesImage {\n        result {\n          files {\n            name\n            location\n          }\n        }\n      }\n      landUseClasses {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n':
    types.PropertyAndClimateSummaryPage_ReportFragmentDoc,
  '\n  fragment AnnualRainfallCard_ReportData on ReportData {\n    rainfallStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.AnnualRainfallCard_ReportDataFragmentDoc,
  '\n  fragment ClimateRiskCard_ReportData on ReportData {\n    climateRiskStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.ClimateRiskCard_ReportDataFragmentDoc,
  '\n  fragment ProductivityCard_ReportData on ReportData {\n    yieldModelDistribution {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.ProductivityCard_ReportDataFragmentDoc,
  '\n  fragment SummaryTable_ReportData on ReportData {\n    elevationArable {\n      result {\n        id\n        values\n      }\n    }\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n    soilAusClasses {\n      result {\n        id\n        values\n      }\n    }\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SummaryTable_ReportDataFragmentDoc,
  '\n  fragment RainfallPage_Report on Report {\n    data {\n      ...RainfallDuration_ReportData\n      ...RainfallVolume_ReportData\n      ...SeasonalRainfallHistory_ReportData\n      ...RainfallHistory_ReportData\n    }\n  }\n':
    types.RainfallPage_ReportFragmentDoc,
  '\n  fragment RainfallDuration_ReportData on ReportData {\n    rainfallDuration {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.RainfallDuration_ReportDataFragmentDoc,
  '\n  fragment RainfallHistory_ReportData on ReportData {\n    rainfallHistory {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtd {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.RainfallHistory_ReportDataFragmentDoc,
  '\n  fragment RainfallVolume_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.RainfallVolume_ReportDataFragmentDoc,
  '\n  fragment SeasonalRainfallHistory_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SeasonalRainfallHistory_ReportDataFragmentDoc,
  '\n  fragment SalesPage_Report on Report {\n    ...RecentSalesPriceChart_Report\n    data {\n      salesPropertyHistory {\n        result {\n          id\n          values\n        }\n      }\n      ...RecentSalesPriceChart_ReportData\n      ...RecentSalesPricePerAreaChart_ReportData\n      ...PricePerHectareOverTimeChart_ReportData\n      ...NumberOfSalesOverTimeChart_ReportData\n      ...PropertyPastSalesTable_ReportData\n    }\n  }\n':
    types.SalesPage_ReportFragmentDoc,
  '\n  fragment NumberOfSalesOverTimeChart_ReportData on ReportData {\n    salesSaleCountHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.NumberOfSalesOverTimeChart_ReportDataFragmentDoc,
  '\n  fragment PricePerHectareOverTimeChart_ReportData on ReportData {\n    salesAveragePriceHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.PricePerHectareOverTimeChart_ReportDataFragmentDoc,
  '\n  fragment PropertyPastSalesTable_ReportData on ReportData {\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.PropertyPastSalesTable_ReportDataFragmentDoc,
  '\n  fragment RecentSalesPriceChart_ReportData on ReportData {\n    salesPriceDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.RecentSalesPriceChart_ReportDataFragmentDoc,
  '\n  fragment RecentSalesPriceChart_Report on Report {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n':
    types.RecentSalesPriceChart_ReportFragmentDoc,
  '\n  fragment RecentSalesPricePerAreaChart_ReportData on ReportData {\n    salesPricePerAreaDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.RecentSalesPricePerAreaChart_ReportDataFragmentDoc,
  '\n  fragment SoilProfilePage_Report on Report {\n    data {\n      ...SoilTexture_ReportData\n      ...SoilOrganicCarbon_ReportData\n      ...SoilPh_ReportData\n      ...SoilAwc_ReportData\n    }\n  }\n':
    types.SoilProfilePage_ReportFragmentDoc,
  '\n  fragment SoilAwc_ReportData on ReportData {\n    soilAwcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SoilAwc_ReportDataFragmentDoc,
  '\n  fragment SoilOrganicCarbon_ReportData on ReportData {\n    soilOcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SoilOrganicCarbon_ReportDataFragmentDoc,
  '\n  fragment SoilPh_ReportData on ReportData {\n    soilPhProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SoilPh_ReportDataFragmentDoc,
  '\n  fragment SoilTexture_ReportData on ReportData {\n    soilSandProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilSiltProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilClayProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.SoilTexture_ReportDataFragmentDoc,
  '\n  fragment SoilTaxonomyPage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClasses {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n':
    types.SoilTaxonomyPage_ReportFragmentDoc,
  '\n  fragment SoilTexturePage_Report on Report {\n    data {\n      soilOcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilPhImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilAwcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n':
    types.SoilTexturePage_ReportFragmentDoc,
  '\n  fragment TableOfContentsPage_Report on Report {\n    id\n    sections\n  }\n':
    types.TableOfContentsPage_ReportFragmentDoc,
  '\n  fragment TemperatureEventsPage_Report on Report {\n    data {\n      ...HeatEventsVolume_ReportData\n      ...FrostEventsVolume_ReportData\n      ...HeatEventsHistory_ReportData\n      ...FrostEventsHistory_ReportData\n    }\n  }\n':
    types.TemperatureEventsPage_ReportFragmentDoc,
  '\n  fragment FrostEventsHistory_ReportData on ReportData {\n    frostEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.FrostEventsHistory_ReportDataFragmentDoc,
  '\n  fragment FrostEventsVolume_ReportData on ReportData {\n    frostEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.FrostEventsVolume_ReportDataFragmentDoc,
  '\n  fragment HeatEventsHistory_ReportData on ReportData {\n    heatEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.HeatEventsHistory_ReportDataFragmentDoc,
  '\n  fragment HeatEventsVolume_ReportData on ReportData {\n    heatEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.HeatEventsVolume_ReportDataFragmentDoc,
  '\n  fragment TerrainPage_Report on Report {\n    data {\n      ...Elevation_ReportData\n      ...Slope_ReportData\n\n      elevationElevationImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationSlopeImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationStatistics {\n        result {\n          id\n          values\n        }\n      }\n      elevationSlope {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n':
    types.TerrainPage_ReportFragmentDoc,
  '\n  fragment Elevation_ReportData on ReportData {\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.Elevation_ReportDataFragmentDoc,
  '\n  fragment Slope_ReportData on ReportData {\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n':
    types.Slope_ReportDataFragmentDoc,
  '\n  fragment ViewReport_Report on Report {\n    id\n    teamId\n    status\n    type\n    ...Report_Report\n\n    # Pages\n    ...ParcelsAndBuildingsPage_Report\n    ...TableOfContentsPage_Report\n    ...OverviewPage_Report\n    ...PropertyAndClimateSummaryPage_Report\n    ...LandUsePage_Report\n    ...ProductivityPage_Report\n    ...RainfallPage_Report\n    ...TerrainPage_Report\n    ...SoilTaxonomyPage_Report\n    ...SoilTexturePage_Report\n    ...SoilProfilePage_Report\n    ...LandAndNaturePage_Report\n    ...GrassAndForestFireRiskPage_Report\n    ...EnvironmentRiskPage_Report\n    ...ClimateChangePage_Report\n    ...TemperatureEventsPage_Report\n    ...DeforestationOverTimePage_Report\n    ...ForestsAndFiresPage_Report\n    ...SalesPage_Report\n  }\n':
    types.ViewReport_ReportFragmentDoc,
  '\n  query GetReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...ViewReport_Report\n    }\n  }\n':
    types.GetReportDocument,
  '\n  mutation CreateMapShapeFromManageMapAreaButton($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n    }\n  }\n':
    types.CreateMapShapeFromManageMapAreaButtonDocument,
  '\n  fragment ComparableSaleAsideLayoutComparableSale on ComparableSale {\n    name\n    sale {\n      id\n      price\n      contractDate\n      propertiesInSale {\n        property {\n          id\n          addressLine1\n          propertyParcels {\n            parcel {\n              id\n              hectares\n              latitude\n              longitude\n              legalNumber\n            }\n          }\n        }\n      }\n    }\n  }\n':
    types.ComparableSaleAsideLayoutComparableSaleFragmentDoc,
  '\n  query GetComparableSale($propertyId: ID!, $saleId: ID!) {\n    comparableSale(input: { propertyId: $propertyId, saleId: $saleId }) {\n      ...ComparableSaleAsideLayoutComparableSale\n    }\n  }\n':
    types.GetComparableSaleDocument,
  '\n  fragment PortfolioLayoutPropertyCoordinates_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n':
    types.PortfolioLayoutPropertyCoordinates_PortfolioFragmentDoc,
  '\n  fragment PortfolioLayoutPropertyList_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n      }\n    }\n  }\n':
    types.PortfolioLayoutPropertyList_PortfolioFragmentDoc,
  '\n  fragment PortfolioLayoutPortals_Portfolio on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n':
    types.PortfolioLayoutPortals_PortfolioFragmentDoc,
  '\n  query GetPortfolioForLayout($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioLayoutPortals_Portfolio\n    }\n  }\n':
    types.GetPortfolioForLayoutDocument,
  '\n  fragment PropertyLayoutPortals_PropertyFragment on Property {\n    id\n    name\n    ...PropertyLayoutParcelGeometries_PropertyFragment\n  }\n':
    types.PropertyLayoutPortals_PropertyFragmentFragmentDoc,
  '\n  query GetPropertyForLayout($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyLayoutPortals_PropertyFragment\n    }\n  }\n':
    types.GetPropertyForLayoutDocument,
  '\n    fragment PropertyLayoutParcelGeometries_PropertyFragment on Property {\n      propertyParcels {\n        propertyId\n        parcelId\n        name\n        geometry\n      }\n    }\n  ':
    types.PropertyLayoutParcelGeometries_PropertyFragmentFragmentDoc,
  '\n  fragment FeedDrySeasonMix_FeedFragment on Feed {\n    id\n    drySeasonMixKg\n    drySeasonMixUreaPercentage\n  }\n':
    types.FeedDrySeasonMix_FeedFragmentFragmentDoc,
  '\n  fragment FeedMineralBlock_FeedFragment on Feed {\n    id\n    mineralBlockKg\n    mineralBlockUreaPercentage\n  }\n':
    types.FeedMineralBlock_FeedFragmentFragmentDoc,
  '\n  fragment FeedWeanerBlock_FeedFragment on Feed {\n    id\n    weanerBlockKg\n    weanerBlockUreaPercentage\n  }\n':
    types.FeedWeanerBlock_FeedFragmentFragmentDoc,
  '\n  mutation updateFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.UpdateFeedWeanerDocument,
  '\n  mutation updateFeedDrySeasonMix(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n':
    types.UpdateFeedDrySeasonMixDocument,
  '\n  mutation updateFeedMineralBlock(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n':
    types.UpdateFeedMineralBlockDocument,
  '\n  fragment MapShapesList_MapShape on MapShape {\n    id\n    ...MapShapesListItem_MapShape\n  }\n':
    types.MapShapesList_MapShapeFragmentDoc,
  '\n  fragment MapShapesListItem_MapShape on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n':
    types.MapShapesListItem_MapShapeFragmentDoc,
  '\n  fragment SelectMapShapeFromLibraryModal_MapShape on MapShape {\n    id\n    geometry\n    ...MapShapesList_MapShape\n  }\n':
    types.SelectMapShapeFromLibraryModal_MapShapeFragmentDoc,
  '\n  query GetMapShapesForSelectMapShapeFromLibraryModal($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...SelectMapShapeFromLibraryModal_MapShape\n    }\n  }\n':
    types.GetMapShapesForSelectMapShapeFromLibraryModalDocument,
  '\n  fragment AdvisorDashboardTeamsFragment on Team {\n    id\n    name\n    organisation {\n      id\n      name\n      ownerId\n    }\n    properties {\n      id\n      name\n    }\n  }\n':
    types.AdvisorDashboardTeamsFragmentFragmentDoc,
  '\n  query GetTeamsForAdvisorDashboard($userId: ID!) {\n    advisorTeams(userId: $userId) {\n      ...AdvisorDashboardTeamsFragment\n    }\n  }\n':
    types.GetTeamsForAdvisorDashboardDocument,
  '\n  mutation createAdvisorInviteForUser(\n    $createAdvisorInvitationInput: CreateAdvisorInvitationInput!\n  ) {\n    createAdvisorInvitation(input: $createAdvisorInvitationInput) {\n      id\n    }\n  }\n':
    types.CreateAdvisorInviteForUserDocument,
  '\n  fragment AllPropertiesForEditPortfolioFormFragment on Property {\n    id\n    name\n    centroid\n    bbox\n  }\n':
    types.AllPropertiesForEditPortfolioFormFragmentFragmentDoc,
  '\n  fragment PortfolioEditPage_PortfolioFragment on Portfolio {\n    name\n    portfolioProperties {\n      property {\n        id\n        name\n        centroid\n        bbox\n      }\n    }\n  }\n':
    types.PortfolioEditPage_PortfolioFragmentFragmentDoc,
  '\n  query GetPortfolioForEdit($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n':
    types.GetPortfolioForEditDocument,
  '\n  query GetPropertiesForAddToPortfolio($teamId: ID!) {\n    properties(teamId: $teamId, orderBy: { name: asc }) {\n      id\n      ...AllPropertiesForEditPortfolioFormFragment\n    }\n  }\n':
    types.GetPropertiesForAddToPortfolioDocument,
  '\n  mutation UpdatePortfolio(\n    $id: ID!\n    $updatePortfolioInput: UpdatePortfolioInput!\n  ) {\n    updatePortfolio(id: $id, input: $updatePortfolioInput) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n':
    types.UpdatePortfolioDocument,
  '\n  mutation DeletePortfolio($id: ID!) {\n    deletePortfolio(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePortfolioDocument,
  '\n  fragment PortfolioPage_PortfolioFragment on Portfolio {\n    name\n  }\n':
    types.PortfolioPage_PortfolioFragmentFragmentDoc,
  '\n  query GetPortfolio($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioPage_PortfolioFragment\n    }\n  }\n':
    types.GetPortfolioDocument,
  '\n  fragment TeamPortfoliosFragment on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n    }\n  }\n':
    types.TeamPortfoliosFragmentFragmentDoc,
  '\n  mutation CreatePortfolio($input: CreatePortfolioInput!) {\n    createPortfolio(input: $input) {\n      id\n    }\n  }\n':
    types.CreatePortfolioDocument,
  '\n  query GetPortfoliosForTeam($teamId: ID!) {\n    portfoliosForTeam(teamId: $teamId) {\n      ...TeamPortfoliosFragment\n    }\n  }\n':
    types.GetPortfoliosForTeamDocument,
  '\n  fragment CmaAssessmentAnalysisPage_Property on Property {\n    ...RecentSalesPriceChart_Property\n    ...RecentSalesPricePerAreaChart_Property\n    salesSummary {\n      ...PropertyPastSalesTable_PropertySalesData\n      ...NumberOfSalesOverTimeChart_PropertySalesData\n      ...PricePerHectareOverTimeChart_PropertySalesData\n      ...RecentSalesPriceChart_PropertySalesData\n      ...RecentSalesPricePerAreaChart_PropertySalesData\n    }\n  }\n':
    types.CmaAssessmentAnalysisPage_PropertyFragmentDoc,
  '\n  fragment NumberOfSalesOverTimeChart_PropertySalesData on PropertySalesData {\n    summarySaleCountHistory {\n      result\n    }\n  }\n':
    types.NumberOfSalesOverTimeChart_PropertySalesDataFragmentDoc,
  '\n    fragment PricePerHectareOverTimeChart_PropertySalesData on PropertySalesData {\n      summaryAveragePriceHistory {\n        result\n      }\n    }\n  ':
    types.PricePerHectareOverTimeChart_PropertySalesDataFragmentDoc,
  '\n  fragment PropertyPastSalesTable_PropertySalesData on PropertySalesData {\n    propertySalesHistory {\n      result\n    }\n  }\n':
    types.PropertyPastSalesTable_PropertySalesDataFragmentDoc,
  '\n  fragment RecentSalesPriceChart_PropertySalesData on PropertySalesData {\n    summaryPriceDistribution {\n      result\n    }\n    propertySalesHistory {\n      result\n    }\n  }\n':
    types.RecentSalesPriceChart_PropertySalesDataFragmentDoc,
  '\n  fragment RecentSalesPriceChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n':
    types.RecentSalesPriceChart_PropertyFragmentDoc,
  '\n    fragment RecentSalesPricePerAreaChart_PropertySalesData on PropertySalesData {\n      summaryPricePerAreaDistribution {\n        result\n      }\n      propertySalesHistory {\n        result\n      }\n    }\n  ':
    types.RecentSalesPricePerAreaChart_PropertySalesDataFragmentDoc,
  '\n  fragment RecentSalesPricePerAreaChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n':
    types.RecentSalesPricePerAreaChart_PropertyFragmentDoc,
  '\n  query GetPropertyForCmaCharts($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...CmaAssessmentAnalysisPage_Property\n    }\n  }\n':
    types.GetPropertyForCmaChartsDocument,
  '\n  fragment PropertyComparableSaleFragment on ComparableSale {\n    propertyId\n    saleId\n    sale {\n      id\n      price\n      contractDate\n      address\n      area\n      notes\n      landUses {\n        id\n        landUse\n        area\n        pricePerHectare\n      }\n    }\n    property {\n      id\n    }\n  }\n':
    types.PropertyComparableSaleFragmentFragmentDoc,
  '\n  query GetComparableSaleOnProperty($input: GetComparableSaleInput!) {\n    comparableSale(input: $input) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n':
    types.GetComparableSaleOnPropertyDocument,
  '\n  mutation UpdateComparableSale(\n    $propertyId: ID!\n    $saleId: ID!\n    $input: UpdateComparableSaleInput!\n  ) {\n    updateComparableSale(\n      propertyId: $propertyId\n      saleId: $saleId\n      input: $input\n    ) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n':
    types.UpdateComparableSaleDocument,
  '\n  fragment CmaAssessmentSalesSearchResults on ComparableSaleSearchResult {\n    latitude\n    longitude\n    price\n    saleDate\n    address {\n      address\n    }\n    boundingGeoJson\n    bbox\n    source\n    sourceRef\n    similarity\n    area\n    landUse\n    rainfall\n  }\n':
    types.CmaAssessmentSalesSearchResultsFragmentDoc,
  '\n  fragment CmaAssessmentPropertyComparableSalesFragment on Property {\n    comparableSales {\n      name\n      similarity\n      propertyId\n      saleId\n      sale {\n        id\n        externalId\n        price\n        contractDate\n        centroid\n        address\n        bbox\n        geog\n      }\n      property {\n        id\n      }\n    }\n  }\n':
    types.CmaAssessmentPropertyComparableSalesFragmentFragmentDoc,
  '\n  fragment CmaAssessmentPropertyCMAVectorFragment on Property {\n    cmaVector\n  }\n':
    types.CmaAssessmentPropertyCmaVectorFragmentFragmentDoc,
  '\n  query GetPropertyComparableSales($id: ID!) {\n    property(id: $id) {\n      id\n      ...CmaAssessmentPropertyComparableSalesFragment\n      ...CmaAssessmentPropertyCMAVectorFragment\n    }\n  }\n':
    types.GetPropertyComparableSalesDocument,
  '\n  query GetPropertyComparableSaleSearchResults(\n    $propertyId: String!\n    $similarity: Percent!\n    $radius: Int!\n    $startDate: Date\n    $endDate: Date\n    $minPrice: Int\n    $maxPrice: Int\n    $minArea: Int\n    $maxArea: Int\n    $orderBy: ComparableSaleSearchOrderBy\n  ) {\n    comparableSalesSearch(\n      propertyId: $propertyId\n      similarity: $similarity\n      radius: $radius\n      startDate: $startDate\n      endDate: $endDate\n      minPrice: $minPrice\n      maxPrice: $maxPrice\n      minArea: $minArea\n      maxArea: $maxArea\n      orderBy: $orderBy\n    ) {\n      ...CmaAssessmentSalesSearchResults\n    }\n  }\n':
    types.GetPropertyComparableSaleSearchResultsDocument,
  '\n  mutation saveComparableSalesForProperty(\n    $propertyId: ID!\n    $input: [SaveComparableSaleInput!]!\n  ) {\n    saveComparableSales(propertyId: $propertyId, input: $input) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n':
    types.SaveComparableSalesForPropertyDocument,
  '\n  mutation deleteComparableSaleForProperty($propertyId: ID!, $saleId: ID!) {\n    deleteComparableSale(propertyId: $propertyId, saleId: $saleId) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n':
    types.DeleteComparableSaleForPropertyDocument,
  '\n  fragment CmaAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n':
    types.CmaAssessmentReportsEditPage_ReportFragmentDoc,
  '\n  mutation UpdateCmaReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...CmaAssessmentReportsPage_Report\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.UpdateCmaReportDocument,
  '\n  mutation DeleteCmaReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteCmaReportDocument,
  '\n  query GetCmaReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.GetCmaReportDocument,
  '\n  fragment CmaAssessmentReportsPage_Report on Report {\n    id\n    ...CmaReportListItem_Report\n  }\n':
    types.CmaAssessmentReportsPage_ReportFragmentDoc,
  '\n  fragment CmaReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n':
    types.CmaReportListItem_ReportFragmentDoc,
  '\n  fragment CmaReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n':
    types.CmaReportListItem_ReportPdfLinkFragmentDoc,
  '\n  query GetCmaReports($propertyId: ID!) {\n    cmaReports(propertyId: $propertyId) {\n      id\n      ...CmaAssessmentReportsPage_Report\n    }\n  }\n':
    types.GetCmaReportsDocument,
  '\n  query GetCmaReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaReportListItem_ReportPdfLink\n    }\n  }\n':
    types.GetCmaReportSignedUrlDocument,
  '\n  mutation CreateCmaReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n':
    types.CreateCmaReportDocument,
  '\n  fragment DeforestationAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n':
    types.DeforestationAssessmentReportsEditPage_ReportFragmentDoc,
  '\n  mutation UpdateDeforestationReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.UpdateDeforestationReportDocument,
  '\n  mutation DeleteDeforestationReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteDeforestationReportDocument,
  '\n  query GetDeforestationReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.GetDeforestationReportDocument,
  '\n  fragment DeforestationAssessmentReportsPage_Report on Report {\n    id\n    ...DeforestationReportListItem_Report\n  }\n':
    types.DeforestationAssessmentReportsPage_ReportFragmentDoc,
  '\n  fragment DeforestationReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n':
    types.DeforestationReportListItem_ReportFragmentDoc,
  '\n  fragment DeforestationReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n':
    types.DeforestationReportListItem_ReportPdfLinkFragmentDoc,
  '\n  query GetDeforestationReports($propertyId: ID!) {\n    deforestationReports(propertyId: $propertyId) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n    }\n  }\n':
    types.GetDeforestationReportsDocument,
  '\n  query GetDeforestationReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationReportListItem_ReportPdfLink\n    }\n  }\n':
    types.GetDeforestationReportSignedUrlDocument,
  '\n  mutation CreateDeforestationReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n':
    types.CreateDeforestationReportDocument,
  '\n    fragment AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n      ...AllTotalGhgEmissions_AllEmissionsCalculationResultFragment\n      ...AllBreakdownGhg_AllEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment AllBreakdownGhg_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.AllBreakdownGhg_AllEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment AllTotalGhgEmissions_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n    }\n    purchasedOffsets {\n      total\n    }\n    net {\n      total\n    }\n  }\n':
    types.AllTotalGhgEmissions_AllEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetAllEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    allEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetAllEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...BeefIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ':
    types.BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment BeefIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n':
    types.BeefIntensity_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        beef {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetBeefEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    beefEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetBeefEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      ...BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloIntensity_BuffaloEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ':
    types.BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment BuffaloIntensity_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    intensities {\n      buffaloMeatIncludingSequestration\n      buffaloMeatExcludingSequestration\n    }\n  }\n':
    types.BuffaloIntensity_BuffaloEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetBuffaloEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    buffaloEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetBuffaloEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      ...CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment\n      ...CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment\n      ...CottonBreakdownGhg_CottonEmissionsCalculationResultFragment\n      ...CottonIntensity_CottonEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment CottonBreakdownGhg_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.CottonBreakdownGhg_CottonEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ':
    types.CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment CottonIntensity_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    intensities {\n      balesExcludingSequestration\n      balesIncludingSequestration\n      tonnesCropExcludingSequestration\n      tonnesCropIncludingSequestration\n      lintIncludingSequestration\n      lintExcludingSequestration\n      seedIncludingSequestration\n      seedExcludingSequestration\n      lintEconomicAllocation\n      seedEconomicAllocation\n    }\n  }\n':
    types.CottonIntensity_CottonEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetCottonEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    cottonEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetCottonEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      ...DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment\n      ...DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment\n      ...DairyBreakdownGhg_DairyEmissionsCalculationResultFragment\n      ...DairyIntensity_DairyEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DairyBreakdownGhg_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.DairyBreakdownGhg_DairyEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ':
    types.DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DairyIntensity_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    intensities {\n      intensity\n    }\n  }\n':
    types.DairyIntensity_DairyEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetDairyEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    dairyEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetDairyEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n      ...DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment\n      ...DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment\n      ...DeerBreakdownGhg_DeerEmissionsCalculationResultFragment\n      ...DeerIntensity_DeerEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DeerBreakdownGhg_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.DeerBreakdownGhg_DeerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      limeCO2\n      ureaCO2\n      transportCO2\n      fuelCH4\n      transportCH4\n      entericCH4\n      manureManagementCH4\n      atmosphericDepositionN2O\n      manureManagementN2O\n      animalWasteN2O\n      fertiliserN2O\n      urineAndDungN2O\n      leachingAndRunoffN2O\n      fuelN2O\n      transportN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n':
    types.DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DeerIntensity_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    intensities {\n      deerMeatIncludingSequestration\n      deerMeatExcludingSequestration\n    }\n  }\n':
    types.DeerIntensity_DeerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n':
    types.DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetDeerEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    deerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetDeerEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      ...FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotIntensity_FeedlotEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        entericCH4\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        limeCO2\n        manureAppliedToSoilN2O\n        manureDirectN2O\n        manureIndirectN2O\n        manureManagementCH4\n        transportCH4\n        transportCO2\n        transportN2O\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n        feed\n        purchaseLivestock\n      }\n    }\n  ':
    types.FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment FeedlotIntensity_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n':
    types.FeedlotIntensity_FeedlotEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      intermediate {\n        carbonSequestration\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetFeedlotEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    feedlotEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetFeedlotEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n      ...GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment\n      ...GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment\n      ...GoatBreakdownGhg_GoatEmissionsCalculationResultFragment\n      ...GoatIntensity_GoatEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GoatBreakdownGhg_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.GoatBreakdownGhg_GoatEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      urineAndDungN2O\n      atmosphericDepositionN2O\n      leachingAndRunoffN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedMineralSupplementation\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n':
    types.GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GoatIntensity_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    intensities {\n      goatMeatBreedingIncludingSequestration\n      goatMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n':
    types.GoatIntensity_GoatEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n':
    types.GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetGoatEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    goatEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetGoatEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      ...GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment\n      ...GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment\n      ...GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment\n      ...GrainsIntensity_GrainsEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ':
    types.GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment GrainsIntensity_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    intensitiesWithSequestration {\n      grainsIncludingSequestration\n      grainsExcludingSequestration\n    }\n  }\n':
    types.GrainsIntensity_GrainsEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetGrainsEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    grainsEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetGrainsEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      ...HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureIntensity_HorticultureEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ':
    types.HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ':
    types.HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment HorticultureIntensity_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      intensities {\n        tonnesCropIncludingSequestration\n        tonnesCropExcludingSequestration\n      }\n    }\n  ':
    types.HorticultureIntensity_HorticultureEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetHorticultureEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    horticultureEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetHorticultureEmissionsDocument,
  '\n    fragment AssessmentGhgEmissions_EmissionsCalculationInputsFragment on EmissionsCalculationInputs {\n      requests {\n        endpoint\n        payload\n      }\n      primaryActivities {\n        id\n        name\n        start\n        end\n        activityType\n        cropping {\n          croppingType\n        }\n      }\n    }\n  ':
    types.AssessmentGhgEmissions_EmissionsCalculationInputsFragmentFragmentDoc,
  '\n  fragment EmissionsAssessmentLayout_PropertyFragment on Property {\n    name\n  }\n':
    types.EmissionsAssessmentLayout_PropertyFragmentFragmentDoc,
  '\n  query GetPropertyForEmissionsAssessmentLayout($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...EmissionsAssessmentLayout_PropertyFragment\n    }\n  }\n':
    types.GetPropertyForEmissionsAssessmentLayoutDocument,
  '\n    fragment AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n      ...PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment\n      ...PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment\n      ...PorkBreakdownGhg_PorkEmissionsCalculationResultFragment\n      ...PorkIntensity_PorkEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment PorkBreakdownGhg_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.PorkBreakdownGhg_PorkEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      manureManagementDirectN2O\n      atmosphericDepositionN2O\n      atmosphericDepositionIndirectN2O\n      leachingAndRunoffSoilN2O\n      leachingAndRunoffMMSN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      bedding\n      purchasedLivestock\n    }\n  }\n':
    types.PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment PorkIntensity_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    intensities {\n      porkMeatIncludingSequestration\n      porkMeatExcludingSequestration\n    }\n  }\n':
    types.PorkIntensity_PorkEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n':
    types.PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetPorkEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    porkEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetPorkEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ':
    types.PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  ':
    types.PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryMeatIncludingSequestration\n        poultryMeatExcludingSequestration\n      }\n    }\n  ':
    types.PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetPoultryBroilerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryBroilerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetPoultryBroilerEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ':
    types.PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  ':
    types.PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryEggsIncludingSequestration\n        poultryEggsExcludingSequestration\n      }\n    }\n  ':
    types.PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetPoultryLayerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryLayerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetPoultryLayerEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...SheepIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ':
    types.SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment SheepIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      sheepMeatBreedingIncludingSequestration\n      sheepMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n':
    types.SheepIntensity_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        sheep {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetSheepEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sheepEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetSheepEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      ...SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment\n      ...SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment\n      ...SugarBreakdownGhg_SugarEmissionsCalculationResultFragment\n      ...SugarIntensity_SugarEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment SugarBreakdownGhg_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n':
    types.SugarBreakdownGhg_SugarEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        limeCO2\n        fertiliserN2O\n        atmosphericDepositionN2O\n        leachingAndRunoffN2O\n        cropResidueN2O\n        fieldBurningN2O\n        fieldBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ':
    types.SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragmentFragmentDoc,
  '\n  fragment SugarIntensity_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    intensities {\n      sugarIncludingSequestration\n      sugarExcludingSequestration\n    }\n  }\n':
    types.SugarIntensity_SugarEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ':
    types.SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetSugarEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sugarEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetSugarEmissionsDocument,
  '\n    fragment AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      ...WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment\n    }\n  ':
    types.AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n        totalHFCs\n      }\n    }\n  ':
    types.WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        hfcsRefrigerantLeakage\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        bait\n      }\n    }\n  ':
    types.WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      intensities {\n        intensityIncludingCarbonOffset\n        intensityExcludingCarbonOffset\n      }\n    }\n  ':
    types.WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc,
  '\n    fragment WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      net {\n        total\n      }\n      purchasedOffsets {\n        total\n      }\n    }\n  ':
    types.WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragmentFragmentDoc,
  '\n  query GetWildSeaFisheryEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    wildSeaFisheryEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n':
    types.GetWildSeaFisheryEmissionsDocument,
  '\n  fragment KyeAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n':
    types.KyeAssessmentReportsEditPage_ReportFragmentDoc,
  '\n  mutation UpdateKyeReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...KyeAssessmentReportsPage_Report\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.UpdateKyeReportDocument,
  '\n  mutation DeleteKyeReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteKyeReportDocument,
  '\n  query GetKyeReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n':
    types.GetKyeReportDocument,
  '\n  fragment KyeAssessmentReportsPage_Report on Report {\n    id\n    ...KyeReportListItem_Report\n  }\n':
    types.KyeAssessmentReportsPage_ReportFragmentDoc,
  '\n  fragment KyeReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n':
    types.KyeReportListItem_ReportFragmentDoc,
  '\n  fragment KyeReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n':
    types.KyeReportListItem_ReportPdfLinkFragmentDoc,
  '\n  query GetKyeReports($propertyId: ID!) {\n    kyeReports(propertyId: $propertyId) {\n      id\n      ...KyeAssessmentReportsPage_Report\n    }\n  }\n':
    types.GetKyeReportsDocument,
  '\n  query GetKyeReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeReportListItem_ReportPdfLink\n    }\n  }\n':
    types.GetKyeReportSignedUrlDocument,
  '\n  mutation CreateKyeReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n':
    types.CreateKyeReportDocument,
  '\n  fragment PropertiesBusinessActivitiesAllocationIndexFragment on Activity {\n    __typename\n    id\n    start\n    end\n    name\n    targetAllocations {\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n      percentage\n    }\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n':
    types.PropertiesBusinessActivitiesAllocationIndexFragmentFragmentDoc,
  '\n  fragment BusinessActivitiesAllocationConsumptionEditFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    consumptionAllocations {\n      percentage\n      sourceConsumption {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n':
    types.BusinessActivitiesAllocationConsumptionEditFragmentFragmentDoc,
  '\n  fragment AllocationIndexActivitiesFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    targetAllocations {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n    ... on CroppingActivity {\n      croppingType\n      varieties {\n        yieldInKg\n      }\n    }\n  }\n':
    types.AllocationIndexActivitiesFragmentFragmentDoc,
  '\n    fragment BusinessActivitiesAllocationIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      name\n      consumableType\n      consumableSubType\n      amount\n      consumptionAllocations {\n        percentage\n        sourceConsumption {\n          id\n        }\n        targetActivity {\n          id\n        }\n      }\n    }\n  ':
    types.BusinessActivitiesAllocationIndexConsumptionsFragmentFragmentDoc,
  '\n  fragment AllocateToActivitiesComponentActivityFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n':
    types.AllocateToActivitiesComponentActivityFragmentFragmentDoc,
  '\n  query GetSourceActivityAllocations($id: ID!) {\n    activity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesAllocationIndexFragment\n    }\n  }\n':
    types.GetSourceActivityAllocationsDocument,
  '\n  query GetActivitiesToAllocate(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocationIndexActivitiesFragment\n    }\n  }\n':
    types.GetActivitiesToAllocateDocument,
  '\n  query GetAllocationConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      ...BusinessActivitiesAllocationIndexConsumptionsFragment\n    }\n  }\n':
    types.GetAllocationConsumptionsDocument,
  '\n  query GetConsumptionForAllocation($id: ID!) {\n    consumption(id: $id) {\n      ...BusinessActivitiesAllocationConsumptionEditFragment\n    }\n  }\n':
    types.GetConsumptionForAllocationDocument,
  '\n  query ActivityTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n':
    types.ActivityTargetActivitiesDocument,
  '\n  query GetConsumptionTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n':
    types.GetConsumptionTargetActivitiesDocument,
  '\n  mutation UpdateConsumptionAllocation(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n':
    types.UpdateConsumptionAllocationDocument,
  '\n  mutation UpdateCropAllocations(\n    $sourceActivityId: ID!\n    $updateActivityAllocationsInput: UpdateActivityAllocationsInput!\n  ) {\n    updateActivityAllocations(\n      sourceActivityId: $sourceActivityId\n      updateActivityAllocationsInput: $updateActivityAllocationsInput\n    ) {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n':
    types.UpdateCropAllocationsDocument,
  '\n  fragment BusinessActivitiesConsumptionIndexFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    name\n    notes\n    consumptionAllocations {\n      percentage\n      targetActivity {\n        id\n      }\n      sourceConsumption {\n        id\n      }\n    }\n  }\n':
    types.BusinessActivitiesConsumptionIndexFragmentFragmentDoc,
  '\n  mutation UpdateConsumption(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n':
    types.UpdateConsumptionDocument,
  '\n  mutation CreateConsumption(\n    $propertyId: ID!\n    $createConsumptionInput: CreateConsumptionInput!\n  ) {\n    createConsumption(\n      propertyId: $propertyId\n      createConsumptionInput: $createConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n':
    types.CreateConsumptionDocument,
  '\n  mutation DeleteConsumption($id: ID!) {\n    deleteConsumption(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteConsumptionDocument,
  '\n  query GetConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      id\n      ...BusinessActivitiesConsumptionIndexConsumptionsFragment\n    }\n  }\n':
    types.GetConsumptionsDocument,
  '\n  query GetConsumption($id: ID!) {\n    consumption(id: $id) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n':
    types.GetConsumptionDocument,
  '\n    fragment BusinessActivitiesConsumptionIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      consumableType\n      consumableSubType\n      amount\n      name\n    }\n  ':
    types.BusinessActivitiesConsumptionIndexConsumptionsFragmentFragmentDoc,
  '\n  fragment ActivitiesActivityEditFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n':
    types.ActivitiesActivityEditFragmentFragmentDoc,
  '\n  query GetActivity($id: ID!) {\n    activity(id: $id) {\n      id\n      ...ActivitiesActivityEditFragment\n    }\n  }\n':
    types.GetActivityDocument,
  '\n  mutation updateActivity(\n    $id: ID!\n    $updateActivityInput: UpdateActivityInput!\n  ) {\n    updateActivity(id: $id, updateActivityInput: $updateActivityInput) {\n      id\n    }\n  }\n':
    types.UpdateActivityDocument,
  '\n  mutation deleteActivity($id: ID!) {\n    deleteActivity(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteActivityDocument,
  '\n  fragment BeefMonthlyForm_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageCowsCalving\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.BeefMonthlyForm_BeefMonthLivestockActivityFragmentDoc,
  '\n  fragment UpdateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_BeefMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment UpdateTransaction_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    ...TransactionForm_LivestockTransactionEventFragment\n  }\n':
    types.UpdateTransaction_LivestockTransactionEventFragmentFragmentDoc,
  '\n  fragment CreateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_BeefMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment TransactionForm_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    year\n    month\n    beefClass\n  }\n':
    types.TransactionForm_BeefMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateBeefMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateBeefMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createBeefLivestockTransactionEventForUpdateTransaction(\n      $beefMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBeefLivestockTransactionEvent(\n        beefMonthLivestockActivityId: $beefMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateBeefLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetBeefActivityForMonthlyBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefMonthlyBreakdown_BeefLivestockActivityFragment\n    }\n  }\n':
    types.GetBeefActivityForMonthlyBreakdownDocument,
  '\n    query GetBeefMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ':
    types.GetBeefMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetBeefMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      beefMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BeefMonthLivestockActivityFragment\n        ...UpdateTransaction_BeefMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetBeefMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment BeefMonthlyBreakdown_BeefLivestockActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n  }\n':
    types.BeefMonthlyBreakdown_BeefLivestockActivityFragmentFragmentDoc,
  '\n  fragment BeefMonthlyBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    ...BeefMonthlyForm_BeefMonthLivestockActivity\n  }\n':
    types.BeefMonthlyBreakdown_BeefMonthLivestockActivityFragmentDoc,
  '\n    fragment BeefActivityLivestockBreedingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Breeding\n      cowsGt2Breeding\n      heifersLt1Breeding\n      heifers1to2Breeding\n      heifersGt2Breeding\n      steersLt1Breeding\n      steers1to2Breeding\n      steersGt2Breeding\n    }\n  ':
    types.BeefActivityLivestockBreedingBeefLivestockActivityFragmentDoc,
  '\n    fragment BeefActivityLivestockTradingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Trading\n      cowsGt2Trading\n      heifersLt1Trading\n      heifers1to2Trading\n      heifersGt2Trading\n      steersLt1Trading\n      steers1to2Trading\n      steersGt2Trading\n    }\n  ':
    types.BeefActivityLivestockTradingBeefLivestockActivityFragmentDoc,
  '\n  fragment BeefActivityLivestockBeefLivestockActivity on BeefLivestockActivity {\n    ...BeefActivityLivestockBreedingBeefLivestockActivity\n    ...BeefActivityLivestockTradingBeefLivestockActivity\n  }\n':
    types.BeefActivityLivestockBeefLivestockActivityFragmentDoc,
  '\n  fragment UseBeefMonthlyFeedBreakdown_ActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    businessActivity {\n      id\n      state\n    }\n  }\n':
    types.UseBeefMonthlyFeedBreakdown_ActivityFragmentFragmentDoc,
  '\n    fragment UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  ':
    types.UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivityFragmentDoc,
  '\n    mutation updateBeefMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateBeefMonthLivestockActivityForMonthlyFeedBreakdownDocument,
  '\n  query GetBeefActivityForMonthlyFeedBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...UseBeefMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n':
    types.GetBeefActivityForMonthlyFeedBreakdownDocument,
  '\n    query GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ':
    types.GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdownDocument,
  '\n  query GetBeefLivestockActivityFeedDrySeasonMix($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedDrySeasonMix_BeefLivestockActivityFragment\n    }\n  }\n':
    types.GetBeefLivestockActivityFeedDrySeasonMixDocument,
  '\n  fragment BeefFeedDrySeasonMix_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n':
    types.BeefFeedDrySeasonMix_BeefLivestockActivityFragmentFragmentDoc,
  '\n  query GetBeefActivityFeed($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockFeed_BeefLivestockActivityFragment\n    }\n  }\n':
    types.GetBeefActivityFeedDocument,
  '\n  fragment BeefActivityLivestockFeed_BeefLivestockActivityFragment on BeefLivestockActivity {\n    id\n    __typename\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n':
    types.BeefActivityLivestockFeed_BeefLivestockActivityFragmentFragmentDoc,
  '\n  query GetBeefLivestockActivityFeedMineralBlock($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedMineralBlock_BeefLivestockActivityFragment\n    }\n  }\n':
    types.GetBeefLivestockActivityFeedMineralBlockDocument,
  '\n  fragment BeefFeedMineralBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n':
    types.BeefFeedMineralBlock_BeefLivestockActivityFragmentFragmentDoc,
  '\n  query GetBeefLivestockActivityFeedWeaner($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedWeanerBlock_BeefLivestockActivityFragment\n    }\n  }\n':
    types.GetBeefLivestockActivityFeedWeanerDocument,
  '\n  fragment BeefFeedWeanerBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.BeefFeedWeanerBlock_BeefLivestockActivityFragmentFragmentDoc,
  '\n  mutation updateBeefLivestockActivity(\n    $id: ID!\n    $updateBeefLivestockActivityInput: UpdateBeefLivestockActivityInput!\n  ) {\n    updateBeefLivestockActivity(\n      id: $id\n      updateBeefLivestockActivityInput: $updateBeefLivestockActivityInput\n    ) {\n      id\n      ...BeefActivityLivestockBreedingBeefLivestockActivity\n      ...BeefActivityLivestockTradingBeefLivestockActivity\n    }\n  }\n':
    types.UpdateBeefLivestockActivityDocument,
  '\n  query GetBeefActivity($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockBeefLivestockActivity\n    }\n  }\n':
    types.GetBeefActivityDocument,
  '\n  query GetBeefActivityInHeader($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBeefActivity\n    }\n  }\n':
    types.GetBeefActivityInHeaderDocument,
  '\n    query GetBeefMonthLivestockActivitiesForActivity(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetBeefMonthLivestockActivitiesForActivityDocument,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBeefActivity on BeefLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderBeefActivityFragmentDoc,
  '\n  fragment BeefLivestockActivity_MonthActivities on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    beefClass\n  }\n':
    types.BeefLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createBeefLivestockActivity(\n    $propertyId: ID!\n    $createBeefLivestockActivityInput: CreateBeefLivestockActivityInput!\n  ) {\n    createBeefLivestockActivity(\n      propertyId: $propertyId\n      createBeefLivestockActivityInput: $createBeefLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateBeefLivestockActivityDocument,
  '\n  fragment BuffaloMonthlyForm_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageCowsCalving\n    seasonalCalvingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.BuffaloMonthlyForm_BuffaloMonthLivestockActivityFragmentDoc,
  '\n  fragment UpdateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_BuffaloMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment CreateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_BuffaloMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment TransactionForm_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    year\n    month\n    buffaloClass\n  }\n':
    types.TransactionForm_BuffaloMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateBuffaloMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBuffaloMonthLivestockActivityInput: UpdateBuffaloMonthLivestockActivityInput!\n    ) {\n      updateBuffaloMonthLivestockActivity(\n        id: $id\n        updateBuffaloMonthLivestockActivityInput: $updateBuffaloMonthLivestockActivityInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateBuffaloMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createBuffaloLivestockTransactionEventForUpdateTransaction(\n      $buffaloMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBuffaloLivestockTransactionEvent(\n        buffaloMonthLivestockActivityId: $buffaloMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateBuffaloLivestockTransactionEventForUpdateTransactionDocument,
  '\n    mutation updateLivestockTransactionEventForUpdateTransaction(\n      $updateLivestockTransactionEventInput: UpdateLivestockTransactionEventInput!\n    ) {\n      updateLivestockTransactionEvent(\n        updateLivestockTransactionEventInput: $updateLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.UpdateLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetBuffaloActivityForMonthlyBreakdown($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment\n    }\n  }\n':
    types.GetBuffaloActivityForMonthlyBreakdownDocument,
  '\n    query GetBuffaloMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  ':
    types.GetBuffaloMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetBuffaloMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      buffaloMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BuffaloMonthLivestockActivityFragment\n        ...UpdateTransaction_BuffaloMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetBuffaloMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    bullsBreeding\n    cowsBreeding\n    steersBreeding\n    calfsBreeding\n    bullsTrading\n    cowsTrading\n    steersTrading\n    calfsTrading\n  }\n':
    types.BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragmentFragmentDoc,
  '\n  fragment BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    ...BuffaloMonthlyForm_BuffaloMonthLivestockActivity\n  }\n':
    types.BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivityFragmentDoc,
  '\n    fragment BuffaloActivityLivestockBreedingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsBreeding\n      cowsBreeding\n      steersBreeding\n      calfsBreeding\n    }\n  ':
    types.BuffaloActivityLivestockBreedingBuffaloLivestockActivityFragmentDoc,
  '\n    fragment BuffaloActivityLivestockTradingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsTrading\n      cowsTrading\n      steersTrading\n      calfsTrading\n    }\n  ':
    types.BuffaloActivityLivestockTradingBuffaloLivestockActivityFragmentDoc,
  '\n    fragment BuffaloActivityLivestockBuffaloLivestockActivity on BuffaloLivestockActivity {\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  ':
    types.BuffaloActivityLivestockBuffaloLivestockActivityFragmentDoc,
  '\n  query GetBuffaloActivityFeed($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment\n    }\n  }\n':
    types.GetBuffaloActivityFeedDocument,
  '\n  fragment BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n':
    types.BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity on BuffaloLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivityFragmentDoc,
  '\n  query GetBuffaloActivity($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockBuffaloLivestockActivity\n    }\n  }\n':
    types.GetBuffaloActivityDocument,
  '\n    query GetBuffaloMonthLivestockActivitiesForActivity(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetBuffaloMonthLivestockActivitiesForActivityDocument,
  '\n  query GetBuffaloActivityInHeader($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity\n    }\n  }\n':
    types.GetBuffaloActivityInHeaderDocument,
  '\n  mutation updateBuffaloLivestockActivity(\n    $id: ID!\n    $updateBuffaloLivestockActivityInput: UpdateBuffaloLivestockActivityInput!\n  ) {\n    updateBuffaloLivestockActivity(\n      id: $id\n      updateBuffaloLivestockActivityInput: $updateBuffaloLivestockActivityInput\n    ) {\n      id\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  }\n':
    types.UpdateBuffaloLivestockActivityDocument,
  '\n  fragment BuffaloLivestockActivity_MonthActivities on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    buffaloClass\n  }\n':
    types.BuffaloLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createBuffaloLivestockActivity(\n    $propertyId: ID!\n    $createBuffaloLivestockActivityInput: CreateBuffaloLivestockActivityInput!\n  ) {\n    createBuffaloLivestockActivity(\n      propertyId: $propertyId\n      createBuffaloLivestockActivityInput: $createBuffaloLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateBuffaloLivestockActivityDocument,
  '\n  fragment BusinessActivitiesBurningActivityInfoFragment on BurningActivity {\n    fireScarAreaSquareMetres\n    fuelClass\n    patchiness\n    season\n    vegetationClass\n    yearsSinceLastFire\n    rainfallZone\n  }\n':
    types.BusinessActivitiesBurningActivityInfoFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBurningActivity on BurningActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderBurningActivityFragmentDoc,
  '\n  query GetBurningActivityInfo($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n':
    types.GetBurningActivityInfoDocument,
  '\n  mutation UpdateBurning(\n    $id: ID!\n    $updateBurningActivityInput: UpdateBurningActivityInput!\n  ) {\n    updateBurningActivity(\n      id: $id\n      updateBurningActivityInput: $updateBurningActivityInput\n    ) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n':
    types.UpdateBurningDocument,
  '\n  mutation createBurningActivity(\n    $propertyId: ID!\n    $createBurningActivityInput: CreateBurningActivityInput!\n  ) {\n    createBurningActivity(\n      propertyId: $propertyId\n      createBurningActivityInput: $createBurningActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateBurningActivityDocument,
  '\n  query GetBurningActivityInHeader($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBurningActivity\n    }\n  }\n':
    types.GetBurningActivityInHeaderDocument,
  '\n  fragment CottonCropInfoPageCottonFields on CottonActivity {\n    id\n    residueBurned\n    percentageBurned\n    averageCottonYieldKgPerHa\n    balesPerHa\n    averageWeightPerBaleKg\n    cottonLintPerBaleKg\n    cottonSeedPerBaleKg\n    wastePerBaleKg\n  }\n':
    types.CottonCropInfoPageCottonFieldsFragmentDoc,
  '\n  fragment CottonCropInfoPageCroppingActivityFragment on CroppingActivity {\n    name\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n':
    types.CottonCropInfoPageCroppingActivityFragmentFragmentDoc,
  '\n  query GetCottonCroppingActivity($id: ID!) {\n    cottonActivity(id: $id) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n':
    types.GetCottonCroppingActivityDocument,
  '\n  mutation UpdateCottonCroppingForInfoPage(\n    $id: ID!\n    $updateCottonActivityInput: UpdateCottonActivityInput!\n  ) {\n    updateCottonActivity(\n      id: $id\n      updateCottonActivityInput: $updateCottonActivityInput\n    ) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n':
    types.UpdateCottonCroppingForInfoPageDocument,
  '\n  fragment CropInfoPageCropVarietyFragment on CropVariety {\n    id\n    species\n    yieldInKg\n  }\n':
    types.CropInfoPageCropVarietyFragmentFragmentDoc,
  '\n  fragment CropInfoPageCroppingActivityFragment on CroppingActivity {\n    irrigated\n    residueBurned\n    percentageBurned\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n    varieties {\n      ...CropInfoPageCropVarietyFragment\n    }\n    name\n  }\n':
    types.CropInfoPageCroppingActivityFragmentFragmentDoc,
  '\n  mutation UpdateCroppingForInfoPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n    }\n  }\n':
    types.UpdateCroppingForInfoPageDocument,
  '\n  mutation CreateCropVariety(\n    $croppingActivityId: ID!\n    $createCropVarietyInput: CreateCropVarietyInput!\n  ) {\n    createCropVariety(\n      croppingActivityId: $croppingActivityId\n      createCropVarietyInput: $createCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n':
    types.CreateCropVarietyDocument,
  '\n  mutation UpdateCropVariety(\n    $id: ID!\n    $updateCropVarietyInput: UpdateCropVarietyInput!\n  ) {\n    updateCropVariety(\n      id: $id\n      updateCropVarietyInput: $updateCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n':
    types.UpdateCropVarietyDocument,
  '\n  query GetCroppingActivityInfo($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n      ...CottonCropInfoPageCroppingActivityFragment\n    }\n  }\n':
    types.GetCroppingActivityInfoDocument,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity on CroppingActivity {\n      __typename\n      id\n      name\n      start\n      end\n      croppingType\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderCroppingActivityFragmentDoc,
  '\n  query GetCropActivityInHeader($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity\n    }\n  }\n':
    types.GetCropActivityInHeaderDocument,
  '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment on Fertiliser {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n      ratioN\n      ratioP\n      ratioK\n      ratioS\n    }\n  ':
    types.PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragmentFragmentDoc,
  '\n  mutation CreateFertiliser(\n    $croppingActivityId: ID!\n    $createFertiliserInput: CreateFertiliserInput!\n  ) {\n    createFertiliser(\n      croppingActivityId: $croppingActivityId\n      createFertiliserInput: $createFertiliserInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n':
    types.CreateFertiliserDocument,
  '\n  mutation UpdateFertiliser(\n    $id: ID!\n    $updateFertiliserInput: UpdateFertiliserInput!\n  ) {\n    updateFertiliser(id: $id, updateFertiliserInput: $updateFertiliserInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n':
    types.UpdateFertiliserDocument,
  '\n  mutation DeleteFertiliser($id: ID!) {\n    deleteFertiliser(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteFertiliserDocument,
  '\n  query GetFertiliser($id: ID!) {\n    fertiliser(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n':
    types.GetFertiliserDocument,
  '\n  mutation UpdateCroppingForLimeFertiliserPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n':
    types.UpdateCroppingForLimeFertiliserPageDocument,
  '\n  query GetCroppingLimeFertiliser($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n':
    types.GetCroppingLimeFertiliserDocument,
  '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFragment on CroppingActivity {\n      id\n      plantingAreaSquareMetres\n      lime {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      fertiliser {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      ureaseInhibitorUsed\n      nitrificationInhibitorUsed\n    }\n  ':
    types.PropertiesBusinessActivitiesCropLimeFertiliserFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment on Lime {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n    }\n  ':
    types.PropertiesBusinessActivitiesCropLimeFertiliserLimeFragmentFragmentDoc,
  '\n  mutation CreateLime(\n    $croppingActivityId: ID!\n    $createLimeInput: CreateLimeInput!\n  ) {\n    createLime(\n      croppingActivityId: $croppingActivityId\n      createLimeInput: $createLimeInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n':
    types.CreateLimeDocument,
  '\n  mutation UpdateLime($id: ID!, $updateLimeInput: UpdateLimeInput!) {\n    updateLime(id: $id, updateLimeInput: $updateLimeInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n':
    types.UpdateLimeDocument,
  '\n  mutation DeleteLime($id: ID!) {\n    deleteLime(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteLimeDocument,
  '\n  query GetLime($id: ID!) {\n    lime(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n':
    types.GetLimeDocument,
  '\n  mutation CreateCarbamate(\n    $croppingActivityId: ID!\n    $createCarbamateInput: CreateCarbamateInput!\n  ) {\n    createCarbamate(\n      croppingActivityId: $croppingActivityId\n      createCarbamateInput: $createCarbamateInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n':
    types.CreateCarbamateDocument,
  '\n  mutation UpdateCarbamate(\n    $id: ID!\n    $updateCarbamateInput: UpdateCarbamateInput!\n  ) {\n    updateCarbamate(id: $id, updateCarbamateInput: $updateCarbamateInput) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n':
    types.UpdateCarbamateDocument,
  '\n  mutation DeleteCarbamate($id: ID!) {\n    deleteCarbamate(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteCarbamateDocument,
  '\n  query GetCroppingWeedPestDisease($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment\n    }\n  }\n':
    types.GetCroppingWeedPestDiseaseDocument,
  '\n  query GetCarbamate($id: ID!) {\n    carbamate(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n':
    types.GetCarbamateDocument,
  '\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment on Carbamate {\n      type\n      name\n      amountPerHectareGrams\n      product\n      applicationAreaSquareMetres\n      applicationAreaGeometry\n      activeIngredientPercentage\n      applicationDate\n    }\n  ':
    types.PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment on CroppingActivity {\n      plantingAreaSquareMetres\n      pesticide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      herbicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      fungicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n    }\n  ':
    types.PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragmentFragmentDoc,
  '\n  mutation createCroppingActivity(\n    $propertyId: ID!\n    $createCroppingActivityInput: CreateCroppingActivityInput!\n  ) {\n    createCroppingActivity(\n      propertyId: $propertyId\n      createCroppingActivityInput: $createCroppingActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateCroppingActivityDocument,
  '\n  fragment UseDairyMonthlyFeedBreakdown_ActivityFragment on DairyLivestockActivity {\n    id\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    feed {\n      pasture\n      grazingCrops\n    }\n    businessActivity {\n      id\n      state\n    }\n  }\n':
    types.UseDairyMonthlyFeedBreakdown_ActivityFragmentFragmentDoc,
  '\n    fragment UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  ':
    types.UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivityFragmentDoc,
  '\n    mutation updateDairyMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateDairyMonthLivestockActivityForMonthlyFeedBreakdownDocument,
  '\n  query GetDairyActivityForMonthlyFeedBreakdown($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...UseDairyMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n':
    types.GetDairyActivityForMonthlyFeedBreakdownDocument,
  '\n    query GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdownDocument,
  '\n  fragment DairyFeedFragment on DairyLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyFeedBreakdownSummaryFragment\n  }\n':
    types.DairyFeedFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityFeed($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyFeedFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityFeedDocument,
  '\n  query GetDairyMonthLivestockActivitiesForFeedBreakdown(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyFeedBreakdownSummaryMonthsFragment\n    }\n  }\n':
    types.GetDairyMonthLivestockActivitiesForFeedBreakdownDocument,
  '\n  fragment DairyFeedBreakdownSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n':
    types.DairyFeedBreakdownSummaryFragmentFragmentDoc,
  '\n  fragment DairyFeedBreakdownSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    crudeProteinPercentage\n    dryMatterDigestibilityPercentage\n    dairyClass\n  }\n':
    types.DairyFeedBreakdownSummaryMonthsFragmentFragmentDoc,
  '\n  fragment DairyInfoFragment on DairyLivestockActivity {\n    productionSystem\n    distanceTravelledBetweenFarmsKm\n    truckType\n    emissionsRedMeatPercentage\n  }\n':
    types.DairyInfoFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityInfo($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityInfoDocument,
  '\n  mutation UpdateDairyLivestockActivityInfo(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n':
    types.UpdateDairyLivestockActivityInfoDocument,
  '\n  fragment DairyLivestockActivityHeaderFragment on DairyLivestockActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n':
    types.DairyLivestockActivityHeaderFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityInHeader($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyLivestockActivityHeaderFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityInHeaderDocument,
  '\n  fragment DairyMonthlyForm_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    previousMonthLiveweightKg\n    averageLiveweightKg\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.DairyMonthlyForm_DairyMonthLivestockActivityFragmentDoc,
  '\n  fragment UpdateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_DairyMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment CreateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_DairyMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment TransactionForm_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    year\n    month\n    dairyClass\n  }\n':
    types.TransactionForm_DairyMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateDairyMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateDairyMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createDairyLivestockTransactionEventForUpdateTransaction(\n      $dairyMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDairyLivestockTransactionEvent(\n        dairyMonthLivestockActivityId: $dairyMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateDairyLivestockTransactionEventForUpdateTransactionDocument,
  '\n    query GetDairyLivestockActivityForMonthlyBreakdown($id: ID!) {\n      dairyLivestockActivity(id: $id) {\n        id\n        ...DairyMonthlyBreakdown_DairyLivestockActivityFragment\n      }\n    }\n  ':
    types.GetDairyLivestockActivityForMonthlyBreakdownDocument,
  '\n    query GetDairyMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.GetDairyMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetdairyMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      dairyMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DairyMonthLivestockActivityFragment\n        ...UpdateTransaction_DairyMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetdairyMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment DairyMonthlyBreakdown_DairyLivestockActivityFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n':
    types.DairyMonthlyBreakdown_DairyLivestockActivityFragmentFragmentDoc,
  '\n  fragment DairyMonthlyBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    ...DairyMonthlyForm_DairyMonthLivestockActivity\n  }\n':
    types.DairyMonthlyBreakdown_DairyMonthLivestockActivityFragmentDoc,
  '\n  fragment DairyActivityLivestockFragment on DairyLivestockActivity {\n    ...DairyLivestockFragment\n  }\n':
    types.DairyActivityLivestockFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityLivestock($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityLivestockDocument,
  '\n  mutation UpdateDairyLivestockActivityLivestock(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n':
    types.UpdateDairyLivestockActivityLivestockDocument,
  '\n  query GetDairyMonthLivestockActivitiesForActivity(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyLivestockMonthsFragment\n    }\n  }\n':
    types.GetDairyMonthLivestockActivitiesForActivityDocument,
  '\n  fragment DairyLivestockFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n':
    types.DairyLivestockFragmentFragmentDoc,
  '\n  fragment DairyLivestockMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    dairyClass\n  }\n':
    types.DairyLivestockMonthsFragmentFragmentDoc,
  '\n  fragment DairyManureFragment on DairyLivestockActivity {\n    manureMilkingPasturePercentage\n    manureMilkingLagoonPercentage\n    manureMilkingSumpDispersalPercentage\n    manureMilkingPaddocksPercentage\n    manureMilkingSolidStoragePercentage\n    manureOtherPasturePercentage\n    manureOtherLagoonPercentage\n    manureOtherSumpDispersalPercentage\n    manureOtherPaddocksPercentage\n    manureOtherSolidStoragePercentage\n  }\n':
    types.DairyManureFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityManure($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyManureFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityManureDocument,
  '\n  mutation UpdateDairyLivestockActivityManure(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyManureFragment\n    }\n  }\n':
    types.UpdateDairyLivestockActivityManureDocument,
  '\n    fragment UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      litresPerHeadPerDay\n    }\n  ':
    types.UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivityFragmentDoc,
  '\n    query GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdownDocument,
  '\n    mutation updateDairyMonthLivestockActivityForMonthlyMilkBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateDairyMonthLivestockActivityForMonthlyMilkBreakdownDocument,
  '\n  fragment DairyMilkProductionListFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyMilkSummaryFragment\n  }\n':
    types.DairyMilkProductionListFragmentFragmentDoc,
  '\n  query GetDairyLivestockActivityMilkProduction($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyMilkProductionListFragment\n    }\n  }\n':
    types.GetDairyLivestockActivityMilkProductionDocument,
  '\n    query GetDairyMonthLivestockActivitiesForMilkProduction(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMilkSummaryMonthsFragment\n      }\n    }\n  ':
    types.GetDairyMonthLivestockActivitiesForMilkProductionDocument,
  '\n  fragment DairyMilkSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n':
    types.DairyMilkSummaryFragmentFragmentDoc,
  '\n  fragment DairyMilkSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    litresPerHeadPerDay\n    dairyClass\n  }\n':
    types.DairyMilkSummaryMonthsFragmentFragmentDoc,
  '\n  mutation CreateDairyLivestockActivity(\n    $propertyId: ID!\n    $createDairyLivestockActivityInput: CreateDairyLivestockActivityInput!\n  ) {\n    createDairyLivestockActivity(\n      propertyId: $propertyId\n      createDairyLivestockActivityInput: $createDairyLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateDairyLivestockActivityDocument,
  '\n  fragment DeerMonthlyForm_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesFawning\n    seasonalFawningRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.DeerMonthlyForm_DeerMonthLivestockActivityFragmentDoc,
  '\n  fragment UpdateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_DeerMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment CreateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_DeerMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment TransactionForm_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    year\n    month\n    deerClass\n  }\n':
    types.TransactionForm_DeerMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateDeerMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDeerMonthLivestockActivityInput: UpdateDeerMonthLivestockActivityInput!\n    ) {\n      updateDeerMonthLivestockActivity(\n        id: $id\n        updateDeerMonthLivestockActivityInput: $updateDeerMonthLivestockActivityInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateDeerMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createDeerLivestockTransactionEventForUpdateTransaction(\n      $deerMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDeerLivestockTransactionEvent(\n        deerMonthLivestockActivityId: $deerMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateDeerLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetDeerActivityForMonthlyBreakdown($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerMonthlyBreakdown_DeerLivestockActivityFragment\n    }\n  }\n':
    types.GetDeerActivityForMonthlyBreakdownDocument,
  '\n    query GetDeerMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  ':
    types.GetDeerMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetDeerMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      deerMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DeerMonthLivestockActivityFragment\n        ...UpdateTransaction_DeerMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetDeerMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment DeerMonthlyBreakdown_DeerLivestockActivityFragment on DeerLivestockActivity {\n    bucksBreeding\n    breedingDoesBreeding\n    otherDoesBreeding\n    fawnBreeding\n    bucksTrading\n    breedingDoesTrading\n    otherDoesTrading\n    fawnTrading\n  }\n':
    types.DeerMonthlyBreakdown_DeerLivestockActivityFragmentFragmentDoc,
  '\n  fragment DeerMonthlyBreakdown_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    ...DeerMonthlyForm_DeerMonthLivestockActivity\n  }\n':
    types.DeerMonthlyBreakdown_DeerMonthLivestockActivityFragmentDoc,
  '\n    fragment DeerActivityLivestockBreedingDeerLivestockActivity on DeerLivestockActivity {\n      bucksBreeding\n      breedingDoesBreeding\n      otherDoesBreeding\n      fawnBreeding\n    }\n  ':
    types.DeerActivityLivestockBreedingDeerLivestockActivityFragmentDoc,
  '\n    fragment DeerActivityLivestockTradingDeerLivestockActivity on DeerLivestockActivity {\n      bucksTrading\n      breedingDoesTrading\n      otherDoesTrading\n      fawnTrading\n    }\n  ':
    types.DeerActivityLivestockTradingDeerLivestockActivityFragmentDoc,
  '\n  fragment DeerActivityLivestockDeerLivestockActivity on DeerLivestockActivity {\n    ...DeerActivityLivestockBreedingDeerLivestockActivity\n    ...DeerActivityLivestockTradingDeerLivestockActivity\n  }\n':
    types.DeerActivityLivestockDeerLivestockActivityFragmentDoc,
  '\n  query GetDeerActivityFeed($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockFeed_DeerLivestockActivityFragment\n    }\n  }\n':
    types.GetDeerActivityFeedDocument,
  '\n  fragment DeerActivityLivestockFeed_DeerLivestockActivityFragment on DeerLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n':
    types.DeerActivityLivestockFeed_DeerLivestockActivityFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderDeerActivity on DeerLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderDeerActivityFragmentDoc,
  '\n  query GetDeerActivity($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockDeerLivestockActivity\n    }\n  }\n':
    types.GetDeerActivityDocument,
  '\n    query GetDeerMonthLivestockActivitiesForActivity(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetDeerMonthLivestockActivitiesForActivityDocument,
  '\n  query GetDeerActivityInHeader($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderDeerActivity\n    }\n  }\n':
    types.GetDeerActivityInHeaderDocument,
  '\n  mutation updateDeerLivestockActivity(\n    $id: ID!\n    $updateDeerLivestockActivityInput: UpdateDeerLivestockActivityInput!\n  ) {\n    updateDeerLivestockActivity(\n      id: $id\n      updateDeerLivestockActivityInput: $updateDeerLivestockActivityInput\n    ) {\n      id\n      ...DeerActivityLivestockBreedingDeerLivestockActivity\n      ...DeerActivityLivestockTradingDeerLivestockActivity\n    }\n  }\n':
    types.UpdateDeerLivestockActivityDocument,
  '\n  fragment DeerLivestockActivity_MonthActivities on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    deerClass\n  }\n':
    types.DeerLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createDeerLivestockActivity(\n    $propertyId: ID!\n    $createDeerLivestockActivityInput: CreateDeerLivestockActivityInput!\n  ) {\n    createDeerLivestockActivity(\n      propertyId: $propertyId\n      createDeerLivestockActivityInput: $createDeerLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateDeerLivestockActivityDocument,
  '\n  fragment FeedlotGroupFeedBreakdownFragment on FeedlotFeedBreakdown {\n    id\n    dryMatterDigestibility\n    crudeProtein\n    netEnergyConcentrationMjPerKg\n    nitrogenRetention\n  }\n':
    types.FeedlotGroupFeedBreakdownFragmentFragmentDoc,
  '\n  fragment FeedlotGroupFeedFragment on FeedlotGroup {\n    id\n    feedBreakdown {\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n':
    types.FeedlotGroupFeedFragmentFragmentDoc,
  '\n  query GetFeedlotGroupFeedBreakdown($groupId: ID!) {\n    feedlotGroup(id: $groupId) {\n      id\n      ...FeedlotGroupFeedFragment\n    }\n  }\n':
    types.GetFeedlotGroupFeedBreakdownDocument,
  '\n  mutation CreateFeedBreakdown(\n    $feedlotGroupId: ID!\n    $createFeedlotFeedBreakdownInput: CreateFeedlotFeedBreakdownInput!\n  ) {\n    createFeedlotFeedBreakdown(\n      feedlotGroupId: $feedlotGroupId\n      createFeedlotFeedBreakdownInput: $createFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n':
    types.CreateFeedBreakdownDocument,
  '\n  mutation UpdateFeedBreakdown(\n    $id: ID!\n    $updateFeedlotFeedBreakdownInput: UpdateFeedlotFeedBreakdownInput!\n  ) {\n    updateFeedlotFeedBreakdown(\n      id: $id\n      updateFeedlotFeedBreakdownInput: $updateFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n':
    types.UpdateFeedBreakdownDocument,
  '\n  mutation CreateFeedComposition(\n    $feedlotActivityId: ID!\n    $createFeedlotFeedCompositionInput: CreateFeedlotFeedCompositionInput!\n  ) {\n    createFeedlotFeedComposition(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotFeedCompositionInput: $createFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n':
    types.CreateFeedCompositionDocument,
  '\n  mutation UpdateFeedComposition(\n    $id: ID!\n    $updateFeedlotFeedCompositionInput: UpdateFeedlotFeedCompositionInput!\n  ) {\n    updateFeedlotFeedComposition(\n      id: $id\n      updateFeedlotFeedCompositionInput: $updateFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n':
    types.UpdateFeedCompositionDocument,
  '\n  fragment FeedlotFeedCompositionFragment on FeedlotFeedComposition {\n    id\n    grainsTotalProportion\n    grainsTotalCellulous\n    grainsTotalHemicellulous\n    grainsTotalSolubleResidue\n    grainsTotalNitrogen\n    otherConcentratesProportion\n    otherConcentratesCellulous\n    otherConcentratesHemicellulous\n    otherConcentratesSolubleResidue\n    otherConcentratesNitrogen\n    grassesProportion\n    grassesCellulous\n    grassesHemicellulous\n    grassesSolubleResidue\n    grassesNitrogen\n    legumesProportion\n    legumesCellulous\n    legumesHemicellulous\n    legumesSolubleResidue\n    legumesNitrogen\n  }\n':
    types.FeedlotFeedCompositionFragmentFragmentDoc,
  '\n  fragment FeedlotProductionFeedFragment on FeedlotActivity {\n    id\n    pasture\n    grazingCrops\n    feedComposition {\n      ...FeedlotFeedCompositionFragment\n    }\n    groups {\n      id\n      name\n      feedBreakdown {\n        id\n      }\n    }\n  }\n':
    types.FeedlotProductionFeedFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity on FeedlotActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivityFragmentDoc,
  '\n  query GetFeedlotActivitySystem($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionSystemFragment\n    }\n  }\n':
    types.GetFeedlotActivitySystemDocument,
  '\n  query GetFeedlotActivityTransport($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionTransportFragment\n    }\n  }\n':
    types.GetFeedlotActivityTransportDocument,
  '\n  query GetFeedlotActivityLivestock($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionLivestockFragment\n    }\n  }\n':
    types.GetFeedlotActivityLivestockDocument,
  '\n  mutation UpdateFeedlot(\n    $id: ID!\n    $updateFeedlotActivityInput: UpdateFeedlotActivityInput!\n  ) {\n    updateFeedlotActivity(\n      id: $id\n      updateFeedlotActivityInput: $updateFeedlotActivityInput\n    ) {\n      id\n      ...FeedlotProductionSystemFragment\n      ...FeedlotProductionFeedFragment\n    }\n  }\n':
    types.UpdateFeedlotDocument,
  '\n  query GetFeedlotActivityFeed($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionFeedFragment\n    }\n  }\n':
    types.GetFeedlotActivityFeedDocument,
  '\n  fragment FeedlotLivestockGroupFragment on FeedlotGroup {\n    id\n    name\n    numberOfLivestock\n    lengthOfStayDays\n    averageLiveweightKg\n  }\n':
    types.FeedlotLivestockGroupFragmentFragmentDoc,
  '\n  fragment FeedlotProductionLivestockFragment on FeedlotActivity {\n    id\n    groups {\n      id\n      name\n    }\n    purchases {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n    sales {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n  }\n':
    types.FeedlotProductionLivestockFragmentFragmentDoc,
  '\n  query FeedlotGroup($id: ID!) {\n    feedlotGroup(id: $id) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n':
    types.FeedlotGroupDocument,
  '\n  mutation CreateLivestockGroup(\n    $feedlotActivityId: ID!\n    $createFeedlotGroupInput: CreateFeedlotGroupInput!\n  ) {\n    createFeedlotGroup(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotGroupInput: $createFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n':
    types.CreateLivestockGroupDocument,
  '\n  mutation UpdateLivestockGroup(\n    $id: ID!\n    $updateFeedlotGroupInput: UpdateFeedlotGroupInput!\n  ) {\n    updateFeedlotGroup(\n      id: $id\n      updateFeedlotGroupInput: $updateFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n':
    types.UpdateLivestockGroupDocument,
  '\n  mutation DeleteLivestockGroup($id: ID!) {\n    deleteFeedlotGroup(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteLivestockGroupDocument,
  '\n  fragment FeedlotLivestockPurchaseFragment on FeedlotPurchase {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n    source\n  }\n':
    types.FeedlotLivestockPurchaseFragmentFragmentDoc,
  '\n  query FeedlotPurchase($id: ID!) {\n    feedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n':
    types.FeedlotPurchaseDocument,
  '\n  mutation CreateLivestockPurchase(\n    $feedlotActivityId: ID!\n    $createFeedlotPurchaseInput: CreateFeedlotPurchaseInput!\n  ) {\n    createFeedlotPurchase(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotPurchaseInput: $createFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n':
    types.CreateLivestockPurchaseDocument,
  '\n  mutation UpdateLivestockPurchase(\n    $id: ID!\n    $updateFeedlotPurchaseInput: UpdateFeedlotPurchaseInput!\n  ) {\n    updateFeedlotPurchase(\n      id: $id\n      updateFeedlotPurchaseInput: $updateFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n':
    types.UpdateLivestockPurchaseDocument,
  '\n  mutation DeleteLivestockPurchase($id: ID!) {\n    deleteFeedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n':
    types.DeleteLivestockPurchaseDocument,
  '\n  fragment FeedlotLivestockSaleFragment on FeedlotSale {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n  }\n':
    types.FeedlotLivestockSaleFragmentFragmentDoc,
  '\n  query FeedlotSale($id: ID!) {\n    feedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n':
    types.FeedlotSaleDocument,
  '\n  mutation CreateLivestockSale(\n    $feedlotActivityId: ID!\n    $createFeedlotSaleInput: CreateFeedlotSaleInput!\n  ) {\n    createFeedlotSale(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotSaleInput: $createFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n':
    types.CreateLivestockSaleDocument,
  '\n  mutation UpdateLivestockSale(\n    $id: ID!\n    $updateFeedlotSaleInput: UpdateFeedlotSaleInput!\n  ) {\n    updateFeedlotSale(\n      id: $id\n      updateFeedlotSaleInput: $updateFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n':
    types.UpdateLivestockSaleDocument,
  '\n  mutation DeleteLivestockSale($id: ID!) {\n    deleteFeedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n':
    types.DeleteLivestockSaleDocument,
  '\n  fragment FeedlotProductionSystemFragment on FeedlotActivity {\n    id\n    system\n  }\n':
    types.FeedlotProductionSystemFragmentFragmentDoc,
  '\n  fragment FeedlotProductionTransportFragment on FeedlotActivity {\n    id\n    totalDistanceTravelledKm\n    truckType\n  }\n':
    types.FeedlotProductionTransportFragmentFragmentDoc,
  '\n  mutation createFeedlotActivity(\n    $propertyId: ID!\n    $createFeedlotActivityInput: CreateFeedlotActivityInput!\n  ) {\n    createFeedlotActivity(\n      propertyId: $propertyId\n      createFeedlotActivityInput: $createFeedlotActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateFeedlotActivityDocument,
  '\n  query GetFeedlotActivityInHeader($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity\n    }\n  }\n':
    types.GetFeedlotActivityInHeaderDocument,
  '\n  fragment UpdateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_GoatMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment GoatMonthlyForm_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesKidding\n    seasonalKiddingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.GoatMonthlyForm_GoatMonthLivestockActivityFragmentDoc,
  '\n  fragment CreateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_GoatMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment TransactionForm_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    year\n    month\n    goatClass\n  }\n':
    types.TransactionForm_GoatMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateGoatMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateGoatMonthLivestockActivityInput: UpdateGoatMonthLivestockActivityInput!\n    ) {\n      updateGoatMonthLivestockActivity(\n        id: $id\n        updateGoatMonthLivestockActivityInput: $updateGoatMonthLivestockActivityInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateGoatMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createGoatLivestockTransactionEventForUpdateTransaction(\n      $goatMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createGoatLivestockTransactionEvent(\n        goatMonthLivestockActivityId: $goatMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateGoatLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetGoatActivityForMonthlyBreakdown($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatMonthlyBreakdown_GoatLivestockActivityFragment\n    }\n  }\n':
    types.GetGoatActivityForMonthlyBreakdownDocument,
  '\n    query GetGoatMonthLivestockActivitiesForMonthlyBreakdown(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  ':
    types.GetGoatMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetGoatMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      goatMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_GoatMonthLivestockActivityFragment\n        ...UpdateTransaction_GoatMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetGoatMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment GoatMonthlyBreakdown_GoatLivestockActivityFragment on GoatLivestockActivity {\n    bucksBreeding\n    wethersBreeding\n    breedingDoesBreeding\n    maidenBreedingDoesBreeding\n    otherDoesBreeding\n    kidsBreeding\n    bucksTrading\n    wethersTrading\n    breedingDoesTrading\n    maidenBreedingDoesTrading\n    otherDoesTrading\n    kidsTrading\n  }\n':
    types.GoatMonthlyBreakdown_GoatLivestockActivityFragmentFragmentDoc,
  '\n  fragment GoatMonthlyBreakdown_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    ...GoatMonthlyForm_GoatMonthLivestockActivity\n  }\n':
    types.GoatMonthlyBreakdown_GoatMonthLivestockActivityFragmentDoc,
  '\n    fragment GoatActivityLivestockBreedingGoatLivestockActivity on GoatLivestockActivity {\n      bucksBreeding\n      wethersBreeding\n      breedingDoesBreeding\n      maidenBreedingDoesBreeding\n      otherDoesBreeding\n      kidsBreeding\n    }\n  ':
    types.GoatActivityLivestockBreedingGoatLivestockActivityFragmentDoc,
  '\n    fragment GoatActivityLivestockTradingGoatLivestockActivity on GoatLivestockActivity {\n      bucksTrading\n      wethersTrading\n      breedingDoesTrading\n      maidenBreedingDoesTrading\n      otherDoesTrading\n      kidsTrading\n    }\n  ':
    types.GoatActivityLivestockTradingGoatLivestockActivityFragmentDoc,
  '\n  fragment GoatActivityLivestockGoatLivestockActivity on GoatLivestockActivity {\n    ...GoatActivityLivestockBreedingGoatLivestockActivity\n    ...GoatActivityLivestockTradingGoatLivestockActivity\n  }\n':
    types.GoatActivityLivestockGoatLivestockActivityFragmentDoc,
  '\n  query GetGoatLivestockActivityFeedDrySeasonMix($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedDrySeasonMix_GoatLivestockActivityFragment\n    }\n  }\n':
    types.GetGoatLivestockActivityFeedDrySeasonMixDocument,
  '\n  fragment GoatFeedDrySeasonMix_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n':
    types.GoatFeedDrySeasonMix_GoatLivestockActivityFragmentFragmentDoc,
  '\n  query GetGoatActivityFeed($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockFeed_GoatLivestockActivityFragment\n    }\n  }\n':
    types.GetGoatActivityFeedDocument,
  '\n  fragment GoatActivityLivestockFeed_GoatLivestockActivityFragment on GoatLivestockActivity {\n    id\n    __typename\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n':
    types.GoatActivityLivestockFeed_GoatLivestockActivityFragmentFragmentDoc,
  '\n  query GetGoatLivestockActivityFeedMineralBlock($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedMineralBlock_GoatLivestockActivityFragment\n    }\n  }\n':
    types.GetGoatLivestockActivityFeedMineralBlockDocument,
  '\n  fragment GoatFeedMineralBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n':
    types.GoatFeedMineralBlock_GoatLivestockActivityFragmentFragmentDoc,
  '\n  mutation UpdateGoatFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.UpdateGoatFeedWeanerDocument,
  '\n  query GetGoatLivestockActivityFeedWeaner($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedWeanerBlock_GoatLivestockActivityFragment\n    }\n  }\n':
    types.GetGoatLivestockActivityFeedWeanerDocument,
  '\n  fragment GoatFeedWeanerBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.GoatFeedWeanerBlock_GoatLivestockActivityFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGoatActivity on GoatLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderGoatActivityFragmentDoc,
  '\n  query GetGoatActivity($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockGoatLivestockActivity\n    }\n  }\n':
    types.GetGoatActivityDocument,
  '\n    query GetGoatMonthLivestockActivitiesForActivity(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetGoatMonthLivestockActivitiesForActivityDocument,
  '\n  query GetGoatActivityInHeader($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGoatActivity\n    }\n  }\n':
    types.GetGoatActivityInHeaderDocument,
  '\n  mutation updateGoatLivestockActivity(\n    $id: ID!\n    $updateGoatLivestockActivityInput: UpdateGoatLivestockActivityInput!\n  ) {\n    updateGoatLivestockActivity(\n      id: $id\n      updateGoatLivestockActivityInput: $updateGoatLivestockActivityInput\n    ) {\n      id\n      ...GoatActivityLivestockBreedingGoatLivestockActivity\n      ...GoatActivityLivestockTradingGoatLivestockActivity\n    }\n  }\n':
    types.UpdateGoatLivestockActivityDocument,
  '\n    query GetGoatMonthLivestockActivitiesForShearing(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatShearingEventMonthActivities\n      }\n    }\n  ':
    types.GetGoatMonthLivestockActivitiesForShearingDocument,
  '\n  fragment GoatWoolShearingEventsFragment on GoatShearingEvent {\n    id\n    month\n    year\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n':
    types.GoatWoolShearingEventsFragmentFragmentDoc,
  '\n  query GetGoatShearingEvents($activityId: ID!) {\n    shearingEventsByGoatLivestockActivityId(\n      goatLivestockActivityId: $activityId\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n':
    types.GetGoatShearingEventsDocument,
  '\n  query GetGoatShearingEvent($id: ID!) {\n    goatShearingEvent(id: $id) {\n      id\n      ...WoolUpdateGoatShearingEventFragment\n    }\n  }\n':
    types.GetGoatShearingEventDocument,
  '\n  mutation CreateGoatShearingEvent(\n    $goatLivestockActivityId: ID!\n    $input: CreateGoatShearingEventInput!\n  ) {\n    createGoatShearingEvent(\n      goatLivestockActivityId: $goatLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n':
    types.CreateGoatShearingEventDocument,
  '\n  mutation UpdateGoatShearingEvent(\n    $id: ID!\n    $input: UpdateGoatShearingEventInput!\n  ) {\n    updateGoatShearingEvent(id: $id, input: $input) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n':
    types.UpdateGoatShearingEventDocument,
  '\n  mutation DeleteGoatShearingEvent($id: ID!) {\n    deleteGoatShearingEvent(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteGoatShearingEventDocument,
  '\n  query GetGoatActivityForShearing($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatShearingEventGoatLivestockActivity\n    }\n  }\n':
    types.GetGoatActivityForShearingDocument,
  '\n  fragment WoolUpdateGoatShearingEventFragment on GoatShearingEvent {\n    id\n    date\n    goatClass\n    livestockCategory\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n':
    types.WoolUpdateGoatShearingEventFragmentFragmentDoc,
  '\n  fragment GoatShearingEventGoatLivestockActivity on GoatLivestockActivity {\n    __typename\n    start\n    end\n  }\n':
    types.GoatShearingEventGoatLivestockActivityFragmentDoc,
  '\n  fragment GoatShearingEventMonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n    livestockCategory\n  }\n':
    types.GoatShearingEventMonthActivitiesFragmentDoc,
  '\n  fragment GoatLivestockActivity_MonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n  }\n':
    types.GoatLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createGoatLivestockActivity(\n    $propertyId: ID!\n    $createGoatLivestockActivityInput: CreateGoatLivestockActivityInput!\n  ) {\n    createGoatLivestockActivity(\n      propertyId: $propertyId\n      createGoatLivestockActivityInput: $createGoatLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateGoatLivestockActivityDocument,
  '\n  query GetActivities($propertyId: ID!) {\n    activities(propertyId: $propertyId) {\n      id\n      ...ActivitiesIndexActivity\n    }\n  }\n':
    types.GetActivitiesDocument,
  '\n  fragment ActivitiesIndexActivity on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n':
    types.ActivitiesIndexActivityFragmentDoc,
  '\n  mutation CreateActivityFeed(\n    $activityId: ID!\n    $createFeedInput: CreateFeedInput!\n  ) {\n    createFeed(activityId: $activityId, createFeedInput: $createFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n':
    types.CreateActivityFeedDocument,
  '\n  mutation UpdateActivityFeed($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n':
    types.UpdateActivityFeedDocument,
  '\n  fragment ActivityLivestockFeed_FeedFragment on Feed {\n    id\n    pasture\n    grazingCrops\n    mineralBlock\n    weanerBlock\n    drySeasonMix\n  }\n':
    types.ActivityLivestockFeed_FeedFragmentFragmentDoc,
  '\n  fragment TransactionForm_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    transactionType\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    transactionRegion\n    headPurchasedMerino\n  }\n':
    types.TransactionForm_LivestockTransactionEventFragmentFragmentDoc,
  '\n    query GetLivestockTransactionEventForUpdateTransaction($id: ID!) {\n      livestockTransactionEvent(id: $id) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.GetLivestockTransactionEventForUpdateTransactionDocument,
  '\n  mutation DeleteLivestockTransactionEvent($id: ID!) {\n    deleteLivestockTransactionEvent(id: $id) {\n      id\n      ...UpdateTransaction_LivestockTransactionEventFragment\n    }\n  }\n':
    types.DeleteLivestockTransactionEventDocument,
  '\n  fragment PorkFeedBlendEditFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    barley\n    beetPulp\n    blendType\n    bloodMeal\n    canolaMeal\n    emissionsIntensityFeedProduction\n    fishMeal\n    meatMeal\n    millMix\n    percentageAdditionalIngredients\n    soybeanMeal\n    tallow\n    wheat\n    wheatBran\n    wheyPowder\n  }\n':
    types.PorkFeedBlendEditFragmentFragmentDoc,
  '\n  query GetPorkFeedBlend($id: ID!) {\n    porkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n':
    types.GetPorkFeedBlendDocument,
  '\n  mutation DeletePorkFeedBlend($id: ID!) {\n    deletePorkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n':
    types.DeletePorkFeedBlendDocument,
  '\n  mutation UpdatePorkFeedBlend(\n    $id: ID!\n    $updatePorkFeedBlendInput: UpdatePorkFeedBlendInput!\n  ) {\n    updatePorkFeedBlend(\n      id: $id\n      updatePorkFeedBlendInput: $updatePorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n':
    types.UpdatePorkFeedBlendDocument,
  '\n  mutation CreatePorkFeedBlend(\n    $porkLivestockActivityId: ID!\n    $createPorkFeedBlendInput: CreatePorkFeedBlendInput!\n  ) {\n    createPorkFeedBlend(\n      porkLivestockActivityId: $porkLivestockActivityId\n      createPorkFeedBlendInput: $createPorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n':
    types.CreatePorkFeedBlendDocument,
  '\n  query GetPorkActivityFeedBlends($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkLivestockActivityFeedBlendsFragment\n    }\n  }\n':
    types.GetPorkActivityFeedBlendsDocument,
  '\n  fragment PorkFeedBlendsFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    blendType\n  }\n':
    types.PorkFeedBlendsFragmentFragmentDoc,
  '\n  fragment PorkLivestockActivityFeedBlendsFragment on PorkLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    feedBlends {\n      ...PorkFeedBlendsFragment\n    }\n  }\n':
    types.PorkLivestockActivityFeedBlendsFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPorkActivity on PorkLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderPorkActivityFragmentDoc,
  '\n  query GetPorkActivity($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkActivityLivestockPorkLivestockActivity\n    }\n  }\n':
    types.GetPorkActivityDocument,
  '\n    query GetPorkMonthLivestockActivitiesForActivity(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetPorkMonthLivestockActivitiesForActivityDocument,
  '\n  query GetPorkActivityInHeader($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPorkActivity\n    }\n  }\n':
    types.GetPorkActivityInHeaderDocument,
  '\n  mutation updatePorkLivestockActivity(\n    $id: ID!\n    $updatePorkLivestockActivityInput: UpdatePorkLivestockActivityInput!\n  ) {\n    updatePorkLivestockActivity(\n      id: $id\n      updatePorkLivestockActivityInput: $updatePorkLivestockActivityInput\n    ) {\n      id\n      ...PorkActivityLivestockClassesFragment\n    }\n  }\n':
    types.UpdatePorkLivestockActivityDocument,
  '\n  fragment UpdateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_PorkMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment CreateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_PorkMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment PorkMonthlyForm_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.PorkMonthlyForm_PorkMonthLivestockActivityFragmentDoc,
  '\n  fragment TransactionForm_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    year\n    month\n    porkClass\n  }\n':
    types.TransactionForm_PorkMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updatePorkMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePorkMonthLivestockActivityInput: UpdatePorkMonthLivestockActivityInput!\n    ) {\n      updatePorkMonthLivestockActivity(\n        id: $id\n        updatePorkMonthLivestockActivityInput: $updatePorkMonthLivestockActivityInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdatePorkMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createPorkLivestockTransactionEventForUpdateTransaction(\n      $porkMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createPorkLivestockTransactionEvent(\n        porkMonthLivestockActivityId: $porkMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreatePorkLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetPorkActivityForMonthlyBreakdown($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkMonthlyBreakdown_PorkLivestockActivityFragment\n    }\n  }\n':
    types.GetPorkActivityForMonthlyBreakdownDocument,
  '\n    query GetPorkMonthLivestockActivitiesForMonthlyBreakdown(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  ':
    types.GetPorkMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetPorkMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      porkMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_PorkMonthLivestockActivityFragment\n        ...UpdateTransaction_PorkMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetPorkMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment PorkMonthlyBreakdown_PorkLivestockActivityFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n':
    types.PorkMonthlyBreakdown_PorkLivestockActivityFragmentFragmentDoc,
  '\n  fragment PorkMonthlyBreakdown_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    ...PorkMonthlyForm_PorkMonthLivestockActivity\n  }\n':
    types.PorkMonthlyBreakdown_PorkMonthLivestockActivityFragmentDoc,
  '\n  fragment PorkActivityLivestockClassesFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n':
    types.PorkActivityLivestockClassesFragmentFragmentDoc,
  '\n  fragment PorkActivityLivestockPorkLivestockActivity on PorkLivestockActivity {\n    ...PorkActivityLivestockClassesFragment\n  }\n':
    types.PorkActivityLivestockPorkLivestockActivityFragmentDoc,
  '\n  fragment PorkLivestockActivity_MonthActivities on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    porkClass\n  }\n':
    types.PorkLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createPorkLivestockActivity(\n    $propertyId: ID!\n    $createPorkLivestockActivityInput: CreatePorkLivestockActivityInput!\n  ) {\n    createPorkLivestockActivity(\n      propertyId: $propertyId\n      createPorkLivestockActivityInput: $createPorkLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreatePorkLivestockActivityDocument,
  '\n  fragment PoultryBroilersBatchFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n':
    types.PoultryBroilersBatchFragmentFragmentDoc,
  '\n  fragment PoultryBroilersBatchFeedBreakdownFragment on PoultryFeedBreakdown {\n    dryMatterIntakeKgPerHeadPerDay\n    dryMatterDigestibilityPercent\n    crudeProteinPercent\n    manureAshPercent\n    nitrogenRetentionPercent\n  }\n':
    types.PoultryBroilersBatchFeedBreakdownFragmentFragmentDoc,
  '\n  fragment PoultryGrowerBatchFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n':
    types.PoultryGrowerBatchFeedBlendFragmentFragmentDoc,
  '\n  query GetPoultryGrowerBatchFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n':
    types.GetPoultryGrowerBatchFeedBlendDocument,
  '\n  mutation CreateGrowerBatchFeedBlend(\n    $batchId: ID!\n    $createPoultryGrowerBatchFeedBlendInput: CreatePoultryGrowerBatchFeedBlendInput!\n  ) {\n    createPoultryGrowerBatchFeedBlend(\n      batchId: $batchId\n      createPoultryGrowerBatchFeedBlendInput: $createPoultryGrowerBatchFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n':
    types.CreateGrowerBatchFeedBlendDocument,
  '\n  mutation UpdateGrowerBatchFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n':
    types.UpdateGrowerBatchFeedBlendDocument,
  '\n  mutation DeletePoultryGrowerBatchFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePoultryGrowerBatchFeedBlendDocument,
  '\n  fragment PoultryBroilersBatchFeedFragment on PoultryGrowerBatch {\n    id\n    pasture\n    grazingCrops\n    growers\n    layers\n    other\n    feedBlends {\n      id\n      amountPurchasedTonnes\n      blendType\n    }\n  }\n':
    types.PoultryBroilersBatchFeedFragmentFragmentDoc,
  '\n  query GetPoultryBroilerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n  ) {\n    poultryGrowerBatchFeedBreakdown(batchId: $batchId, feedType: $feedType) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchFeedBreakdownDocument,
  '\n  mutation UpdatePoultryGrowerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n    $updatePoultryGrowerBatchFeedBreakdownInput: UpdatePoultryGrowerBatchFeedBreakdownInput!\n  ) {\n    updatePoultryGrowerBatchFeedBreakdown(\n      batchId: $batchId\n      feedType: $feedType\n      updatePoultryGrowerBatchFeedBreakdownInput: $updatePoultryGrowerBatchFeedBreakdownInput\n    ) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n':
    types.UpdatePoultryGrowerBatchFeedBreakdownDocument,
  '\n  fragment PoultryBroilersBatchDetailsFragment on PoultryGrowerBatchDetails {\n    totalNumberOfBirds\n    averageLengthOfStayDaysL1\n    averageLiveweightPerHeadKgW1\n    averageLengthOfStayDaysL2\n    averageLiveweightPerHeadKgW2\n  }\n':
    types.PoultryBroilersBatchDetailsFragmentFragmentDoc,
  '\n  fragment PoultryBroilersBatchFlockFragment on PoultryGrowerBatch {\n    growers\n    layers\n    other\n  }\n':
    types.PoultryBroilersBatchFlockFragmentFragmentDoc,
  '\n  query GetPoultryBroilerBatchFlockType(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n  ) {\n    poultryGrowerBatchDetails(batchId: $batchId, detailsType: $detailsType) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchFlockTypeDocument,
  '\n  mutation UpdatePoultryGrowerBatchFlockDetails(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n    $updatePoultryGrowerBatchDetailsInput: UpdatePoultryGrowerBatchDetailsInput!\n  ) {\n    updatePoultryGrowerBatchDetails(\n      batchId: $batchId\n      detailsType: $detailsType\n      updatePoultryGrowerBatchDetailsInput: $updatePoultryGrowerBatchDetailsInput\n    ) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n':
    types.UpdatePoultryGrowerBatchFlockDetailsDocument,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderGrowerBatchFragmentDoc,
  '\n  fragment PoultryBroilersGrowerBatchLayoutFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n':
    types.PoultryBroilersGrowerBatchLayoutFragmentFragmentDoc,
  '\n  query GetPoultryActivityInGrowerBatchHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch\n    }\n  }\n':
    types.GetPoultryActivityInGrowerBatchHeaderDocument,
  '\n  query GetPoultryBroilerBatchHeader($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersGrowerBatchLayoutFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchHeaderDocument,
  '\n  query GetPoultryBroilerBatchFlock($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFlockFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchFlockDocument,
  '\n  query GetPoultryBroilerBatchFeed($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchFeedDocument,
  '\n  mutation UpdatePoultryGrowerBatch(\n    $id: ID!\n    $updatePoultryGrowerBatchInput: UpdatePoultryGrowerBatchInput!\n  ) {\n    updatePoultryGrowerBatch(\n      id: $id\n      updatePoultryGrowerBatchInput: $updatePoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n      ...PoultryBroilersBatchFlockFragment\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n':
    types.UpdatePoultryGrowerBatchDocument,
  '\n  fragment PoultryBroilers_BatchesFragment on PoultryBroilerActivity {\n    growerBatches {\n      id\n      name\n      startDate\n      endDate\n    }\n  }\n':
    types.PoultryBroilers_BatchesFragmentFragmentDoc,
  '\n  query GetPoultryBroilerBatch($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchDocument,
  '\n  mutation CreateGrowerBatch(\n    $poultryActivityId: ID!\n    $createPoultryGrowerBatchInput: CreatePoultryGrowerBatchInput!\n  ) {\n    createPoultryGrowerBatch(\n      poultryActivityId: $poultryActivityId\n      createPoultryGrowerBatchInput: $createPoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n':
    types.CreateGrowerBatchDocument,
  '\n  mutation DeletePoultryGrowerBatch($id: ID!) {\n    deletePoultryGrowerBatch(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePoultryGrowerBatchDocument,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderPoultryActivityFragmentDoc,
  '\n  query GetPoultryBroilerBatches($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilers_BatchesFragment\n    }\n  }\n':
    types.GetPoultryBroilerBatchesDocument,
  '\n  query GetPoultryBroilerTransactions($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersTransactionsFragment\n    }\n  }\n':
    types.GetPoultryBroilerTransactionsDocument,
  '\n  query GetPoultryActivityInHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity\n    }\n  }\n':
    types.GetPoultryActivityInHeaderDocument,
  '\n  fragment PoultryBroilersManureLitterFragment on PoultryBroilerActivity {\n    percentManureAddedToPasture\n    percentSolidManure\n    percentageLitterRecycled\n    cyclesPerYear\n  }\n':
    types.PoultryBroilersManureLitterFragmentFragmentDoc,
  '\n  query GetPoultryBroilerManureLitter($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n':
    types.GetPoultryBroilerManureLitterDocument,
  '\n  mutation UpdateBroilerManureLitter(\n    $id: ID!\n    $updatePoultryBroilerActivityInput: UpdatePoultryBroilerActivityInput!\n  ) {\n    updatePoultryBroilerActivity(\n      id: $id\n      updatePoultryBroilerActivityInput: $updatePoultryBroilerActivityInput\n    ) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n':
    types.UpdateBroilerManureLitterDocument,
  '\n  fragment PoultryBroilersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n':
    types.PoultryBroilersTransactionFragmentFragmentDoc,
  '\n  mutation UpdatePoultryBroilerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n':
    types.UpdatePoultryBroilerTransactionDocument,
  '\n  fragment PoultryBroilersTransactionsFragment on PoultryBroilerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n':
    types.PoultryBroilersTransactionsFragmentFragmentDoc,
  '\n  query GetPoultryBroilerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n':
    types.GetPoultryBroilerTransactionDocument,
  '\n  mutation CreateBroilerTransaction(\n    $poultryBroilerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryBroilerTransactionEvent(\n      poultryBroilerActivityId: $poultryBroilerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n':
    types.CreateBroilerTransactionDocument,
  '\n  mutation DeletePoultryTransaction($id: ID!) {\n    deletePoultryTransactionEvent(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePoultryTransactionDocument,
  '\n  mutation createPoultryBroilerActivity(\n    $propertyId: ID!\n    $createPoultryBroilerActivityInput: CreatePoultryBroilerActivityInput!\n  ) {\n    createPoultryBroilerActivity(\n      propertyId: $propertyId\n      createPoultryBroilerActivityInput: $createPoultryBroilerActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreatePoultryBroilerActivityDocument,
  '\n  fragment PoultryLayerEggSalesFragment on PoultryLayerActivity {\n    totalLayerEggsProduced\n    averageLayerEggWeightGrams\n    totalMeatChickenLayerEggsProduced\n    averageMeatChickenLayerEggWeightGrams\n  }\n':
    types.PoultryLayerEggSalesFragmentFragmentDoc,
  '\n  query GetPoultryLayerEggSales($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n':
    types.GetPoultryLayerEggSalesDocument,
  '\n  mutation UpdateLayerEggSales(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n':
    types.UpdateLayerEggSalesDocument,
  '\n  fragment PoultryLayerActivityFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n':
    types.PoultryLayerActivityFeedBlendFragmentFragmentDoc,
  '\n  query GetPoultryLayerActivityFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n':
    types.GetPoultryLayerActivityFeedBlendDocument,
  '\n  mutation CreatePoultryLayerFeedBlend(\n    $poultryLayerActivityId: ID!\n    $createPoultryLayerFeedBlendInput: CreatePoultryLayerFeedBlendInput!\n  ) {\n    createPoultryLayerFeedBlend(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryLayerFeedBlendInput: $createPoultryLayerFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n':
    types.CreatePoultryLayerFeedBlendDocument,
  '\n  mutation UpdatePoultryLayerFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n':
    types.UpdatePoultryLayerFeedBlendDocument,
  '\n  mutation DeletePoultryLayerActivityFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePoultryLayerActivityFeedBlendDocument,
  '\n  fragment PoultryLayerFeedBlendsFragment on PoultryLayerActivity {\n    id\n    feedBlends {\n      id\n      blendType\n      amountPurchasedTonnes\n    }\n    pasture\n    grazingCrops\n  }\n':
    types.PoultryLayerFeedBlendsFragmentFragmentDoc,
  '\n  query GetPoultryLayerFeedBlends($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n':
    types.GetPoultryLayerFeedBlendsDocument,
  '\n  mutation UpdatePoultryLayerFeed(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n':
    types.UpdatePoultryLayerFeedDocument,
  '\n  fragment PoultryLayerActivityInfoFragment on PoultryLayerActivity {\n    poultryLayerMonthActivities {\n      ...PoultryLayerFlockFormMonthlyActivity\n    }\n  }\n':
    types.PoultryLayerActivityInfoFragmentFragmentDoc,
  '\n  fragment PoultryLayerFlockFormMonthlyActivity on PoultryLayerMonthActivity {\n    id\n    month\n    year\n    layerCount\n    meatChickenLayerCount\n  }\n':
    types.PoultryLayerFlockFormMonthlyActivityFragmentDoc,
  '\n    mutation updatePoultryLayerMonthActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePoultryLayerMonthActivityInput: UpdatePoultryLayerMonthActivityInput!\n    ) {\n      updatePoultryLayerMonthActivity(\n        id: $id\n        updatePoultryLayerMonthActivityInput: $updatePoultryLayerMonthActivityInput\n      ) {\n        id\n        ...PoultryLayerFlockFormMonthlyActivity\n      }\n    }\n  ':
    types.UpdatePoultryLayerMonthActivityForMonthlyBreakdownDocument,
  '\n  fragment PoultryLayerActivityHeaderFragment on PoultryLayerActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n':
    types.PoultryLayerActivityHeaderFragmentFragmentDoc,
  '\n  query GetPoultryLayerInfo($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityInfoFragment\n    }\n  }\n':
    types.GetPoultryLayerInfoDocument,
  '\n  query GetPoultryLayerInHeader($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityHeaderFragment\n    }\n  }\n':
    types.GetPoultryLayerInHeaderDocument,
  '\n  fragment PoultryLayerManureLitterFragment on PoultryLayerActivity {\n    percentManureAddedToPasture\n    percentageLitterRecycled\n    percentSolidManure\n    cyclesPerYear\n  }\n':
    types.PoultryLayerManureLitterFragmentFragmentDoc,
  '\n  query GetPoultryLayerManureLitter($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n':
    types.GetPoultryLayerManureLitterDocument,
  '\n  mutation UpdateLayerManureLitter(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n':
    types.UpdateLayerManureLitterDocument,
  '\n  fragment PoultryLayersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n':
    types.PoultryLayersTransactionFragmentFragmentDoc,
  '\n  mutation UpdatePoultryLayerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n':
    types.UpdatePoultryLayerTransactionDocument,
  '\n  fragment PoultryLayerActivityTransactionsFragment on PoultryLayerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n':
    types.PoultryLayerActivityTransactionsFragmentFragmentDoc,
  '\n  query GetPoultryLayerTransactions($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityTransactionsFragment\n    }\n  }\n':
    types.GetPoultryLayerTransactionsDocument,
  '\n  query GetPoultryLayerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n':
    types.GetPoultryLayerTransactionDocument,
  '\n  mutation CreateLayerTransaction(\n    $poultryLayerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryLayerTransactionEvent(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n':
    types.CreateLayerTransactionDocument,
  '\n  mutation createPoultryLayerActivity(\n    $propertyId: ID!\n    $createPoultryLayerActivityInput: CreatePoultryLayerActivityInput!\n  ) {\n    createPoultryLayerActivity(\n      propertyId: $propertyId\n      createPoultryLayerActivityInput: $createPoultryLayerActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreatePoultryLayerActivityDocument,
  '\n  fragment UpdateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n':
    types.UpdateTransaction_SheepMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment CreateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n':
    types.CreateTransaction_SheepMonthLivestockActivityFragmentFragmentDoc,
  '\n  fragment SheepMonthlyForm_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageEwesLambing\n    seasonalLambingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n':
    types.SheepMonthlyForm_SheepMonthLivestockActivityFragmentDoc,
  '\n  fragment TransactionForm_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    year\n    month\n    sheepClass\n  }\n':
    types.TransactionForm_SheepMonthLivestockActivityFragmentFragmentDoc,
  '\n    mutation updateSheepMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateSheepMonthLivestockActivityForMonthlyBreakdownDocument,
  '\n    mutation createSheepLivestockTransactionEventForUpdateTransaction(\n      $sheepMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createSheepLivestockTransactionEvent(\n        sheepMonthLivestockActivityId: $sheepMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ':
    types.CreateSheepLivestockTransactionEventForUpdateTransactionDocument,
  '\n  query GetSheepActivityForMonthlyBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepMonthlyBreakdown_SheepLivestockActivityFragment\n    }\n  }\n':
    types.GetSheepActivityForMonthlyBreakdownDocument,
  '\n    query GetSheepMonthLivestockActivitiesForMonthlyBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ':
    types.GetSheepMonthLivestockActivitiesForMonthlyBreakdownDocument,
  '\n    query GetSheepMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      sheepMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_SheepMonthLivestockActivityFragment\n        ...UpdateTransaction_SheepMonthLivestockActivityFragment\n      }\n    }\n  ':
    types.GetSheepMonthLivestockActivityForCreateUpdateTransactionDocument,
  '\n  fragment SheepMonthlyBreakdown_SheepLivestockActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n  }\n':
    types.SheepMonthlyBreakdown_SheepLivestockActivityFragmentFragmentDoc,
  '\n  fragment SheepMonthlyBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    ...SheepMonthlyForm_SheepMonthLivestockActivity\n  }\n':
    types.SheepMonthlyBreakdown_SheepMonthLivestockActivityFragmentDoc,
  '\n    fragment SheepActivityLivestockBreedingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Breeding\n      eweLambsLt1Breeding\n      otherEwesGt2Breeding\n      maidenBreedingEwes1To2Breeding\n      wetherLambsLt1Breeding\n      wethersGt1Breeding\n      ramsBreeding\n    }\n  ':
    types.SheepActivityLivestockBreedingSheepLivestockActivityFragmentDoc,
  '\n    fragment SheepActivityLivestockTradingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Trading\n      eweLambsLt1Trading\n      otherEwesGt2Trading\n      maidenBreedingEwes1To2Trading\n      wetherLambsLt1Trading\n      wethersGt1Trading\n      ramsTrading\n    }\n  ':
    types.SheepActivityLivestockTradingSheepLivestockActivityFragmentDoc,
  '\n  fragment SheepActivityLivestockSheepLivestockActivity on SheepLivestockActivity {\n    ...SheepActivityLivestockBreedingSheepLivestockActivity\n    ...SheepActivityLivestockTradingSheepLivestockActivity\n  }\n':
    types.SheepActivityLivestockSheepLivestockActivityFragmentDoc,
  '\n  fragment UseSheepMonthlyFeedBreakdown_ActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    businessActivity {\n      id\n      state\n    }\n  }\n':
    types.UseSheepMonthlyFeedBreakdown_ActivityFragmentFragmentDoc,
  '\n    fragment UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n      feedAvailabilityTonnesPerHectare\n    }\n  ':
    types.UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivityFragmentDoc,
  '\n    mutation updateSheepMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ':
    types.UpdateSheepMonthLivestockActivityForMonthlyFeedBreakdownDocument,
  '\n  query GetSheepActivityForMonthlyFeedBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...UseSheepMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n':
    types.GetSheepActivityForMonthlyFeedBreakdownDocument,
  '\n    query GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ':
    types.GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdownDocument,
  '\n  query GetSheepLivestockActivityFeedDrySeasonMix($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedDrySeasonMix_SheepLivestockActivityFragment\n    }\n  }\n':
    types.GetSheepLivestockActivityFeedDrySeasonMixDocument,
  '\n  fragment SheepFeedDrySeasonMix_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n':
    types.SheepFeedDrySeasonMix_SheepLivestockActivityFragmentFragmentDoc,
  '\n  query GetSheepActivityFeed($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockFeed_SheepLivestockActivityFragment\n    }\n  }\n':
    types.GetSheepActivityFeedDocument,
  '\n  fragment SheepActivityLivestockFeed_SheepLivestockActivityFragment on SheepLivestockActivity {\n    id\n    __typename\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n':
    types.SheepActivityLivestockFeed_SheepLivestockActivityFragmentFragmentDoc,
  '\n  query GetSheepLivestockActivityFeedMineralBlock($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedMineralBlock_SheepLivestockActivityFragment\n    }\n  }\n':
    types.GetSheepLivestockActivityFeedMineralBlockDocument,
  '\n  fragment SheepFeedMineralBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n':
    types.SheepFeedMineralBlock_SheepLivestockActivityFragmentFragmentDoc,
  '\n  mutation UpdateSheepFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.UpdateSheepFeedWeanerDocument,
  '\n  query GetSheepLivestockActivityFeedWeaner($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedWeanerBlock_SheepLivestockActivityFragment\n    }\n  }\n':
    types.GetSheepLivestockActivityFeedWeanerDocument,
  '\n  fragment SheepFeedWeanerBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n':
    types.SheepFeedWeanerBlock_SheepLivestockActivityFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderSheepActivity on SheepLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderSheepActivityFragmentDoc,
  '\n  query GetSheepActivity($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockSheepLivestockActivity\n    }\n  }\n':
    types.GetSheepActivityDocument,
  '\n    query GetSheepMonthLivestockActivitiesForActivity(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepLivestockActivity_MonthActivities\n      }\n    }\n  ':
    types.GetSheepMonthLivestockActivitiesForActivityDocument,
  '\n  query GetSheepActivityInHeader($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderSheepActivity\n    }\n  }\n':
    types.GetSheepActivityInHeaderDocument,
  '\n  mutation updateSheepLivestockActivity(\n    $id: ID!\n    $updateSheepLivestockActivityInput: UpdateSheepLivestockActivityInput!\n  ) {\n    updateSheepLivestockActivity(\n      id: $id\n      updateSheepLivestockActivityInput: $updateSheepLivestockActivityInput\n    ) {\n      id\n      ...SheepActivityLivestockBreedingSheepLivestockActivity\n      ...SheepActivityLivestockTradingSheepLivestockActivity\n    }\n  }\n':
    types.UpdateSheepLivestockActivityDocument,
  '\n  fragment SheepWoolShearingEventsFragment on SheepShearingEvent {\n    id\n    month\n    year\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n':
    types.SheepWoolShearingEventsFragmentFragmentDoc,
  '\n  query GetSheepShearingEvents($activityId: ID!) {\n    shearingEventsBySheepLivestockActivityId(\n      sheepLivestockActivityId: $activityId\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n':
    types.GetSheepShearingEventsDocument,
  '\n  query GetSheepShearingEvent($id: ID!) {\n    sheepShearingEvent(id: $id) {\n      id\n      ...WoolUpdateSheepShearingEventFragment\n    }\n  }\n':
    types.GetSheepShearingEventDocument,
  '\n  mutation CreateSheepShearingEvent(\n    $sheepLivestockActivityId: ID!\n    $input: CreateSheepShearingEventInput!\n  ) {\n    createSheepShearingEvent(\n      sheepLivestockActivityId: $sheepLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n':
    types.CreateSheepShearingEventDocument,
  '\n  mutation UpdateSheepShearingEvent(\n    $id: ID!\n    $input: UpdateSheepShearingEventInput!\n  ) {\n    updateSheepShearingEvent(id: $id, input: $input) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n':
    types.UpdateSheepShearingEventDocument,
  '\n  mutation DeleteSheepShearingEvent($id: ID!) {\n    deleteSheepShearingEvent(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteSheepShearingEventDocument,
  '\n  query GetSheepActivityForShearing($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepShearingEventSheepLivestockActivity\n    }\n  }\n':
    types.GetSheepActivityForShearingDocument,
  '\n    query GetSheepMonthLivestockActivitiesForShearing(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepShearingEventMonthActivities\n      }\n    }\n  ':
    types.GetSheepMonthLivestockActivitiesForShearingDocument,
  '\n  fragment WoolUpdateSheepShearingEventFragment on SheepShearingEvent {\n    id\n    date\n    sheepClass\n    livestockCategory\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n':
    types.WoolUpdateSheepShearingEventFragmentFragmentDoc,
  '\n  fragment SheepShearingEventSheepLivestockActivity on SheepLivestockActivity {\n    __typename\n    start\n    end\n  }\n':
    types.SheepShearingEventSheepLivestockActivityFragmentDoc,
  '\n  fragment SheepShearingEventMonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    sheepClass\n    livestockCategory\n  }\n':
    types.SheepShearingEventMonthActivitiesFragmentDoc,
  '\n  fragment SheepLivestockActivity_MonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    sheepClass\n  }\n':
    types.SheepLivestockActivity_MonthActivitiesFragmentDoc,
  '\n  mutation createSheepLivestockActivity(\n    $propertyId: ID!\n    $createSheepLivestockActivityInput: CreateSheepLivestockActivityInput!\n  ) {\n    createSheepLivestockActivity(\n      propertyId: $propertyId\n      createSheepLivestockActivityInput: $createSheepLivestockActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateSheepLivestockActivityDocument,
  '\n  fragment VegetationInfoFragment on VegetationActivity {\n    name\n    region\n    soilType\n    species\n    averageAge\n    treesPerHectare\n    totalPlantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n':
    types.VegetationInfoFragmentFragmentDoc,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity on VegetationActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderVegetationActivityFragmentDoc,
  '\n  mutation createVegetationActivity(\n    $propertyId: ID!\n    $createVegetationActivityInput: CreateVegetationActivityInput!\n  ) {\n    createVegetationActivity(\n      propertyId: $propertyId\n      createVegetationActivityInput: $createVegetationActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateVegetationActivityDocument,
  '\n  mutation UpdateVegetation(\n    $id: ID!\n    $updateVegetationActivityInput: UpdateVegetationActivityInput!\n  ) {\n    updateVegetationActivity(\n      id: $id\n      updateVegetationActivityInput: $updateVegetationActivityInput\n    ) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n':
    types.UpdateVegetationDocument,
  '\n  query GetVegetationInfo($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n':
    types.GetVegetationInfoDocument,
  '\n  query GetVegetationActivityInHeader($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity\n    }\n  }\n':
    types.GetVegetationActivityInHeaderDocument,
  '\n  fragment WildSeaFisheryBaitBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n':
    types.WildSeaFisheryBaitBaitPurchasesFragmentFragmentDoc,
  '\n  query GetBaitPurchases($activityId: ID!) {\n    baitPurchasesByWildSeaFisheryActivityId(\n      wildSeaFisheryActivityId: $activityId\n    ) {\n      id\n      ...WildSeaFisheryBaitBaitPurchasesFragment\n    }\n  }\n':
    types.GetBaitPurchasesDocument,
  '\n  query GetBaitPurchase($id: ID!) {\n    fisheryBaitPurchase(id: $id) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n':
    types.GetBaitPurchaseDocument,
  '\n  mutation CreateBaitPurchase(\n    $wildSeaFisheryActivityId: ID!\n    $input: CreateFisheryBaitPurchaseInput!\n  ) {\n    createFisheryBaitPurchase(\n      wildSeaFisheryActivityId: $wildSeaFisheryActivityId\n      input: $input\n    ) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n':
    types.CreateBaitPurchaseDocument,
  '\n  mutation UpdateBaitPurchase(\n    $id: ID!\n    $input: UpdateFisheryBaitPurchaseInput!\n  ) {\n    updateFisheryBaitPurchase(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n':
    types.UpdateBaitPurchaseDocument,
  '\n  mutation DeleteBaitPurchase($id: ID!) {\n    deleteFisheryBaitPurchase(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteBaitPurchaseDocument,
  '\n  fragment WildSeaFisheryBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n':
    types.WildSeaFisheryBaitPurchasesFragmentFragmentDoc,
  '\n  fragment WildSeaFisheryActivityInfoFragment on WildSeaFisheryActivity {\n    id\n    productionSystem\n    totalWeightCaughtKg\n    offsetsPurchasedTonnes\n  }\n':
    types.WildSeaFisheryActivityInfoFragmentFragmentDoc,
  '\n  query GetWildSeaFisheryInfo($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n':
    types.GetWildSeaFisheryInfoDocument,
  '\n  mutation UpdateWildSeaFisheryActivityInfo(\n    $id: ID!\n    $updateWildSeaFisheryActivityInput: UpdateWildSeaFisheryActivityInput!\n  ) {\n    updateWildSeaFisheryActivity(\n      id: $id\n      updateWildSeaFisheryActivityInput: $updateWildSeaFisheryActivityInput\n    ) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n':
    types.UpdateWildSeaFisheryActivityInfoDocument,
  '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity on WildSeaFisheryActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ':
    types.PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivityFragmentDoc,
  '\n  fragment WildSeaFisheryTransportRoutesFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n':
    types.WildSeaFisheryTransportRoutesFragmentFragmentDoc,
  '\n  query GetTransportRoutes($activityId: ID!) {\n    routesByActivityId(activityId: $activityId) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n':
    types.GetTransportRoutesDocument,
  '\n  query GetTransportRoute($id: ID!) {\n    route(id: $id) {\n      id\n      ...WildSeaFisheryTransportRouteFragment\n    }\n  }\n':
    types.GetTransportRouteDocument,
  '\n  mutation CreateTransportRoute($activityId: ID!, $input: CreateRouteInput!) {\n    createRoute(activityId: $activityId, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n':
    types.CreateTransportRouteDocument,
  '\n  mutation UpdateTransportRoute($id: ID!, $input: UpdateRouteInput!) {\n    updateRoute(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n':
    types.UpdateTransportRouteDocument,
  '\n  mutation DeleteTransportRoute($id: ID!) {\n    deleteRoute(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteTransportRouteDocument,
  '\n  query GetFisheryActivityForTransport($wildSeaFisheryId: ID!) {\n    wildSeaFisheryActivity(id: $wildSeaFisheryId) {\n      id\n      ...WildSeaFisheryActivityForTransportFragment\n    }\n  }\n':
    types.GetFisheryActivityForTransportDocument,
  '\n  fragment WildSeaFisheryTransportRouteFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n':
    types.WildSeaFisheryTransportRouteFragmentFragmentDoc,
  '\n  fragment WildSeaFisheryActivityForTransportFragment on WildSeaFisheryActivity {\n    id\n    start\n    end\n  }\n':
    types.WildSeaFisheryActivityForTransportFragmentFragmentDoc,
  '\n  mutation createWildSeaFisheryActivity(\n    $propertyId: ID!\n    $createWildSeaFisheryActivityInput: CreateWildSeaFisheryActivityInput!\n  ) {\n    createWildSeaFisheryActivity(\n      propertyId: $propertyId\n      createWildSeaFisheryActivityInput: $createWildSeaFisheryActivityInput\n    ) {\n      id\n    }\n  }\n':
    types.CreateWildSeaFisheryActivityDocument,
  '\n  query GetWildSeaFisheryActivityInHeader($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity\n    }\n  }\n':
    types.GetWildSeaFisheryActivityInHeaderDocument,
  '\n  fragment EditPropertyPage_PropertyFragment on Property {\n    id\n    name\n    addressLine1\n    addressLine2\n    city\n    state\n    postcode\n    country\n  }\n':
    types.EditPropertyPage_PropertyFragmentFragmentDoc,
  '\n  fragment EditPropertyPage_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    propertyNorthOfTropicOfCapricorn\n    rainfallAbove600\n    state\n  }\n':
    types.EditPropertyPage_BusinessActivityFragmentFragmentDoc,
  '\n  mutation UpdateProperty(\n    $id: ID!\n    $updatePropertyInput: UpdatePropertyInput!\n  ) {\n    updateProperty(id: $id, input: $updatePropertyInput) {\n      id\n      ...EditPropertyPage_PropertyFragment\n      ...PropertyLayoutParcelGeometries_PropertyFragment\n    }\n  }\n':
    types.UpdatePropertyDocument,
  '\n  mutation UpdateBusinessActivity(\n    $updateBusinessActivityInput: UpdateBusinessActivityInput!\n  ) {\n    updateBusinessActivity(input: $updateBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n':
    types.UpdateBusinessActivityDocument,
  '\n  query GetPropertyForEdit($id: ID!) {\n    property(id: $id) {\n      id\n      ...EditPropertyPage_PropertyFragment\n    }\n  }\n':
    types.GetPropertyForEditDocument,
  '\n  query GetBusinessActivityForProperty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n':
    types.GetBusinessActivityForPropertyDocument,
  '\n  mutation DeleteProperty($id: ID!) {\n    deleteProperty(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePropertyDocument,
  '\n  fragment PropertiesFilesFileFragment on File {\n    id\n    name\n    description\n    uploadStatus\n    extension\n    mimeType\n    bytes\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n  }\n':
    types.PropertiesFilesFileFragmentFragmentDoc,
  '\n  query GetPropertyFile($id: ID!) {\n    file(id: $id) {\n      id\n      uploadStatus\n      name\n      ...PropertiesFilesFileFragment\n    }\n  }\n':
    types.GetPropertyFileDocument,
  '\n  mutation DeletePropertyFile($id: ID!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePropertyFileDocument,
  '\n  fragment PropertyFileForUploadFragment on FileForUpload {\n    presignedPostUrl\n    presignedPostFields {\n      key\n      value\n    }\n    file {\n      id\n      name\n      description\n      bytes\n      createdAt\n      updatedAt\n      extension\n      signedUrl\n    }\n  }\n':
    types.PropertyFileForUploadFragmentFragmentDoc,
  '\n  fragment PropertyFilesFragment on Property {\n    files {\n      id\n      name\n      description\n      uploadStatus\n      bytes\n      extension\n      createdAt\n      updatedAt\n      signedUrl\n    }\n  }\n':
    types.PropertyFilesFragmentFragmentDoc,
  '\n  query GetPropertyFiles($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyFilesFragment\n    }\n  }\n':
    types.GetPropertyFilesDocument,
  '\n  mutation CreatePropertyFile($createFileInput: CreateFileInput!) {\n    createPropertyFile(input: $createFileInput) {\n      ...PropertyFileForUploadFragment\n    }\n  }\n':
    types.CreatePropertyFileDocument,
  '\n  mutation UpdatePropertyFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      id\n      ...PropertiesFilesFileFragment\n    }\n  }\n':
    types.UpdatePropertyFileDocument,
  '\n  fragment PropertyPage_PropertyFragment on Property {\n    name\n  }\n':
    types.PropertyPage_PropertyFragmentFragmentDoc,
  '\n  query GetProperty($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyPage_PropertyFragment\n    }\n  }\n':
    types.GetPropertyDocument,
  '\n  query GetBusinessActivityForProeprty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...UseBusinessActivityForProperty_BusinessActivityFragment\n    }\n  }\n':
    types.GetBusinessActivityForProeprtyDocument,
  '\n  fragment UseBusinessActivityForProperty_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    state\n  }\n':
    types.UseBusinessActivityForProperty_BusinessActivityFragmentFragmentDoc,
  '\n  fragment MapShapesEditIndex_MapShapeFragment on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n':
    types.MapShapesEditIndex_MapShapeFragmentFragmentDoc,
  '\n  query GetMapShape($id: ID!) {\n    mapShape(id: $id) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n':
    types.GetMapShapeDocument,
  '\n  mutation UpdateMapShape($id: ID!, $input: UpdateMapShapeInput!) {\n    updateMapShape(id: $id, input: $input) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n':
    types.UpdateMapShapeDocument,
  '\n  mutation DeleteMapShape($id: ID!) {\n    deleteMapShape(id: $id) {\n      id\n    }\n  }\n':
    types.DeleteMapShapeDocument,
  '\n  fragment MapShapesIndex_MapShape on MapShape {\n    geometry\n    name\n    ...MapShapesList_MapShape\n  }\n':
    types.MapShapesIndex_MapShapeFragmentDoc,
  '\n  query GetMapShapes($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n':
    types.GetMapShapesDocument,
  '\n  mutation CreateMapShape($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n':
    types.CreateMapShapeDocument,
  '\n  fragment PropertiesNotesNoteFragment on PropertyNote {\n    id\n    title\n    text\n  }\n':
    types.PropertiesNotesNoteFragmentFragmentDoc,
  '\n  query GetPropertyNote($id: ID!) {\n    propertyNote(id: $id) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n':
    types.GetPropertyNoteDocument,
  '\n  mutation UpdatePropertyNote($id: ID!, $input: UpdatePropertyNoteInput!) {\n    updatePropertyNote(id: $id, input: $input) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n':
    types.UpdatePropertyNoteDocument,
  '\n  mutation DeletePropertyNote($id: ID!) {\n    deletePropertyNote(id: $id) {\n      id\n    }\n  }\n':
    types.DeletePropertyNoteDocument,
  '\n  fragment PropertyNotesFragment on Property {\n    propertyNotes {\n      id\n      title\n      text\n      createdAt\n      updatedAt\n    }\n  }\n':
    types.PropertyNotesFragmentFragmentDoc,
  '\n  query GetPropertyNotes($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyNotesFragment\n    }\n  }\n':
    types.GetPropertyNotesDocument,
  '\n  mutation CreatePropertyNote(\n    $createPropertyNoteInput: CreatePropertyNoteInput!\n  ) {\n    createPropertyNote(input: $createPropertyNoteInput) {\n      id\n    }\n  }\n':
    types.CreatePropertyNoteDocument,
  '\n  fragment AllTeamPropertiesPageProperty on Property {\n    id\n    name\n    addressLine1\n    city\n    state\n    createdAt\n    updatedAt\n    centroid\n    createdBy\n  }\n':
    types.AllTeamPropertiesPagePropertyFragmentDoc,
  '\n  query GetProperties($teamId: ID!) {\n    properties(teamId: $teamId) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n':
    types.GetPropertiesDocument,
  '\n  query GetPropertiesWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesSearchByPage(teamId: $teamId, searchPage: $searchPage) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n':
    types.GetPropertiesWithSearchDocument,
  '\n  query GetPropertiesCountWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesCount(teamId: $teamId, searchPage: $searchPage)\n  }\n':
    types.GetPropertiesCountWithSearchDocument,
  '\n  mutation CreateBusinessActivity(\n    $createBusinessActivityInput: CreateBusinessActivityInput!\n  ) {\n    createBusinessActivity(input: $createBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n':
    types.CreateBusinessActivityDocument,
  '\n  query GetParcelForUseEditPropertyBoundary($id: ID!) {\n    parcel(id: $id) {\n      id\n      ...UseEditPropertyBoundary_ParcelFragment\n    }\n  }\n':
    types.GetParcelForUseEditPropertyBoundaryDocument,
  '\n  query GetGeographicInformationForPointForUseEditPropertyBoundary(\n    $point: GeoJSONPoint!\n  ) {\n    geographicInformationForPoint(point: $point) {\n      ...UseEditPropertyBoundary_GeographicInformationFragment\n    }\n  }\n':
    types.GetGeographicInformationForPointForUseEditPropertyBoundaryDocument,
  '\n  query SearchParcels($input: ParcelSearchInput!) {\n    searchParcels(input: $input) {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n':
    types.SearchParcelsDocument,
  '\n  fragment UseEditPropertyBoundary_ParcelFragment on Parcel {\n    id\n    geoFeature {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n':
    types.UseEditPropertyBoundary_ParcelFragmentFragmentDoc,
  '\n  fragment UseEditPropertyBoundary_GeographicInformationFragment on GeographicInformation {\n    state\n  }\n':
    types.UseEditPropertyBoundary_GeographicInformationFragmentFragmentDoc,
  '\n  mutation CreateProperty($createPropertyInput: CreatePropertyInput!) {\n    createProperty(input: $createPropertyInput) {\n      id\n    }\n  }\n':
    types.CreatePropertyDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FiresTable_ReportData on ReportData {\n    burnedAreaEvents {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FiresTable_ReportData on ReportData {\n    burnedAreaEvents {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Report_Report on Report {\n    id\n    type\n    createdAt\n    sections\n    status\n    data {\n      reportMetadata {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Report_Report on Report {\n    id\n    type\n    createdAt\n    sections\n    status\n    data {\n      reportMetadata {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ClimateChangePage_Report on Report {\n    data {\n      ...ClimateChangeRainfall_ReportData\n      ...ClimateChangeTempMin_ReportData\n      ...ClimateChangeTempMax_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment ClimateChangePage_Report on Report {\n    data {\n      ...ClimateChangeRainfall_ReportData\n      ...ClimateChangeTempMin_ReportData\n      ...ClimateChangeTempMax_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ClimateChangeRainfall_ReportData on ReportData {\n    climateSSP126RainfallYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126RainfallYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ClimateChangeRainfall_ReportData on ReportData {\n    climateSSP126RainfallYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126RainfallYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ClimateChangeTempMax_ReportData on ReportData {\n    climateSSP126TempMaxYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMaxYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ClimateChangeTempMax_ReportData on ReportData {\n    climateSSP126TempMaxYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMaxYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ClimateChangeTempMin_ReportData on ReportData {\n    climateSSP126TempMinYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMinYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ClimateChangeTempMin_ReportData on ReportData {\n    climateSSP126TempMinYearly {\n      result {\n        id\n        values\n      }\n    }\n    climateSSP126TempMinYearlyStdDev {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationOverTimePage_Report on Report {\n    data {\n      ...DeforestationOverTimeChart_ReportData\n      ...UseDeforestationImages_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment DeforestationOverTimePage_Report on Report {\n    data {\n      ...DeforestationOverTimeChart_ReportData\n      ...UseDeforestationImages_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationOverTimeChart_ReportData on ReportData {\n    deforestationYearly {\n      result {\n        id\n        values\n      }\n    }\n    deforestationColorMap {\n      result {\n        id\n        values\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment DeforestationOverTimeChart_ReportData on ReportData {\n    deforestationYearly {\n      result {\n        id\n        values\n      }\n    }\n    deforestationColorMap {\n      result {\n        id\n        values\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseDeforestationImages_ReportData on ReportData {\n    deforestationYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment UseDeforestationImages_ReportData on ReportData {\n    deforestationYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    deforestationDataQuality {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EnvironmentRiskPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      ...TemperatureMeanHistory_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment EnvironmentRiskPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      ...TemperatureMeanHistory_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TemperatureMeanHistory_ReportData on ReportData {\n    temperatureMeanHistory {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtd {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment TemperatureMeanHistory_ReportData on ReportData {\n    temperatureMeanHistory {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtd {\n      result {\n        id\n        values\n      }\n    }\n    temperatureMeanYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ForestsAndFiresPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ForestsAndFiresPage_Report on Report {\n    data {\n      ...FiresTable_ReportData\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GrassAndForestFireRiskPage_Report on Report {\n    data {\n      ...FireRiskChart_ReportData\n      ...TemperatureMeanHistory_ReportData\n      grasslandFireMaximum {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment GrassAndForestFireRiskPage_Report on Report {\n    data {\n      ...FireRiskChart_ReportData\n      ...TemperatureMeanHistory_ReportData\n      grasslandFireMaximum {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FireRiskChart_ReportData on ReportData {\n    grasslandFireMonthly {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FireRiskChart_ReportData on ReportData {\n    grasslandFireMonthly {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LandAndNaturePage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n      landCoverClasses {\n        result {\n          id\n          values\n        }\n      }\n      landCoverClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment LandAndNaturePage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      forestClasses {\n        result {\n          id\n          values\n        }\n      }\n      forestClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n      landCoverClasses {\n        result {\n          id\n          values\n        }\n      }\n      landCoverClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LandUsePage_Report on Report {\n    data {\n      ...LandUse_ReportData\n      ...PropertyDetailsLandUseTable_ReportData\n      ...UseTerrainSummary_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment LandUsePage_Report on Report {\n    data {\n      ...LandUse_ReportData\n      ...PropertyDetailsLandUseTable_ReportData\n      ...UseTerrainSummary_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyDetailsLandUseTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyDetailsLandUseTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LandUse_ReportData on ReportData {\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment LandUse_ReportData on ReportData {\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseTerrainSummary_ReportData on ReportData {\n    satelliteRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    landUseClassesImage {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    satelliteNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment UseTerrainSummary_ReportData on ReportData {\n    satelliteRgbYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    landUseClassesImage {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n    satelliteNdviYearlyImages {\n      result {\n        files {\n          name\n          location\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment OverviewPage_Report on Report {\n    data {\n      ...OverviewMap_ReportData\n      ...PropertyDetailsTable_ReportData\n      ...LandmarksTable_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment OverviewPage_Report on Report {\n    data {\n      ...OverviewMap_ReportData\n      ...PropertyDetailsTable_ReportData\n      ...LandmarksTable_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment LandmarksTable_ReportData on ReportData {\n    landmarksTowns {\n      result {\n        id\n        values\n      }\n    }\n    landmarksCities {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSchools {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSilos {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment LandmarksTable_ReportData on ReportData {\n    landmarksTowns {\n      result {\n        id\n        values\n      }\n    }\n    landmarksCities {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSchools {\n      result {\n        id\n        values\n      }\n    }\n    landmarksSilos {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment OverviewMap_ReportData on ReportData {\n    propertyParcelsGeoJson {\n      result {\n        id\n        values\n      }\n    }\n    buildingsGeometries {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment OverviewMap_ReportData on ReportData {\n    propertyParcelsGeoJson {\n      result {\n        id\n        values\n      }\n    }\n    buildingsGeometries {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyDetailsTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n    landmarksLocation {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyDetailsTable_ReportData on ReportData {\n    propertySite {\n      result {\n        id\n        values\n      }\n    }\n    landmarksLocation {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ParcelsAndBuildingsPage_Report on Report {\n    data {\n      propertyParcelsList {\n        result {\n          id\n          values\n        }\n      }\n      buildingsList {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ParcelsAndBuildingsPage_Report on Report {\n    data {\n      propertyParcelsList {\n        result {\n          id\n          values\n        }\n      }\n      buildingsList {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProductivityPage_Report on Report {\n    data {\n      ...EviAreaProductivity_ReportData\n      ...EviAreaAndRainfall_ReportData\n      ...YieldCropTypes_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment ProductivityPage_Report on Report {\n    data {\n      ...EviAreaProductivity_ReportData\n      ...EviAreaAndRainfall_ReportData\n      ...YieldCropTypes_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EviAreaAndRainfall_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment EviAreaAndRainfall_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EviAreaProductivity_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment EviAreaProductivity_ReportData on ReportData {\n    eviAreaProductivity {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment YieldCropTypes_ReportData on ReportData {\n    yieldModelYearly {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment YieldCropTypes_ReportData on ReportData {\n    yieldModelYearly {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyAndClimateSummaryPage_Report on Report {\n    data {\n      ...SummaryTable_ReportData\n      ...ProductivityCard_ReportData\n      ...AnnualRainfallCard_ReportData\n      ...ClimateRiskCard_ReportData\n      elevationArableImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      landUseClassesImage {\n        result {\n          files {\n            name\n            location\n          }\n        }\n      }\n      landUseClasses {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyAndClimateSummaryPage_Report on Report {\n    data {\n      ...SummaryTable_ReportData\n      ...ProductivityCard_ReportData\n      ...AnnualRainfallCard_ReportData\n      ...ClimateRiskCard_ReportData\n      elevationArableImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      landUseClassesImage {\n        result {\n          files {\n            name\n            location\n          }\n        }\n      }\n      landUseClasses {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AnnualRainfallCard_ReportData on ReportData {\n    rainfallStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AnnualRainfallCard_ReportData on ReportData {\n    rainfallStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ClimateRiskCard_ReportData on ReportData {\n    climateRiskStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ClimateRiskCard_ReportData on ReportData {\n    climateRiskStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ProductivityCard_ReportData on ReportData {\n    yieldModelDistribution {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ProductivityCard_ReportData on ReportData {\n    yieldModelDistribution {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SummaryTable_ReportData on ReportData {\n    elevationArable {\n      result {\n        id\n        values\n      }\n    }\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n    soilAusClasses {\n      result {\n        id\n        values\n      }\n    }\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SummaryTable_ReportData on ReportData {\n    elevationArable {\n      result {\n        id\n        values\n      }\n    }\n    landUseClasses {\n      result {\n        id\n        values\n      }\n    }\n    soilAusClasses {\n      result {\n        id\n        values\n      }\n    }\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RainfallPage_Report on Report {\n    data {\n      ...RainfallDuration_ReportData\n      ...RainfallVolume_ReportData\n      ...SeasonalRainfallHistory_ReportData\n      ...RainfallHistory_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment RainfallPage_Report on Report {\n    data {\n      ...RainfallDuration_ReportData\n      ...RainfallVolume_ReportData\n      ...SeasonalRainfallHistory_ReportData\n      ...RainfallHistory_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RainfallDuration_ReportData on ReportData {\n    rainfallDuration {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RainfallDuration_ReportData on ReportData {\n    rainfallDuration {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RainfallHistory_ReportData on ReportData {\n    rainfallHistory {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtd {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RainfallHistory_ReportData on ReportData {\n    rainfallHistory {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtd {\n      result {\n        id\n        values\n      }\n    }\n    rainfallYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RainfallVolume_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RainfallVolume_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SeasonalRainfallHistory_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SeasonalRainfallHistory_ReportData on ReportData {\n    rainfallVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SalesPage_Report on Report {\n    ...RecentSalesPriceChart_Report\n    data {\n      salesPropertyHistory {\n        result {\n          id\n          values\n        }\n      }\n      ...RecentSalesPriceChart_ReportData\n      ...RecentSalesPricePerAreaChart_ReportData\n      ...PricePerHectareOverTimeChart_ReportData\n      ...NumberOfSalesOverTimeChart_ReportData\n      ...PropertyPastSalesTable_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment SalesPage_Report on Report {\n    ...RecentSalesPriceChart_Report\n    data {\n      salesPropertyHistory {\n        result {\n          id\n          values\n        }\n      }\n      ...RecentSalesPriceChart_ReportData\n      ...RecentSalesPricePerAreaChart_ReportData\n      ...PricePerHectareOverTimeChart_ReportData\n      ...NumberOfSalesOverTimeChart_ReportData\n      ...PropertyPastSalesTable_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment NumberOfSalesOverTimeChart_ReportData on ReportData {\n    salesSaleCountHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment NumberOfSalesOverTimeChart_ReportData on ReportData {\n    salesSaleCountHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PricePerHectareOverTimeChart_ReportData on ReportData {\n    salesAveragePriceHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PricePerHectareOverTimeChart_ReportData on ReportData {\n    salesAveragePriceHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyPastSalesTable_ReportData on ReportData {\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyPastSalesTable_ReportData on ReportData {\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPriceChart_ReportData on ReportData {\n    salesPriceDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPriceChart_ReportData on ReportData {\n    salesPriceDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPriceChart_Report on Report {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPriceChart_Report on Report {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPricePerAreaChart_ReportData on ReportData {\n    salesPricePerAreaDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPricePerAreaChart_ReportData on ReportData {\n    salesPricePerAreaDistribution {\n      result {\n        id\n        values\n      }\n    }\n    salesPropertyHistory {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilProfilePage_Report on Report {\n    data {\n      ...SoilTexture_ReportData\n      ...SoilOrganicCarbon_ReportData\n      ...SoilPh_ReportData\n      ...SoilAwc_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilProfilePage_Report on Report {\n    data {\n      ...SoilTexture_ReportData\n      ...SoilOrganicCarbon_ReportData\n      ...SoilPh_ReportData\n      ...SoilAwc_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilAwc_ReportData on ReportData {\n    soilAwcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilAwc_ReportData on ReportData {\n    soilAwcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilOrganicCarbon_ReportData on ReportData {\n    soilOcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilOrganicCarbon_ReportData on ReportData {\n    soilOcProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilPh_ReportData on ReportData {\n    soilPhProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilPh_ReportData on ReportData {\n    soilPhProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilTexture_ReportData on ReportData {\n    soilSandProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilSiltProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilClayProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilTexture_ReportData on ReportData {\n    soilSandProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilSiltProfile {\n      result {\n        id\n        values\n      }\n    }\n    soilClayProfile {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilTaxonomyPage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClasses {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilTaxonomyPage_Report on Report {\n    data {\n      propertyParcelsGeoJson {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClasses {\n        result {\n          id\n          values\n        }\n      }\n      soilAusClassesImage {\n        result {\n          id\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SoilTexturePage_Report on Report {\n    data {\n      soilOcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilPhImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilAwcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment SoilTexturePage_Report on Report {\n    data {\n      soilOcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilPhImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      soilAwcImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TableOfContentsPage_Report on Report {\n    id\n    sections\n  }\n',
): (typeof documents)['\n  fragment TableOfContentsPage_Report on Report {\n    id\n    sections\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TemperatureEventsPage_Report on Report {\n    data {\n      ...HeatEventsVolume_ReportData\n      ...FrostEventsVolume_ReportData\n      ...HeatEventsHistory_ReportData\n      ...FrostEventsHistory_ReportData\n    }\n  }\n',
): (typeof documents)['\n  fragment TemperatureEventsPage_Report on Report {\n    data {\n      ...HeatEventsVolume_ReportData\n      ...FrostEventsVolume_ReportData\n      ...HeatEventsHistory_ReportData\n      ...FrostEventsHistory_ReportData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FrostEventsHistory_ReportData on ReportData {\n    frostEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FrostEventsHistory_ReportData on ReportData {\n    frostEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    frostEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FrostEventsVolume_ReportData on ReportData {\n    frostEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FrostEventsVolume_ReportData on ReportData {\n    frostEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment HeatEventsHistory_ReportData on ReportData {\n    heatEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment HeatEventsHistory_ReportData on ReportData {\n    heatEventsHistory {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtd {\n      result {\n        id\n        values\n      }\n    }\n    heatEventsYtdMinus1 {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment HeatEventsVolume_ReportData on ReportData {\n    heatEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment HeatEventsVolume_ReportData on ReportData {\n    heatEventsVolume {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TerrainPage_Report on Report {\n    data {\n      ...Elevation_ReportData\n      ...Slope_ReportData\n\n      elevationElevationImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationSlopeImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationStatistics {\n        result {\n          id\n          values\n        }\n      }\n      elevationSlope {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment TerrainPage_Report on Report {\n    data {\n      ...Elevation_ReportData\n      ...Slope_ReportData\n\n      elevationElevationImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationSlopeImage {\n        result {\n          files {\n            location\n          }\n        }\n      }\n      elevationStatistics {\n        result {\n          id\n          values\n        }\n      }\n      elevationSlope {\n        result {\n          id\n          values\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Elevation_ReportData on ReportData {\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Elevation_ReportData on ReportData {\n    elevationStatistics {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment Slope_ReportData on ReportData {\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment Slope_ReportData on ReportData {\n    elevationSlope {\n      result {\n        id\n        values\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ViewReport_Report on Report {\n    id\n    teamId\n    status\n    type\n    ...Report_Report\n\n    # Pages\n    ...ParcelsAndBuildingsPage_Report\n    ...TableOfContentsPage_Report\n    ...OverviewPage_Report\n    ...PropertyAndClimateSummaryPage_Report\n    ...LandUsePage_Report\n    ...ProductivityPage_Report\n    ...RainfallPage_Report\n    ...TerrainPage_Report\n    ...SoilTaxonomyPage_Report\n    ...SoilTexturePage_Report\n    ...SoilProfilePage_Report\n    ...LandAndNaturePage_Report\n    ...GrassAndForestFireRiskPage_Report\n    ...EnvironmentRiskPage_Report\n    ...ClimateChangePage_Report\n    ...TemperatureEventsPage_Report\n    ...DeforestationOverTimePage_Report\n    ...ForestsAndFiresPage_Report\n    ...SalesPage_Report\n  }\n',
): (typeof documents)['\n  fragment ViewReport_Report on Report {\n    id\n    teamId\n    status\n    type\n    ...Report_Report\n\n    # Pages\n    ...ParcelsAndBuildingsPage_Report\n    ...TableOfContentsPage_Report\n    ...OverviewPage_Report\n    ...PropertyAndClimateSummaryPage_Report\n    ...LandUsePage_Report\n    ...ProductivityPage_Report\n    ...RainfallPage_Report\n    ...TerrainPage_Report\n    ...SoilTaxonomyPage_Report\n    ...SoilTexturePage_Report\n    ...SoilProfilePage_Report\n    ...LandAndNaturePage_Report\n    ...GrassAndForestFireRiskPage_Report\n    ...EnvironmentRiskPage_Report\n    ...ClimateChangePage_Report\n    ...TemperatureEventsPage_Report\n    ...DeforestationOverTimePage_Report\n    ...ForestsAndFiresPage_Report\n    ...SalesPage_Report\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...ViewReport_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...ViewReport_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateMapShapeFromManageMapAreaButton($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateMapShapeFromManageMapAreaButton($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ComparableSaleAsideLayoutComparableSale on ComparableSale {\n    name\n    sale {\n      id\n      price\n      contractDate\n      propertiesInSale {\n        property {\n          id\n          addressLine1\n          propertyParcels {\n            parcel {\n              id\n              hectares\n              latitude\n              longitude\n              legalNumber\n            }\n          }\n        }\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment ComparableSaleAsideLayoutComparableSale on ComparableSale {\n    name\n    sale {\n      id\n      price\n      contractDate\n      propertiesInSale {\n        property {\n          id\n          addressLine1\n          propertyParcels {\n            parcel {\n              id\n              hectares\n              latitude\n              longitude\n              legalNumber\n            }\n          }\n        }\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetComparableSale($propertyId: ID!, $saleId: ID!) {\n    comparableSale(input: { propertyId: $propertyId, saleId: $saleId }) {\n      ...ComparableSaleAsideLayoutComparableSale\n    }\n  }\n',
): (typeof documents)['\n  query GetComparableSale($propertyId: ID!, $saleId: ID!) {\n    comparableSale(input: { propertyId: $propertyId, saleId: $saleId }) {\n      ...ComparableSaleAsideLayoutComparableSale\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PortfolioLayoutPropertyCoordinates_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioLayoutPropertyCoordinates_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PortfolioLayoutPropertyList_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioLayoutPropertyList_Portfolio on Portfolio {\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PortfolioLayoutPortals_Portfolio on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioLayoutPortals_Portfolio on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n      portfolioId\n      property {\n        id\n        centroid\n        name\n        bbox\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPortfolioForLayout($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioLayoutPortals_Portfolio\n    }\n  }\n',
): (typeof documents)['\n  query GetPortfolioForLayout($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioLayoutPortals_Portfolio\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyLayoutPortals_PropertyFragment on Property {\n    id\n    name\n    ...PropertyLayoutParcelGeometries_PropertyFragment\n  }\n',
): (typeof documents)['\n  fragment PropertyLayoutPortals_PropertyFragment on Property {\n    id\n    name\n    ...PropertyLayoutParcelGeometries_PropertyFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyForLayout($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyLayoutPortals_PropertyFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyForLayout($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyLayoutPortals_PropertyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertyLayoutParcelGeometries_PropertyFragment on Property {\n      propertyParcels {\n        propertyId\n        parcelId\n        name\n        geometry\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PropertyLayoutParcelGeometries_PropertyFragment on Property {\n      propertyParcels {\n        propertyId\n        parcelId\n        name\n        geometry\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedDrySeasonMix_FeedFragment on Feed {\n    id\n    drySeasonMixKg\n    drySeasonMixUreaPercentage\n  }\n',
): (typeof documents)['\n  fragment FeedDrySeasonMix_FeedFragment on Feed {\n    id\n    drySeasonMixKg\n    drySeasonMixUreaPercentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedMineralBlock_FeedFragment on Feed {\n    id\n    mineralBlockKg\n    mineralBlockUreaPercentage\n  }\n',
): (typeof documents)['\n  fragment FeedMineralBlock_FeedFragment on Feed {\n    id\n    mineralBlockKg\n    mineralBlockUreaPercentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedWeanerBlock_FeedFragment on Feed {\n    id\n    weanerBlockKg\n    weanerBlockUreaPercentage\n  }\n',
): (typeof documents)['\n  fragment FeedWeanerBlock_FeedFragment on Feed {\n    id\n    weanerBlockKg\n    weanerBlockUreaPercentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateFeedDrySeasonMix(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFeedDrySeasonMix(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateFeedMineralBlock(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation updateFeedMineralBlock(\n    $id: ID!\n    $updateFeedInput: UpdateFeedInput!\n  ) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MapShapesList_MapShape on MapShape {\n    id\n    ...MapShapesListItem_MapShape\n  }\n',
): (typeof documents)['\n  fragment MapShapesList_MapShape on MapShape {\n    id\n    ...MapShapesListItem_MapShape\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MapShapesListItem_MapShape on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n',
): (typeof documents)['\n  fragment MapShapesListItem_MapShape on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SelectMapShapeFromLibraryModal_MapShape on MapShape {\n    id\n    geometry\n    ...MapShapesList_MapShape\n  }\n',
): (typeof documents)['\n  fragment SelectMapShapeFromLibraryModal_MapShape on MapShape {\n    id\n    geometry\n    ...MapShapesList_MapShape\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetMapShapesForSelectMapShapeFromLibraryModal($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...SelectMapShapeFromLibraryModal_MapShape\n    }\n  }\n',
): (typeof documents)['\n  query GetMapShapesForSelectMapShapeFromLibraryModal($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...SelectMapShapeFromLibraryModal_MapShape\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AdvisorDashboardTeamsFragment on Team {\n    id\n    name\n    organisation {\n      id\n      name\n      ownerId\n    }\n    properties {\n      id\n      name\n    }\n  }\n',
): (typeof documents)['\n  fragment AdvisorDashboardTeamsFragment on Team {\n    id\n    name\n    organisation {\n      id\n      name\n      ownerId\n    }\n    properties {\n      id\n      name\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetTeamsForAdvisorDashboard($userId: ID!) {\n    advisorTeams(userId: $userId) {\n      ...AdvisorDashboardTeamsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetTeamsForAdvisorDashboard($userId: ID!) {\n    advisorTeams(userId: $userId) {\n      ...AdvisorDashboardTeamsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createAdvisorInviteForUser(\n    $createAdvisorInvitationInput: CreateAdvisorInvitationInput!\n  ) {\n    createAdvisorInvitation(input: $createAdvisorInvitationInput) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createAdvisorInviteForUser(\n    $createAdvisorInvitationInput: CreateAdvisorInvitationInput!\n  ) {\n    createAdvisorInvitation(input: $createAdvisorInvitationInput) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllPropertiesForEditPortfolioFormFragment on Property {\n    id\n    name\n    centroid\n    bbox\n  }\n',
): (typeof documents)['\n  fragment AllPropertiesForEditPortfolioFormFragment on Property {\n    id\n    name\n    centroid\n    bbox\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PortfolioEditPage_PortfolioFragment on Portfolio {\n    name\n    portfolioProperties {\n      property {\n        id\n        name\n        centroid\n        bbox\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment PortfolioEditPage_PortfolioFragment on Portfolio {\n    name\n    portfolioProperties {\n      property {\n        id\n        name\n        centroid\n        bbox\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPortfolioForEdit($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPortfolioForEdit($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertiesForAddToPortfolio($teamId: ID!) {\n    properties(teamId: $teamId, orderBy: { name: asc }) {\n      id\n      ...AllPropertiesForEditPortfolioFormFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertiesForAddToPortfolio($teamId: ID!) {\n    properties(teamId: $teamId, orderBy: { name: asc }) {\n      id\n      ...AllPropertiesForEditPortfolioFormFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePortfolio(\n    $id: ID!\n    $updatePortfolioInput: UpdatePortfolioInput!\n  ) {\n    updatePortfolio(id: $id, input: $updatePortfolioInput) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePortfolio(\n    $id: ID!\n    $updatePortfolioInput: UpdatePortfolioInput!\n  ) {\n    updatePortfolio(id: $id, input: $updatePortfolioInput) {\n      id\n      ...PortfolioEditPage_PortfolioFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePortfolio($id: ID!) {\n    deletePortfolio(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePortfolio($id: ID!) {\n    deletePortfolio(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PortfolioPage_PortfolioFragment on Portfolio {\n    name\n  }\n',
): (typeof documents)['\n  fragment PortfolioPage_PortfolioFragment on Portfolio {\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPortfolio($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioPage_PortfolioFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPortfolio($id: ID!) {\n    portfolio(id: $id) {\n      id\n      ...PortfolioPage_PortfolioFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TeamPortfoliosFragment on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n    }\n  }\n',
): (typeof documents)['\n  fragment TeamPortfoliosFragment on Portfolio {\n    id\n    name\n    portfolioProperties {\n      propertyId\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePortfolio($input: CreatePortfolioInput!) {\n    createPortfolio(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePortfolio($input: CreatePortfolioInput!) {\n    createPortfolio(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPortfoliosForTeam($teamId: ID!) {\n    portfoliosForTeam(teamId: $teamId) {\n      ...TeamPortfoliosFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPortfoliosForTeam($teamId: ID!) {\n    portfoliosForTeam(teamId: $teamId) {\n      ...TeamPortfoliosFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentAnalysisPage_Property on Property {\n    ...RecentSalesPriceChart_Property\n    ...RecentSalesPricePerAreaChart_Property\n    salesSummary {\n      ...PropertyPastSalesTable_PropertySalesData\n      ...NumberOfSalesOverTimeChart_PropertySalesData\n      ...PricePerHectareOverTimeChart_PropertySalesData\n      ...RecentSalesPriceChart_PropertySalesData\n      ...RecentSalesPricePerAreaChart_PropertySalesData\n    }\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentAnalysisPage_Property on Property {\n    ...RecentSalesPriceChart_Property\n    ...RecentSalesPricePerAreaChart_Property\n    salesSummary {\n      ...PropertyPastSalesTable_PropertySalesData\n      ...NumberOfSalesOverTimeChart_PropertySalesData\n      ...PricePerHectareOverTimeChart_PropertySalesData\n      ...RecentSalesPriceChart_PropertySalesData\n      ...RecentSalesPricePerAreaChart_PropertySalesData\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment NumberOfSalesOverTimeChart_PropertySalesData on PropertySalesData {\n    summarySaleCountHistory {\n      result\n    }\n  }\n',
): (typeof documents)['\n  fragment NumberOfSalesOverTimeChart_PropertySalesData on PropertySalesData {\n    summarySaleCountHistory {\n      result\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PricePerHectareOverTimeChart_PropertySalesData on PropertySalesData {\n      summaryAveragePriceHistory {\n        result\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PricePerHectareOverTimeChart_PropertySalesData on PropertySalesData {\n      summaryAveragePriceHistory {\n        result\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyPastSalesTable_PropertySalesData on PropertySalesData {\n    propertySalesHistory {\n      result\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyPastSalesTable_PropertySalesData on PropertySalesData {\n    propertySalesHistory {\n      result\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPriceChart_PropertySalesData on PropertySalesData {\n    summaryPriceDistribution {\n      result\n    }\n    propertySalesHistory {\n      result\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPriceChart_PropertySalesData on PropertySalesData {\n    summaryPriceDistribution {\n      result\n    }\n    propertySalesHistory {\n      result\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPriceChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPriceChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment RecentSalesPricePerAreaChart_PropertySalesData on PropertySalesData {\n      summaryPricePerAreaDistribution {\n        result\n      }\n      propertySalesHistory {\n        result\n      }\n    }\n  ',
): (typeof documents)['\n    fragment RecentSalesPricePerAreaChart_PropertySalesData on PropertySalesData {\n      summaryPricePerAreaDistribution {\n        result\n      }\n      propertySalesHistory {\n        result\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment RecentSalesPricePerAreaChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment RecentSalesPricePerAreaChart_Property on Property {\n    id\n    comparableSales {\n      propertyId\n      saleId\n      name\n      similarity\n      sale {\n        id\n        price\n        contractDate\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyForCmaCharts($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...CmaAssessmentAnalysisPage_Property\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyForCmaCharts($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...CmaAssessmentAnalysisPage_Property\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyComparableSaleFragment on ComparableSale {\n    propertyId\n    saleId\n    sale {\n      id\n      price\n      contractDate\n      address\n      area\n      notes\n      landUses {\n        id\n        landUse\n        area\n        pricePerHectare\n      }\n    }\n    property {\n      id\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyComparableSaleFragment on ComparableSale {\n    propertyId\n    saleId\n    sale {\n      id\n      price\n      contractDate\n      address\n      area\n      notes\n      landUses {\n        id\n        landUse\n        area\n        pricePerHectare\n      }\n    }\n    property {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetComparableSaleOnProperty($input: GetComparableSaleInput!) {\n    comparableSale(input: $input) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetComparableSaleOnProperty($input: GetComparableSaleInput!) {\n    comparableSale(input: $input) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateComparableSale(\n    $propertyId: ID!\n    $saleId: ID!\n    $input: UpdateComparableSaleInput!\n  ) {\n    updateComparableSale(\n      propertyId: $propertyId\n      saleId: $saleId\n      input: $input\n    ) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateComparableSale(\n    $propertyId: ID!\n    $saleId: ID!\n    $input: UpdateComparableSaleInput!\n  ) {\n    updateComparableSale(\n      propertyId: $propertyId\n      saleId: $saleId\n      input: $input\n    ) {\n      ...PropertyComparableSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentSalesSearchResults on ComparableSaleSearchResult {\n    latitude\n    longitude\n    price\n    saleDate\n    address {\n      address\n    }\n    boundingGeoJson\n    bbox\n    source\n    sourceRef\n    similarity\n    area\n    landUse\n    rainfall\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentSalesSearchResults on ComparableSaleSearchResult {\n    latitude\n    longitude\n    price\n    saleDate\n    address {\n      address\n    }\n    boundingGeoJson\n    bbox\n    source\n    sourceRef\n    similarity\n    area\n    landUse\n    rainfall\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentPropertyComparableSalesFragment on Property {\n    comparableSales {\n      name\n      similarity\n      propertyId\n      saleId\n      sale {\n        id\n        externalId\n        price\n        contractDate\n        centroid\n        address\n        bbox\n        geog\n      }\n      property {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentPropertyComparableSalesFragment on Property {\n    comparableSales {\n      name\n      similarity\n      propertyId\n      saleId\n      sale {\n        id\n        externalId\n        price\n        contractDate\n        centroid\n        address\n        bbox\n        geog\n      }\n      property {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentPropertyCMAVectorFragment on Property {\n    cmaVector\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentPropertyCMAVectorFragment on Property {\n    cmaVector\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyComparableSales($id: ID!) {\n    property(id: $id) {\n      id\n      ...CmaAssessmentPropertyComparableSalesFragment\n      ...CmaAssessmentPropertyCMAVectorFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyComparableSales($id: ID!) {\n    property(id: $id) {\n      id\n      ...CmaAssessmentPropertyComparableSalesFragment\n      ...CmaAssessmentPropertyCMAVectorFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyComparableSaleSearchResults(\n    $propertyId: String!\n    $similarity: Percent!\n    $radius: Int!\n    $startDate: Date\n    $endDate: Date\n    $minPrice: Int\n    $maxPrice: Int\n    $minArea: Int\n    $maxArea: Int\n    $orderBy: ComparableSaleSearchOrderBy\n  ) {\n    comparableSalesSearch(\n      propertyId: $propertyId\n      similarity: $similarity\n      radius: $radius\n      startDate: $startDate\n      endDate: $endDate\n      minPrice: $minPrice\n      maxPrice: $maxPrice\n      minArea: $minArea\n      maxArea: $maxArea\n      orderBy: $orderBy\n    ) {\n      ...CmaAssessmentSalesSearchResults\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyComparableSaleSearchResults(\n    $propertyId: String!\n    $similarity: Percent!\n    $radius: Int!\n    $startDate: Date\n    $endDate: Date\n    $minPrice: Int\n    $maxPrice: Int\n    $minArea: Int\n    $maxArea: Int\n    $orderBy: ComparableSaleSearchOrderBy\n  ) {\n    comparableSalesSearch(\n      propertyId: $propertyId\n      similarity: $similarity\n      radius: $radius\n      startDate: $startDate\n      endDate: $endDate\n      minPrice: $minPrice\n      maxPrice: $maxPrice\n      minArea: $minArea\n      maxArea: $maxArea\n      orderBy: $orderBy\n    ) {\n      ...CmaAssessmentSalesSearchResults\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation saveComparableSalesForProperty(\n    $propertyId: ID!\n    $input: [SaveComparableSaleInput!]!\n  ) {\n    saveComparableSales(propertyId: $propertyId, input: $input) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation saveComparableSalesForProperty(\n    $propertyId: ID!\n    $input: [SaveComparableSaleInput!]!\n  ) {\n    saveComparableSales(propertyId: $propertyId, input: $input) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteComparableSaleForProperty($propertyId: ID!, $saleId: ID!) {\n    deleteComparableSale(propertyId: $propertyId, saleId: $saleId) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteComparableSaleForProperty($propertyId: ID!, $saleId: ID!) {\n    deleteComparableSale(propertyId: $propertyId, saleId: $saleId) {\n      propertyId\n      saleId\n      property {\n        id\n      }\n      sale {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCmaReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...CmaAssessmentReportsPage_Report\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCmaReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...CmaAssessmentReportsPage_Report\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteCmaReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCmaReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCmaReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetCmaReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaAssessmentReportsPage_Report on Report {\n    id\n    ...CmaReportListItem_Report\n  }\n',
): (typeof documents)['\n  fragment CmaAssessmentReportsPage_Report on Report {\n    id\n    ...CmaReportListItem_Report\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n',
): (typeof documents)['\n  fragment CmaReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CmaReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n',
): (typeof documents)['\n  fragment CmaReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCmaReports($propertyId: ID!) {\n    cmaReports(propertyId: $propertyId) {\n      id\n      ...CmaAssessmentReportsPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetCmaReports($propertyId: ID!) {\n    cmaReports(propertyId: $propertyId) {\n      id\n      ...CmaAssessmentReportsPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCmaReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaReportListItem_ReportPdfLink\n    }\n  }\n',
): (typeof documents)['\n  query GetCmaReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...CmaReportListItem_ReportPdfLink\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCmaReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCmaReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n',
): (typeof documents)['\n  fragment DeforestationAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateDeforestationReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDeforestationReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteDeforestationReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteDeforestationReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeforestationReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetDeforestationReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationAssessmentReportsPage_Report on Report {\n    id\n    ...DeforestationReportListItem_Report\n  }\n',
): (typeof documents)['\n  fragment DeforestationAssessmentReportsPage_Report on Report {\n    id\n    ...DeforestationReportListItem_Report\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n',
): (typeof documents)['\n  fragment DeforestationReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeforestationReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n',
): (typeof documents)['\n  fragment DeforestationReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeforestationReports($propertyId: ID!) {\n    deforestationReports(propertyId: $propertyId) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetDeforestationReports($propertyId: ID!) {\n    deforestationReports(propertyId: $propertyId) {\n      id\n      ...DeforestationAssessmentReportsPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeforestationReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationReportListItem_ReportPdfLink\n    }\n  }\n',
): (typeof documents)['\n  query GetDeforestationReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...DeforestationReportListItem_ReportPdfLink\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateDeforestationReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDeforestationReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n      ...AllTotalGhgEmissions_AllEmissionsCalculationResultFragment\n      ...AllBreakdownGhg_AllEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n      ...AllTotalGhgEmissions_AllEmissionsCalculationResultFragment\n      ...AllBreakdownGhg_AllEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllBreakdownGhg_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment AllBreakdownGhg_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllTotalGhgEmissions_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n    }\n    purchasedOffsets {\n      total\n    }\n    net {\n      total\n    }\n  }\n',
): (typeof documents)['\n  fragment AllTotalGhgEmissions_AllEmissionsCalculationResultFragment on AllEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n    }\n    purchasedOffsets {\n      total\n    }\n    net {\n      total\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetAllEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    allEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetAllEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    allEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsAllPage_AllEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...BeefIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...BeefIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment BeefEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        beef {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment BeefTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        beef {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    beefEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    beefEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBeefPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      ...BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloIntensity_BuffaloEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      ...BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment\n      ...BuffaloIntensity_BuffaloEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment BuffaloBreakdownGhg_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment BuffaloEmissionsBreakdown_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloIntensity_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    intensities {\n      buffaloMeatIncludingSequestration\n      buffaloMeatExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment BuffaloIntensity_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n    intensities {\n      buffaloMeatIncludingSequestration\n      buffaloMeatExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment BuffaloTotalGhgEmissions_BuffaloEmissionsCalculationResultFragment on BuffaloEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBuffaloEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    buffaloEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetBuffaloEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    buffaloEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsBuffaloPage_BuffaloEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      ...CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment\n      ...CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment\n      ...CottonBreakdownGhg_CottonEmissionsCalculationResultFragment\n      ...CottonIntensity_CottonEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      ...CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment\n      ...CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment\n      ...CottonBreakdownGhg_CottonEmissionsCalculationResultFragment\n      ...CottonIntensity_CottonEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CottonBreakdownGhg_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment CottonBreakdownGhg_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ',
): (typeof documents)['\n    fragment CottonEmissionsBreakdown_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CottonIntensity_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    intensities {\n      balesExcludingSequestration\n      balesIncludingSequestration\n      tonnesCropExcludingSequestration\n      tonnesCropIncludingSequestration\n      lintIncludingSequestration\n      lintExcludingSequestration\n      seedIncludingSequestration\n      seedExcludingSequestration\n      lintEconomicAllocation\n      seedEconomicAllocation\n    }\n  }\n',
): (typeof documents)['\n  fragment CottonIntensity_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n    intensities {\n      balesExcludingSequestration\n      balesIncludingSequestration\n      tonnesCropExcludingSequestration\n      tonnesCropIncludingSequestration\n      lintIncludingSequestration\n      lintExcludingSequestration\n      seedIncludingSequestration\n      seedExcludingSequestration\n      lintEconomicAllocation\n      seedEconomicAllocation\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment CottonTotalGhgEmissions_CottonEmissionsCalculationResultFragment on CottonEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCottonEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    cottonEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetCottonEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    cottonEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsCottonPage_CottonEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      ...DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment\n      ...DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment\n      ...DairyBreakdownGhg_DairyEmissionsCalculationResultFragment\n      ...DairyIntensity_DairyEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      ...DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment\n      ...DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment\n      ...DairyBreakdownGhg_DairyEmissionsCalculationResultFragment\n      ...DairyIntensity_DairyEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyBreakdownGhg_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment DairyBreakdownGhg_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ',
): (typeof documents)['\n    fragment DairyEmissionsBreakdown_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        limeCO2\n        ureaCO2\n        transportCO2\n        fuelCH4\n        transportCH4\n        entericCH4\n        manureManagementCH4\n        atmosphericDepositionN2O\n        manureManagementN2O\n        animalWasteN2O\n        fertiliserN2O\n        urineAndDungN2O\n        leachingAndRunoffN2O\n        fuelN2O\n        transportN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyIntensity_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    intensities {\n      intensity\n    }\n  }\n',
): (typeof documents)['\n  fragment DairyIntensity_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n    intensities {\n      intensity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment DairyTotalGhgEmissions_DairyEmissionsCalculationResultFragment on DairyEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    dairyEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    dairyEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDairyPage_DairyEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n      ...DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment\n      ...DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment\n      ...DeerBreakdownGhg_DeerEmissionsCalculationResultFragment\n      ...DeerIntensity_DeerEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n      ...DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment\n      ...DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment\n      ...DeerBreakdownGhg_DeerEmissionsCalculationResultFragment\n      ...DeerIntensity_DeerEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerBreakdownGhg_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerBreakdownGhg_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      limeCO2\n      ureaCO2\n      transportCO2\n      fuelCH4\n      transportCH4\n      entericCH4\n      manureManagementCH4\n      atmosphericDepositionN2O\n      manureManagementN2O\n      animalWasteN2O\n      fertiliserN2O\n      urineAndDungN2O\n      leachingAndRunoffN2O\n      fuelN2O\n      transportN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerEmissionsBreakdown_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      limeCO2\n      ureaCO2\n      transportCO2\n      fuelCH4\n      transportCH4\n      entericCH4\n      manureManagementCH4\n      atmosphericDepositionN2O\n      manureManagementN2O\n      animalWasteN2O\n      fertiliserN2O\n      urineAndDungN2O\n      leachingAndRunoffN2O\n      fuelN2O\n      transportN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerIntensity_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    intensities {\n      deerMeatIncludingSequestration\n      deerMeatExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerIntensity_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    intensities {\n      deerMeatIncludingSequestration\n      deerMeatExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerTotalGhgEmissions_DeerEmissionsCalculationResultFragment on DeerEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeerEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    deerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetDeerEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    deerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsDeerPage_DeerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      ...FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotIntensity_FeedlotEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      ...FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment\n      ...FeedlotIntensity_FeedlotEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment FeedlotBreakdownGhg_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        entericCH4\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        limeCO2\n        manureAppliedToSoilN2O\n        manureDirectN2O\n        manureIndirectN2O\n        manureManagementCH4\n        transportCH4\n        transportCO2\n        transportN2O\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n        feed\n        purchaseLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment FeedlotEmissionsBreakdown_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        entericCH4\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        limeCO2\n        manureAppliedToSoilN2O\n        manureDirectN2O\n        manureIndirectN2O\n        manureManagementCH4\n        transportCH4\n        transportCO2\n        transportN2O\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n        feed\n        purchaseLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotIntensity_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment FeedlotIntensity_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n    intensities {\n      beefIncludingSequestration\n      beefExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      intermediate {\n        carbonSequestration\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment FeedlotTotalGhgEmissions_FeedlotEmissionsCalculationResultFragment on FeedlotEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      intermediate {\n        carbonSequestration\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    feedlotEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    feedlotEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsFeedlotPage_FeedlotEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n      ...GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment\n      ...GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment\n      ...GoatBreakdownGhg_GoatEmissionsCalculationResultFragment\n      ...GoatIntensity_GoatEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n      ...GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment\n      ...GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment\n      ...GoatBreakdownGhg_GoatEmissionsCalculationResultFragment\n      ...GoatIntensity_GoatEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatBreakdownGhg_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatBreakdownGhg_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      urineAndDungN2O\n      atmosphericDepositionN2O\n      leachingAndRunoffN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedMineralSupplementation\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatEmissionsBreakdown_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      urineAndDungN2O\n      atmosphericDepositionN2O\n      leachingAndRunoffN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedMineralSupplementation\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      purchasedLivestock\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatIntensity_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    intensities {\n      goatMeatBreedingIncludingSequestration\n      goatMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatIntensity_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    intensities {\n      goatMeatBreedingIncludingSequestration\n      goatMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatTotalGhgEmissions_GoatEmissionsCalculationResultFragment on GoatEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    goatEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    goatEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGoatPage_GoatEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      ...GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment\n      ...GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment\n      ...GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment\n      ...GrainsIntensity_GrainsEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      ...GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment\n      ...GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment\n      ...GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment\n      ...GrainsIntensity_GrainsEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment GrainsBreakdownGhg_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ',
): (typeof documents)['\n    fragment GrainsEmissionsBreakdown_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n        ureaCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GrainsIntensity_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    intensitiesWithSequestration {\n      grainsIncludingSequestration\n      grainsExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment GrainsIntensity_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n    intensitiesWithSequestration {\n      grainsIncludingSequestration\n      grainsExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment GrainsTotalGhgEmissions_GrainsEmissionsCalculationResultFragment on GrainsEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGrainsEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    grainsEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetGrainsEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    grainsEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsGrainsPage_GrainsEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      ...HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureIntensity_HorticultureEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      ...HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment\n      ...HorticultureIntensity_HorticultureEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ',
): (typeof documents)['\n    fragment HorticultureBreakdownGhg_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ',
): (typeof documents)['\n    fragment HorticultureEmissionsBreakdown_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        cropResidueN2O\n        fertiliserN2O\n        fieldBurningCH4\n        fieldBurningN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        limeCO2\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment HorticultureIntensity_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      intensities {\n        tonnesCropIncludingSequestration\n        tonnesCropExcludingSequestration\n      }\n    }\n  ',
): (typeof documents)['\n    fragment HorticultureIntensity_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      intensities {\n        tonnesCropIncludingSequestration\n        tonnesCropExcludingSequestration\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment HorticultureTotalGhgEmissions_HorticultureEmissionsCalculationResultFragment on HorticultureEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetHorticultureEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    horticultureEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetHorticultureEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    horticultureEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsHorticulturePage_HorticultureEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissions_EmissionsCalculationInputsFragment on EmissionsCalculationInputs {\n      requests {\n        endpoint\n        payload\n      }\n      primaryActivities {\n        id\n        name\n        start\n        end\n        activityType\n        cropping {\n          croppingType\n        }\n      }\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissions_EmissionsCalculationInputsFragment on EmissionsCalculationInputs {\n      requests {\n        endpoint\n        payload\n      }\n      primaryActivities {\n        id\n        name\n        start\n        end\n        activityType\n        cropping {\n          croppingType\n        }\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EmissionsAssessmentLayout_PropertyFragment on Property {\n    name\n  }\n',
): (typeof documents)['\n  fragment EmissionsAssessmentLayout_PropertyFragment on Property {\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyForEmissionsAssessmentLayout($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...EmissionsAssessmentLayout_PropertyFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyForEmissionsAssessmentLayout($propertyId: ID!) {\n    property(id: $propertyId) {\n      id\n      ...EmissionsAssessmentLayout_PropertyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n      ...PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment\n      ...PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment\n      ...PorkBreakdownGhg_PorkEmissionsCalculationResultFragment\n      ...PorkIntensity_PorkEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n      ...PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment\n      ...PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment\n      ...PorkBreakdownGhg_PorkEmissionsCalculationResultFragment\n      ...PorkIntensity_PorkEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkBreakdownGhg_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkBreakdownGhg_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      manureManagementDirectN2O\n      atmosphericDepositionN2O\n      atmosphericDepositionIndirectN2O\n      leachingAndRunoffSoilN2O\n      leachingAndRunoffMMSN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      bedding\n      purchasedLivestock\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkEmissionsBreakdown_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      fuelCO2\n      fuelCH4\n      fuelN2O\n      ureaCO2\n      limeCO2\n      fertiliserN2O\n      entericCH4\n      manureManagementCH4\n      manureManagementDirectN2O\n      atmosphericDepositionN2O\n      atmosphericDepositionIndirectN2O\n      leachingAndRunoffSoilN2O\n      leachingAndRunoffMMSN2O\n    }\n    scope2 {\n      electricity\n    }\n    scope3 {\n      fertiliser\n      purchasedFeed\n      herbicide\n      electricity\n      fuel\n      lime\n      bedding\n      purchasedLivestock\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkIntensity_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    intensities {\n      porkMeatIncludingSequestration\n      porkMeatExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkIntensity_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    intensities {\n      porkMeatIncludingSequestration\n      porkMeatExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkTotalGhgEmissions_PorkEmissionsCalculationResultFragment on PorkEmissionsCalculationResult {\n    scope1 {\n      total\n    }\n    scope2 {\n      total\n    }\n    scope3 {\n      total\n    }\n    carbonSequestration {\n      total\n      intermediate {\n        total\n        average\n      }\n    }\n    net {\n      total\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    porkEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    porkEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPorkPage_PorkEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment\n      ...PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryBroilerBreakdownGhg_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryBroilerEmissionsBreakdown_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryMeatIncludingSequestration\n        poultryMeatExcludingSequestration\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryBroilerIntensity_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryMeatIncludingSequestration\n        poultryMeatExcludingSequestration\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryBroilerTotalGhgEmissions_PoultryBroilerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryBroilerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryBroilerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryBroilerPage_PoultryBroilerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      ...PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment\n      ...PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryLayerBreakdownGhg_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryLayerEmissionsBreakdown_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        atmosphericDepositionN2O\n        fuelCH4\n        fuelCO2\n        fuelN2O\n        leachingAndRunoffN2O\n        manureManagementCH4\n        manureManagementN2O\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        herbicide\n        purchasedFeed\n        purchasedHay\n        purchasedLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryEggsIncludingSequestration\n        poultryEggsExcludingSequestration\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryLayerIntensity_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      intensities {\n        poultryEggsIncludingSequestration\n        poultryEggsExcludingSequestration\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PoultryLayerTotalGhgEmissions_PoultryLayerEmissionsCalculationResultFragment on PoultryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryLayerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    poultryLayerEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsPoultryLayerPage_PoultryLayerEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...SheepIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      ...SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment\n      ...SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment\n      ...SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment\n      ...SheepIntensity_SheepBeefEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepBreakdownGhg_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  ',
): (typeof documents)['\n    fragment SheepEmissionsBreakdown_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        leachingAndRunoffN2O\n        atmosphericDepositionN2O\n        urineAndDungN2O\n        manureManagementCH4\n        entericCH4\n        fertiliserN2O\n        limeCO2\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        savannahBurningN2O\n        savannahBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        purchasedMineralSupplementation\n        purchasedFeed\n        herbicide\n        electricity\n        fuel\n        lime\n        purchasedLivestock\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      sheepMeatBreedingIncludingSequestration\n      sheepMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepIntensity_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n    intensities {\n      sheepMeatBreedingIncludingSequestration\n      sheepMeatBreedingExcludingSequestration\n      woolIncludingSequestration\n      woolExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        sheep {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment SheepTotalGhgEmissions_SheepBeefEmissionsCalculationResultFragment on SheepBeefEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n        intermediate {\n          total\n          average\n        }\n      }\n      intermediate {\n        sheep {\n          carbonSequestration\n        }\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sheepEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sheepEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSheepPage_SheepBeefEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      ...SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment\n      ...SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment\n      ...SugarBreakdownGhg_SugarEmissionsCalculationResultFragment\n      ...SugarIntensity_SugarEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      ...SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment\n      ...SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment\n      ...SugarBreakdownGhg_SugarEmissionsCalculationResultFragment\n      ...SugarIntensity_SugarEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SugarBreakdownGhg_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n',
): (typeof documents)['\n  fragment SugarBreakdownGhg_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    scope1 {\n      totalCO2\n      totalCH4\n      totalN2O\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        limeCO2\n        fertiliserN2O\n        atmosphericDepositionN2O\n        leachingAndRunoffN2O\n        cropResidueN2O\n        fieldBurningN2O\n        fieldBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  ',
): (typeof documents)['\n    fragment SugarEmissionsBreakdown_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        ureaCO2\n        limeCO2\n        fertiliserN2O\n        atmosphericDepositionN2O\n        leachingAndRunoffN2O\n        cropResidueN2O\n        fieldBurningN2O\n        fieldBurningCH4\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        fertiliser\n        herbicide\n        electricity\n        fuel\n        lime\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SugarIntensity_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    intensities {\n      sugarIncludingSequestration\n      sugarExcludingSequestration\n    }\n  }\n',
): (typeof documents)['\n  fragment SugarIntensity_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n    intensities {\n      sugarIncludingSequestration\n      sugarExcludingSequestration\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment SugarTotalGhgEmissions_SugarEmissionsCalculationResultFragment on SugarEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      carbonSequestration {\n        total\n      }\n      net {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSugarEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sugarEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetSugarEmissions($propertyId: ID!, $filter: EmissionsFilterInput) {\n    sugarEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsSugarPage_SugarEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      ...WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment\n    }\n  ',
): (typeof documents)['\n    fragment AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      ...WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment\n      ...WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n        totalHFCs\n      }\n    }\n  ',
): (typeof documents)['\n    fragment WildSeaFisheryBreakdownGhg_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        totalCO2\n        totalCH4\n        totalN2O\n        totalHFCs\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        hfcsRefrigerantLeakage\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        bait\n      }\n    }\n  ',
): (typeof documents)['\n    fragment WildSeaFisheryEmissionsBreakdown_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        fuelCO2\n        fuelCH4\n        fuelN2O\n        hfcsRefrigerantLeakage\n      }\n      scope2 {\n        electricity\n      }\n      scope3 {\n        electricity\n        fuel\n        bait\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      intensities {\n        intensityIncludingCarbonOffset\n        intensityExcludingCarbonOffset\n      }\n    }\n  ',
): (typeof documents)['\n    fragment WildSeaFisheryIntensity_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      intensities {\n        intensityIncludingCarbonOffset\n        intensityExcludingCarbonOffset\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      net {\n        total\n      }\n      purchasedOffsets {\n        total\n      }\n    }\n  ',
): (typeof documents)['\n    fragment WildSeaFisheryTotalGhgEmissions_WildSeaFisheryEmissionsCalculationResultFragment on WildSeaFisheryEmissionsCalculationResult {\n      scope1 {\n        total\n      }\n      scope2 {\n        total\n      }\n      scope3 {\n        total\n      }\n      net {\n        total\n      }\n      purchasedOffsets {\n        total\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetWildSeaFisheryEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    wildSeaFisheryEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n',
): (typeof documents)['\n  query GetWildSeaFisheryEmissions(\n    $propertyId: ID!\n    $filter: EmissionsFilterInput\n  ) {\n    wildSeaFisheryEmissions(propertyId: $propertyId, filter: $filter) {\n      result {\n        ...AssessmentGhgEmissionsWildSeaFisheryPage_WildSeaFisheryEmissionsCalculationResultFragment\n      }\n      inputs {\n        ...AssessmentGhgEmissions_EmissionsCalculationInputsFragment\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment KyeAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n',
): (typeof documents)['\n  fragment KyeAssessmentReportsEditPage_Report on Report {\n    id\n    name\n    sections\n    propertyId\n    teamId\n    type\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateKyeReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...KyeAssessmentReportsPage_Report\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateKyeReport($id: ID!, $input: UpdateReportInput!) {\n    updateReport(id: $id, input: $input) {\n      id\n      ...KyeAssessmentReportsPage_Report\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteKyeReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteKyeReport($id: ID!) {\n    deleteReport(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetKyeReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetKyeReport($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeAssessmentReportsEditPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment KyeAssessmentReportsPage_Report on Report {\n    id\n    ...KyeReportListItem_Report\n  }\n',
): (typeof documents)['\n  fragment KyeAssessmentReportsPage_Report on Report {\n    id\n    ...KyeReportListItem_Report\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment KyeReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n',
): (typeof documents)['\n  fragment KyeReportListItem_Report on Report {\n    id\n    teamId\n    propertyId\n    name\n    createdAt\n    status\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment KyeReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n',
): (typeof documents)['\n  fragment KyeReportListItem_ReportPdfLink on Report {\n    id\n    signedPdfUrl\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetKyeReports($propertyId: ID!) {\n    kyeReports(propertyId: $propertyId) {\n      id\n      ...KyeAssessmentReportsPage_Report\n    }\n  }\n',
): (typeof documents)['\n  query GetKyeReports($propertyId: ID!) {\n    kyeReports(propertyId: $propertyId) {\n      id\n      ...KyeAssessmentReportsPage_Report\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetKyeReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeReportListItem_ReportPdfLink\n    }\n  }\n',
): (typeof documents)['\n  query GetKyeReportSignedUrl($id: ID!) {\n    report(id: $id) {\n      id\n      ...KyeReportListItem_ReportPdfLink\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateKyeReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateKyeReport($input: CreateReportInput!) {\n    createReport(input: $input) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertiesBusinessActivitiesAllocationIndexFragment on Activity {\n    __typename\n    id\n    start\n    end\n    name\n    targetAllocations {\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n      percentage\n    }\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertiesBusinessActivitiesAllocationIndexFragment on Activity {\n    __typename\n    id\n    start\n    end\n    name\n    targetAllocations {\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n      percentage\n    }\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BusinessActivitiesAllocationConsumptionEditFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    consumptionAllocations {\n      percentage\n      sourceConsumption {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment BusinessActivitiesAllocationConsumptionEditFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    consumptionAllocations {\n      percentage\n      sourceConsumption {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllocationIndexActivitiesFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    targetAllocations {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n    ... on CroppingActivity {\n      croppingType\n      varieties {\n        yieldInKg\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment AllocationIndexActivitiesFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    targetAllocations {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n    ... on CroppingActivity {\n      croppingType\n      varieties {\n        yieldInKg\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BusinessActivitiesAllocationIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      name\n      consumableType\n      consumableSubType\n      amount\n      consumptionAllocations {\n        percentage\n        sourceConsumption {\n          id\n        }\n        targetActivity {\n          id\n        }\n      }\n    }\n  ',
): (typeof documents)['\n    fragment BusinessActivitiesAllocationIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      name\n      consumableType\n      consumableSubType\n      amount\n      consumptionAllocations {\n        percentage\n        sourceConsumption {\n          id\n        }\n        targetActivity {\n          id\n        }\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllocateToActivitiesComponentActivityFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n',
): (typeof documents)['\n  fragment AllocateToActivitiesComponentActivityFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSourceActivityAllocations($id: ID!) {\n    activity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesAllocationIndexFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSourceActivityAllocations($id: ID!) {\n    activity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesAllocationIndexFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetActivitiesToAllocate(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocationIndexActivitiesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetActivitiesToAllocate(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocationIndexActivitiesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetAllocationConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      ...BusinessActivitiesAllocationIndexConsumptionsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetAllocationConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      ...BusinessActivitiesAllocationIndexConsumptionsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetConsumptionForAllocation($id: ID!) {\n    consumption(id: $id) {\n      ...BusinessActivitiesAllocationConsumptionEditFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetConsumptionForAllocation($id: ID!) {\n    consumption(id: $id) {\n      ...BusinessActivitiesAllocationConsumptionEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query ActivityTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query ActivityTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetConsumptionTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetConsumptionTargetActivities(\n    $propertyId: ID!\n    $searchParams: ActivityQueryParamsInput\n  ) {\n    activities(propertyId: $propertyId, params: $searchParams) {\n      id\n      ...AllocateToActivitiesComponentActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateConsumptionAllocation(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateConsumptionAllocation(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCropAllocations(\n    $sourceActivityId: ID!\n    $updateActivityAllocationsInput: UpdateActivityAllocationsInput!\n  ) {\n    updateActivityAllocations(\n      sourceActivityId: $sourceActivityId\n      updateActivityAllocationsInput: $updateActivityAllocationsInput\n    ) {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCropAllocations(\n    $sourceActivityId: ID!\n    $updateActivityAllocationsInput: UpdateActivityAllocationsInput!\n  ) {\n    updateActivityAllocations(\n      sourceActivityId: $sourceActivityId\n      updateActivityAllocationsInput: $updateActivityAllocationsInput\n    ) {\n      percentage\n      sourceActivity {\n        id\n      }\n      targetActivity {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BusinessActivitiesConsumptionIndexFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    name\n    notes\n    consumptionAllocations {\n      percentage\n      targetActivity {\n        id\n      }\n      sourceConsumption {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment BusinessActivitiesConsumptionIndexFragment on Consumption {\n    __typename\n    id\n    startDate\n    endDate\n    consumableType\n    consumableSubType\n    amount\n    name\n    notes\n    consumptionAllocations {\n      percentage\n      targetActivity {\n        id\n      }\n      sourceConsumption {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateConsumption(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateConsumption(\n    $id: ID!\n    $updateConsumptionInput: UpdateConsumptionInput!\n  ) {\n    updateConsumption(\n      id: $id\n      updateConsumptionInput: $updateConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateConsumption(\n    $propertyId: ID!\n    $createConsumptionInput: CreateConsumptionInput!\n  ) {\n    createConsumption(\n      propertyId: $propertyId\n      createConsumptionInput: $createConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateConsumption(\n    $propertyId: ID!\n    $createConsumptionInput: CreateConsumptionInput!\n  ) {\n    createConsumption(\n      propertyId: $propertyId\n      createConsumptionInput: $createConsumptionInput\n    ) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteConsumption($id: ID!) {\n    deleteConsumption(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteConsumption($id: ID!) {\n    deleteConsumption(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      id\n      ...BusinessActivitiesConsumptionIndexConsumptionsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetConsumptions($propertyId: ID!) {\n    consumptions(propertyId: $propertyId) {\n      id\n      ...BusinessActivitiesConsumptionIndexConsumptionsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetConsumption($id: ID!) {\n    consumption(id: $id) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetConsumption($id: ID!) {\n    consumption(id: $id) {\n      id\n      ...BusinessActivitiesConsumptionIndexFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BusinessActivitiesConsumptionIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      consumableType\n      consumableSubType\n      amount\n      name\n    }\n  ',
): (typeof documents)['\n    fragment BusinessActivitiesConsumptionIndexConsumptionsFragment on Consumption {\n      __typename\n      id\n      startDate\n      endDate\n      consumableType\n      consumableSubType\n      amount\n      name\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ActivitiesActivityEditFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n',
): (typeof documents)['\n  fragment ActivitiesActivityEditFragment on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetActivity($id: ID!) {\n    activity(id: $id) {\n      id\n      ...ActivitiesActivityEditFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetActivity($id: ID!) {\n    activity(id: $id) {\n      id\n      ...ActivitiesActivityEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateActivity(\n    $id: ID!\n    $updateActivityInput: UpdateActivityInput!\n  ) {\n    updateActivity(id: $id, updateActivityInput: $updateActivityInput) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation updateActivity(\n    $id: ID!\n    $updateActivityInput: UpdateActivityInput!\n  ) {\n    updateActivity(id: $id, updateActivityInput: $updateActivityInput) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation deleteActivity($id: ID!) {\n    deleteActivity(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation deleteActivity($id: ID!) {\n    deleteActivity(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefMonthlyForm_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageCowsCalving\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefMonthlyForm_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageCowsCalving\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    ...TransactionForm_LivestockTransactionEventFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    ...TransactionForm_LivestockTransactionEventFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    ...TransactionForm_BeefMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    year\n    month\n    beefClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_BeefMonthLivestockActivityFragment on BeefMonthLivestockActivity {\n    year\n    month\n    beefClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateBeefMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateBeefMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createBeefLivestockTransactionEventForUpdateTransaction(\n      $beefMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBeefLivestockTransactionEvent(\n        beefMonthLivestockActivityId: $beefMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createBeefLivestockTransactionEventForUpdateTransaction(\n      $beefMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBeefLivestockTransactionEvent(\n        beefMonthLivestockActivityId: $beefMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefActivityForMonthlyBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefMonthlyBreakdown_BeefLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefActivityForMonthlyBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefMonthlyBreakdown_BeefLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBeefMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBeefMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefMonthlyBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBeefMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      beefMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BeefMonthLivestockActivityFragment\n        ...UpdateTransaction_BeefMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBeefMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      beefMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BeefMonthLivestockActivityFragment\n        ...UpdateTransaction_BeefMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefMonthlyBreakdown_BeefLivestockActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n  }\n',
): (typeof documents)['\n  fragment BeefMonthlyBreakdown_BeefLivestockActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefMonthlyBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    ...BeefMonthlyForm_BeefMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment BeefMonthlyBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n    ...BeefMonthlyForm_BeefMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BeefActivityLivestockBreedingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Breeding\n      cowsGt2Breeding\n      heifersLt1Breeding\n      heifers1to2Breeding\n      heifersGt2Breeding\n      steersLt1Breeding\n      steers1to2Breeding\n      steersGt2Breeding\n    }\n  ',
): (typeof documents)['\n    fragment BeefActivityLivestockBreedingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Breeding\n      cowsGt2Breeding\n      heifersLt1Breeding\n      heifers1to2Breeding\n      heifersGt2Breeding\n      steersLt1Breeding\n      steers1to2Breeding\n      steersGt2Breeding\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BeefActivityLivestockTradingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Trading\n      cowsGt2Trading\n      heifersLt1Trading\n      heifers1to2Trading\n      heifersGt2Trading\n      steersLt1Trading\n      steers1to2Trading\n      steersGt2Trading\n    }\n  ',
): (typeof documents)['\n    fragment BeefActivityLivestockTradingBeefLivestockActivity on BeefLivestockActivity {\n      bullsGt1Trading\n      cowsGt2Trading\n      heifersLt1Trading\n      heifers1to2Trading\n      heifersGt2Trading\n      steersLt1Trading\n      steers1to2Trading\n      steersGt2Trading\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefActivityLivestockBeefLivestockActivity on BeefLivestockActivity {\n    ...BeefActivityLivestockBreedingBeefLivestockActivity\n    ...BeefActivityLivestockTradingBeefLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment BeefActivityLivestockBeefLivestockActivity on BeefLivestockActivity {\n    ...BeefActivityLivestockBreedingBeefLivestockActivity\n    ...BeefActivityLivestockTradingBeefLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseBeefMonthlyFeedBreakdown_ActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    businessActivity {\n      id\n      state\n    }\n  }\n',
): (typeof documents)['\n  fragment UseBeefMonthlyFeedBreakdown_ActivityFragment on BeefLivestockActivity {\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    businessActivity {\n      id\n      state\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  ',
): (typeof documents)['\n    fragment UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity on BeefMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateBeefMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateBeefMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateBeefMonthLivestockActivityInput: UpdateBeefMonthLivestockActivityInput!\n    ) {\n      updateBeefMonthLivestockActivity(\n        id: $id\n        updateBeefMonthLivestockActivityInput: $updateBeefMonthLivestockActivityInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefActivityForMonthlyFeedBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...UseBeefMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefActivityForMonthlyFeedBreakdown($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...UseBeefMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBeefMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseBeefMonthlyFeedBreakdown_BeefMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefLivestockActivityFeedDrySeasonMix($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedDrySeasonMix_BeefLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefLivestockActivityFeedDrySeasonMix($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedDrySeasonMix_BeefLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefFeedDrySeasonMix_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefFeedDrySeasonMix_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefActivityFeed($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockFeed_BeefLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefActivityFeed($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockFeed_BeefLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefActivityLivestockFeed_BeefLivestockActivityFragment on BeefLivestockActivity {\n    id\n    __typename\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefActivityLivestockFeed_BeefLivestockActivityFragment on BeefLivestockActivity {\n    id\n    __typename\n    bullsGt1Breeding\n    cowsGt2Breeding\n    heifersLt1Breeding\n    heifers1to2Breeding\n    heifersGt2Breeding\n    steersLt1Breeding\n    steers1to2Breeding\n    steersGt2Breeding\n    bullsGt1Trading\n    cowsGt2Trading\n    heifersLt1Trading\n    heifers1to2Trading\n    heifersGt2Trading\n    steersLt1Trading\n    steers1to2Trading\n    steersGt2Trading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefLivestockActivityFeedMineralBlock($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedMineralBlock_BeefLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefLivestockActivityFeedMineralBlock($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedMineralBlock_BeefLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefFeedMineralBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefFeedMineralBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefLivestockActivityFeedWeaner($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedWeanerBlock_BeefLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefLivestockActivityFeedWeaner($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefFeedWeanerBlock_BeefLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefFeedWeanerBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment BeefFeedWeanerBlock_BeefLivestockActivityFragment on BeefLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateBeefLivestockActivity(\n    $id: ID!\n    $updateBeefLivestockActivityInput: UpdateBeefLivestockActivityInput!\n  ) {\n    updateBeefLivestockActivity(\n      id: $id\n      updateBeefLivestockActivityInput: $updateBeefLivestockActivityInput\n    ) {\n      id\n      ...BeefActivityLivestockBreedingBeefLivestockActivity\n      ...BeefActivityLivestockTradingBeefLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBeefLivestockActivity(\n    $id: ID!\n    $updateBeefLivestockActivityInput: UpdateBeefLivestockActivityInput!\n  ) {\n    updateBeefLivestockActivity(\n      id: $id\n      updateBeefLivestockActivityInput: $updateBeefLivestockActivityInput\n    ) {\n      id\n      ...BeefActivityLivestockBreedingBeefLivestockActivity\n      ...BeefActivityLivestockTradingBeefLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefActivity($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockBeefLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefActivity($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...BeefActivityLivestockBeefLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBeefActivityInHeader($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBeefActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetBeefActivityInHeader($id: ID!) {\n    beefLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBeefActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBeefMonthLivestockActivitiesForActivity(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBeefMonthLivestockActivitiesForActivity(\n      $listBeefMonthLivestockActivitiesInput: ListBeefMonthLivestockActivitiesInput!\n    ) {\n      beefMonthLivestockActivities(\n        listBeefMonthLivestockActivitiesInput: $listBeefMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BeefLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBeefActivity on BeefLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBeefActivity on BeefLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BeefLivestockActivity_MonthActivities on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    beefClass\n  }\n',
): (typeof documents)['\n  fragment BeefLivestockActivity_MonthActivities on BeefMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    beefClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createBeefLivestockActivity(\n    $propertyId: ID!\n    $createBeefLivestockActivityInput: CreateBeefLivestockActivityInput!\n  ) {\n    createBeefLivestockActivity(\n      propertyId: $propertyId\n      createBeefLivestockActivityInput: $createBeefLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createBeefLivestockActivity(\n    $propertyId: ID!\n    $createBeefLivestockActivityInput: CreateBeefLivestockActivityInput!\n  ) {\n    createBeefLivestockActivity(\n      propertyId: $propertyId\n      createBeefLivestockActivityInput: $createBeefLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloMonthlyForm_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageCowsCalving\n    seasonalCalvingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment BuffaloMonthlyForm_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageCowsCalving\n    seasonalCalvingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    ...TransactionForm_BuffaloMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    year\n    month\n    buffaloClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_BuffaloMonthLivestockActivityFragment on BuffaloMonthLivestockActivity {\n    year\n    month\n    buffaloClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateBuffaloMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBuffaloMonthLivestockActivityInput: UpdateBuffaloMonthLivestockActivityInput!\n    ) {\n      updateBuffaloMonthLivestockActivity(\n        id: $id\n        updateBuffaloMonthLivestockActivityInput: $updateBuffaloMonthLivestockActivityInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateBuffaloMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateBuffaloMonthLivestockActivityInput: UpdateBuffaloMonthLivestockActivityInput!\n    ) {\n      updateBuffaloMonthLivestockActivity(\n        id: $id\n        updateBuffaloMonthLivestockActivityInput: $updateBuffaloMonthLivestockActivityInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createBuffaloLivestockTransactionEventForUpdateTransaction(\n      $buffaloMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBuffaloLivestockTransactionEvent(\n        buffaloMonthLivestockActivityId: $buffaloMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createBuffaloLivestockTransactionEventForUpdateTransaction(\n      $buffaloMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createBuffaloLivestockTransactionEvent(\n        buffaloMonthLivestockActivityId: $buffaloMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateLivestockTransactionEventForUpdateTransaction(\n      $updateLivestockTransactionEventInput: UpdateLivestockTransactionEventInput!\n    ) {\n      updateLivestockTransactionEvent(\n        updateLivestockTransactionEventInput: $updateLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateLivestockTransactionEventForUpdateTransaction(\n      $updateLivestockTransactionEventInput: UpdateLivestockTransactionEventInput!\n    ) {\n      updateLivestockTransactionEvent(\n        updateLivestockTransactionEventInput: $updateLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBuffaloActivityForMonthlyBreakdown($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBuffaloActivityForMonthlyBreakdown($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBuffaloMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBuffaloMonthLivestockActivitiesForMonthlyBreakdown(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBuffaloMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      buffaloMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BuffaloMonthLivestockActivityFragment\n        ...UpdateTransaction_BuffaloMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBuffaloMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      buffaloMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_BuffaloMonthLivestockActivityFragment\n        ...UpdateTransaction_BuffaloMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    bullsBreeding\n    cowsBreeding\n    steersBreeding\n    calfsBreeding\n    bullsTrading\n    cowsTrading\n    steersTrading\n    calfsTrading\n  }\n',
): (typeof documents)['\n  fragment BuffaloMonthlyBreakdown_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    bullsBreeding\n    cowsBreeding\n    steersBreeding\n    calfsBreeding\n    bullsTrading\n    cowsTrading\n    steersTrading\n    calfsTrading\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    ...BuffaloMonthlyForm_BuffaloMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment BuffaloMonthlyBreakdown_BuffaloMonthLivestockActivity on BuffaloMonthLivestockActivity {\n    ...BuffaloMonthlyForm_BuffaloMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BuffaloActivityLivestockBreedingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsBreeding\n      cowsBreeding\n      steersBreeding\n      calfsBreeding\n    }\n  ',
): (typeof documents)['\n    fragment BuffaloActivityLivestockBreedingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsBreeding\n      cowsBreeding\n      steersBreeding\n      calfsBreeding\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BuffaloActivityLivestockTradingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsTrading\n      cowsTrading\n      steersTrading\n      calfsTrading\n    }\n  ',
): (typeof documents)['\n    fragment BuffaloActivityLivestockTradingBuffaloLivestockActivity on BuffaloLivestockActivity {\n      bullsTrading\n      cowsTrading\n      steersTrading\n      calfsTrading\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment BuffaloActivityLivestockBuffaloLivestockActivity on BuffaloLivestockActivity {\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  ',
): (typeof documents)['\n    fragment BuffaloActivityLivestockBuffaloLivestockActivity on BuffaloLivestockActivity {\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBuffaloActivityFeed($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBuffaloActivityFeed($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n',
): (typeof documents)['\n  fragment BuffaloActivityLivestockFeed_BuffaloLivestockActivityFragment on BuffaloLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity on BuffaloLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity on BuffaloLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBuffaloActivity($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockBuffaloLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetBuffaloActivity($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...BuffaloActivityLivestockBuffaloLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetBuffaloMonthLivestockActivitiesForActivity(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetBuffaloMonthLivestockActivitiesForActivity(\n      $listBuffaloMonthLivestockActivitiesInput: ListBuffaloMonthLivestockActivitiesInput!\n    ) {\n      buffaloMonthLivestockActivities(\n        listBuffaloMonthLivestockActivitiesInput: $listBuffaloMonthLivestockActivitiesInput\n      ) {\n        id\n        ...BuffaloLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBuffaloActivityInHeader($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetBuffaloActivityInHeader($id: ID!) {\n    buffaloLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBuffaloActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateBuffaloLivestockActivity(\n    $id: ID!\n    $updateBuffaloLivestockActivityInput: UpdateBuffaloLivestockActivityInput!\n  ) {\n    updateBuffaloLivestockActivity(\n      id: $id\n      updateBuffaloLivestockActivityInput: $updateBuffaloLivestockActivityInput\n    ) {\n      id\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateBuffaloLivestockActivity(\n    $id: ID!\n    $updateBuffaloLivestockActivityInput: UpdateBuffaloLivestockActivityInput!\n  ) {\n    updateBuffaloLivestockActivity(\n      id: $id\n      updateBuffaloLivestockActivityInput: $updateBuffaloLivestockActivityInput\n    ) {\n      id\n      ...BuffaloActivityLivestockBreedingBuffaloLivestockActivity\n      ...BuffaloActivityLivestockTradingBuffaloLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BuffaloLivestockActivity_MonthActivities on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    buffaloClass\n  }\n',
): (typeof documents)['\n  fragment BuffaloLivestockActivity_MonthActivities on BuffaloMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    buffaloClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createBuffaloLivestockActivity(\n    $propertyId: ID!\n    $createBuffaloLivestockActivityInput: CreateBuffaloLivestockActivityInput!\n  ) {\n    createBuffaloLivestockActivity(\n      propertyId: $propertyId\n      createBuffaloLivestockActivityInput: $createBuffaloLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createBuffaloLivestockActivity(\n    $propertyId: ID!\n    $createBuffaloLivestockActivityInput: CreateBuffaloLivestockActivityInput!\n  ) {\n    createBuffaloLivestockActivity(\n      propertyId: $propertyId\n      createBuffaloLivestockActivityInput: $createBuffaloLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment BusinessActivitiesBurningActivityInfoFragment on BurningActivity {\n    fireScarAreaSquareMetres\n    fuelClass\n    patchiness\n    season\n    vegetationClass\n    yearsSinceLastFire\n    rainfallZone\n  }\n',
): (typeof documents)['\n  fragment BusinessActivitiesBurningActivityInfoFragment on BurningActivity {\n    fireScarAreaSquareMetres\n    fuelClass\n    patchiness\n    season\n    vegetationClass\n    yearsSinceLastFire\n    rainfallZone\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBurningActivity on BurningActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderBurningActivity on BurningActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBurningActivityInfo($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBurningActivityInfo($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateBurning(\n    $id: ID!\n    $updateBurningActivityInput: UpdateBurningActivityInput!\n  ) {\n    updateBurningActivity(\n      id: $id\n      updateBurningActivityInput: $updateBurningActivityInput\n    ) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateBurning(\n    $id: ID!\n    $updateBurningActivityInput: UpdateBurningActivityInput!\n  ) {\n    updateBurningActivity(\n      id: $id\n      updateBurningActivityInput: $updateBurningActivityInput\n    ) {\n      id\n      ...BusinessActivitiesBurningActivityInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createBurningActivity(\n    $propertyId: ID!\n    $createBurningActivityInput: CreateBurningActivityInput!\n  ) {\n    createBurningActivity(\n      propertyId: $propertyId\n      createBurningActivityInput: $createBurningActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createBurningActivity(\n    $propertyId: ID!\n    $createBurningActivityInput: CreateBurningActivityInput!\n  ) {\n    createBurningActivity(\n      propertyId: $propertyId\n      createBurningActivityInput: $createBurningActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBurningActivityInHeader($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBurningActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetBurningActivityInHeader($id: ID!) {\n    burningActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderBurningActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CottonCropInfoPageCottonFields on CottonActivity {\n    id\n    residueBurned\n    percentageBurned\n    averageCottonYieldKgPerHa\n    balesPerHa\n    averageWeightPerBaleKg\n    cottonLintPerBaleKg\n    cottonSeedPerBaleKg\n    wastePerBaleKg\n  }\n',
): (typeof documents)['\n  fragment CottonCropInfoPageCottonFields on CottonActivity {\n    id\n    residueBurned\n    percentageBurned\n    averageCottonYieldKgPerHa\n    balesPerHa\n    averageWeightPerBaleKg\n    cottonLintPerBaleKg\n    cottonSeedPerBaleKg\n    wastePerBaleKg\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CottonCropInfoPageCroppingActivityFragment on CroppingActivity {\n    name\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n',
): (typeof documents)['\n  fragment CottonCropInfoPageCroppingActivityFragment on CroppingActivity {\n    name\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCottonCroppingActivity($id: ID!) {\n    cottonActivity(id: $id) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n',
): (typeof documents)['\n  query GetCottonCroppingActivity($id: ID!) {\n    cottonActivity(id: $id) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCottonCroppingForInfoPage(\n    $id: ID!\n    $updateCottonActivityInput: UpdateCottonActivityInput!\n  ) {\n    updateCottonActivity(\n      id: $id\n      updateCottonActivityInput: $updateCottonActivityInput\n    ) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCottonCroppingForInfoPage(\n    $id: ID!\n    $updateCottonActivityInput: UpdateCottonActivityInput!\n  ) {\n    updateCottonActivity(\n      id: $id\n      updateCottonActivityInput: $updateCottonActivityInput\n    ) {\n      id\n      ...CottonCropInfoPageCottonFields\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CropInfoPageCropVarietyFragment on CropVariety {\n    id\n    species\n    yieldInKg\n  }\n',
): (typeof documents)['\n  fragment CropInfoPageCropVarietyFragment on CropVariety {\n    id\n    species\n    yieldInKg\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CropInfoPageCroppingActivityFragment on CroppingActivity {\n    irrigated\n    residueBurned\n    percentageBurned\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n    varieties {\n      ...CropInfoPageCropVarietyFragment\n    }\n    name\n  }\n',
): (typeof documents)['\n  fragment CropInfoPageCroppingActivityFragment on CroppingActivity {\n    irrigated\n    residueBurned\n    percentageBurned\n    plantingAreaSquareMetres\n    plantingAreaGeometry\n    varieties {\n      ...CropInfoPageCropVarietyFragment\n    }\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCroppingForInfoPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCroppingForInfoPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCropVariety(\n    $croppingActivityId: ID!\n    $createCropVarietyInput: CreateCropVarietyInput!\n  ) {\n    createCropVariety(\n      croppingActivityId: $croppingActivityId\n      createCropVarietyInput: $createCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCropVariety(\n    $croppingActivityId: ID!\n    $createCropVarietyInput: CreateCropVarietyInput!\n  ) {\n    createCropVariety(\n      croppingActivityId: $croppingActivityId\n      createCropVarietyInput: $createCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCropVariety(\n    $id: ID!\n    $updateCropVarietyInput: UpdateCropVarietyInput!\n  ) {\n    updateCropVariety(\n      id: $id\n      updateCropVarietyInput: $updateCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCropVariety(\n    $id: ID!\n    $updateCropVarietyInput: UpdateCropVarietyInput!\n  ) {\n    updateCropVariety(\n      id: $id\n      updateCropVarietyInput: $updateCropVarietyInput\n    ) {\n      id\n      ...CropInfoPageCropVarietyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCroppingActivityInfo($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n      ...CottonCropInfoPageCroppingActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetCroppingActivityInfo($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...CropInfoPageCroppingActivityFragment\n      ...CottonCropInfoPageCroppingActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity on CroppingActivity {\n      __typename\n      id\n      name\n      start\n      end\n      croppingType\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity on CroppingActivity {\n      __typename\n      id\n      name\n      start\n      end\n      croppingType\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCropActivityInHeader($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetCropActivityInHeader($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderCroppingActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment on Fertiliser {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n      ratioN\n      ratioP\n      ratioK\n      ratioS\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment on Fertiliser {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n      ratioN\n      ratioP\n      ratioK\n      ratioS\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateFertiliser(\n    $croppingActivityId: ID!\n    $createFertiliserInput: CreateFertiliserInput!\n  ) {\n    createFertiliser(\n      croppingActivityId: $croppingActivityId\n      createFertiliserInput: $createFertiliserInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFertiliser(\n    $croppingActivityId: ID!\n    $createFertiliserInput: CreateFertiliserInput!\n  ) {\n    createFertiliser(\n      croppingActivityId: $croppingActivityId\n      createFertiliserInput: $createFertiliserInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateFertiliser(\n    $id: ID!\n    $updateFertiliserInput: UpdateFertiliserInput!\n  ) {\n    updateFertiliser(id: $id, updateFertiliserInput: $updateFertiliserInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFertiliser(\n    $id: ID!\n    $updateFertiliserInput: UpdateFertiliserInput!\n  ) {\n    updateFertiliser(id: $id, updateFertiliserInput: $updateFertiliserInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteFertiliser($id: ID!) {\n    deleteFertiliser(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteFertiliser($id: ID!) {\n    deleteFertiliser(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFertiliser($id: ID!) {\n    fertiliser(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFertiliser($id: ID!) {\n    fertiliser(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFertiliserFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCroppingForLimeFertiliserPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCroppingForLimeFertiliserPage(\n    $id: ID!\n    $updateCroppingActivityInput: UpdateCroppingActivityInput!\n  ) {\n    updateCroppingActivity(\n      id: $id\n      updateCroppingActivityInput: $updateCroppingActivityInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCroppingLimeFertiliser($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetCroppingLimeFertiliser($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFragment on CroppingActivity {\n      id\n      plantingAreaSquareMetres\n      lime {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      fertiliser {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      ureaseInhibitorUsed\n      nitrificationInhibitorUsed\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserFragment on CroppingActivity {\n      id\n      plantingAreaSquareMetres\n      lime {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      fertiliser {\n        id\n        type\n        amountPerHectareKg\n        applicationAreaSquareMetres\n      }\n      ureaseInhibitorUsed\n      nitrificationInhibitorUsed\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment on Lime {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment on Lime {\n      type\n      amountPerHectareKg\n      applicationAreaSquareMetres\n      name\n      applicationDate\n      applicationAreaGeometry\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateLime(\n    $croppingActivityId: ID!\n    $createLimeInput: CreateLimeInput!\n  ) {\n    createLime(\n      croppingActivityId: $croppingActivityId\n      createLimeInput: $createLimeInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateLime(\n    $croppingActivityId: ID!\n    $createLimeInput: CreateLimeInput!\n  ) {\n    createLime(\n      croppingActivityId: $croppingActivityId\n      createLimeInput: $createLimeInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLime($id: ID!, $updateLimeInput: UpdateLimeInput!) {\n    updateLime(id: $id, updateLimeInput: $updateLimeInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLime($id: ID!, $updateLimeInput: UpdateLimeInput!) {\n    updateLime(id: $id, updateLimeInput: $updateLimeInput) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteLime($id: ID!) {\n    deleteLime(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteLime($id: ID!) {\n    deleteLime(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetLime($id: ID!) {\n    lime(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetLime($id: ID!) {\n    lime(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropLimeFertiliserLimeFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateCarbamate(\n    $croppingActivityId: ID!\n    $createCarbamateInput: CreateCarbamateInput!\n  ) {\n    createCarbamate(\n      croppingActivityId: $croppingActivityId\n      createCarbamateInput: $createCarbamateInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateCarbamate(\n    $croppingActivityId: ID!\n    $createCarbamateInput: CreateCarbamateInput!\n  ) {\n    createCarbamate(\n      croppingActivityId: $croppingActivityId\n      createCarbamateInput: $createCarbamateInput\n    ) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateCarbamate(\n    $id: ID!\n    $updateCarbamateInput: UpdateCarbamateInput!\n  ) {\n    updateCarbamate(id: $id, updateCarbamateInput: $updateCarbamateInput) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateCarbamate(\n    $id: ID!\n    $updateCarbamateInput: UpdateCarbamateInput!\n  ) {\n    updateCarbamate(id: $id, updateCarbamateInput: $updateCarbamateInput) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteCarbamate($id: ID!) {\n    deleteCarbamate(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteCarbamate($id: ID!) {\n    deleteCarbamate(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCroppingWeedPestDisease($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetCroppingWeedPestDisease($id: ID!) {\n    croppingActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetCarbamate($id: ID!) {\n    carbamate(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetCarbamate($id: ID!) {\n    carbamate(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment on Carbamate {\n      type\n      name\n      amountPerHectareGrams\n      product\n      applicationAreaSquareMetres\n      applicationAreaGeometry\n      activeIngredientPercentage\n      applicationDate\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseFieldsFragment on Carbamate {\n      type\n      name\n      amountPerHectareGrams\n      product\n      applicationAreaSquareMetres\n      applicationAreaGeometry\n      activeIngredientPercentage\n      applicationDate\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment on CroppingActivity {\n      plantingAreaSquareMetres\n      pesticide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      herbicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      fungicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesCropWeedPestDiseaseListsFragment on CroppingActivity {\n      plantingAreaSquareMetres\n      pesticide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      herbicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n      fungicide {\n        id\n        type\n        product\n        amountPerHectareGrams\n        applicationAreaSquareMetres\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createCroppingActivity(\n    $propertyId: ID!\n    $createCroppingActivityInput: CreateCroppingActivityInput!\n  ) {\n    createCroppingActivity(\n      propertyId: $propertyId\n      createCroppingActivityInput: $createCroppingActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createCroppingActivity(\n    $propertyId: ID!\n    $createCroppingActivityInput: CreateCroppingActivityInput!\n  ) {\n    createCroppingActivity(\n      propertyId: $propertyId\n      createCroppingActivityInput: $createCroppingActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseDairyMonthlyFeedBreakdown_ActivityFragment on DairyLivestockActivity {\n    id\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    feed {\n      pasture\n      grazingCrops\n    }\n    businessActivity {\n      id\n      state\n    }\n  }\n',
): (typeof documents)['\n  fragment UseDairyMonthlyFeedBreakdown_ActivityFragment on DairyLivestockActivity {\n    id\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    feed {\n      pasture\n      grazingCrops\n    }\n    businessActivity {\n      id\n      state\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  ',
): (typeof documents)['\n    fragment UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateDairyMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateDairyMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyActivityForMonthlyFeedBreakdown($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...UseDairyMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyActivityForMonthlyFeedBreakdown($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...UseDairyMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDairyMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyFeedBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyFeedFragment on DairyLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyFeedBreakdownSummaryFragment\n  }\n',
): (typeof documents)['\n  fragment DairyFeedFragment on DairyLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyFeedBreakdownSummaryFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityFeed($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityFeed($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyMonthLivestockActivitiesForFeedBreakdown(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyFeedBreakdownSummaryMonthsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyMonthLivestockActivitiesForFeedBreakdown(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyFeedBreakdownSummaryMonthsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyFeedBreakdownSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n',
): (typeof documents)['\n  fragment DairyFeedBreakdownSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyFeedBreakdownSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    crudeProteinPercentage\n    dryMatterDigestibilityPercentage\n    dairyClass\n  }\n',
): (typeof documents)['\n  fragment DairyFeedBreakdownSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    crudeProteinPercentage\n    dryMatterDigestibilityPercentage\n    dairyClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyInfoFragment on DairyLivestockActivity {\n    productionSystem\n    distanceTravelledBetweenFarmsKm\n    truckType\n    emissionsRedMeatPercentage\n  }\n',
): (typeof documents)['\n  fragment DairyInfoFragment on DairyLivestockActivity {\n    productionSystem\n    distanceTravelledBetweenFarmsKm\n    truckType\n    emissionsRedMeatPercentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityInfo($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityInfo($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateDairyLivestockActivityInfo(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDairyLivestockActivityInfo(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyLivestockActivityHeaderFragment on DairyLivestockActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n',
): (typeof documents)['\n  fragment DairyLivestockActivityHeaderFragment on DairyLivestockActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityInHeader($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyLivestockActivityHeaderFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityInHeader($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyLivestockActivityHeaderFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMonthlyForm_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    previousMonthLiveweightKg\n    averageLiveweightKg\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment DairyMonthlyForm_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    previousMonthLiveweightKg\n    averageLiveweightKg\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    ...TransactionForm_DairyMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    year\n    month\n    dairyClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_DairyMonthLivestockActivityFragment on DairyMonthLivestockActivity {\n    year\n    month\n    dairyClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateDairyMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateDairyMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createDairyLivestockTransactionEventForUpdateTransaction(\n      $dairyMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDairyLivestockTransactionEvent(\n        dairyMonthLivestockActivityId: $dairyMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createDairyLivestockTransactionEventForUpdateTransaction(\n      $dairyMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDairyLivestockTransactionEvent(\n        dairyMonthLivestockActivityId: $dairyMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDairyLivestockActivityForMonthlyBreakdown($id: ID!) {\n      dairyLivestockActivity(id: $id) {\n        id\n        ...DairyMonthlyBreakdown_DairyLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDairyLivestockActivityForMonthlyBreakdown($id: ID!) {\n      dairyLivestockActivity(id: $id) {\n        id\n        ...DairyMonthlyBreakdown_DairyLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDairyMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDairyMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMonthlyBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetdairyMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      dairyMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DairyMonthLivestockActivityFragment\n        ...UpdateTransaction_DairyMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetdairyMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      dairyMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DairyMonthLivestockActivityFragment\n        ...UpdateTransaction_DairyMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMonthlyBreakdown_DairyLivestockActivityFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n',
): (typeof documents)['\n  fragment DairyMonthlyBreakdown_DairyLivestockActivityFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMonthlyBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    ...DairyMonthlyForm_DairyMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment DairyMonthlyBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n    ...DairyMonthlyForm_DairyMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyActivityLivestockFragment on DairyLivestockActivity {\n    ...DairyLivestockFragment\n  }\n',
): (typeof documents)['\n  fragment DairyActivityLivestockFragment on DairyLivestockActivity {\n    ...DairyLivestockFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityLivestock($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityLivestock($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateDairyLivestockActivityLivestock(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDairyLivestockActivityLivestock(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyActivityLivestockFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyMonthLivestockActivitiesForActivity(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyLivestockMonthsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyMonthLivestockActivitiesForActivity(\n    $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n  ) {\n    dairyMonthLivestockActivities(\n      listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n    ) {\n      id\n      ...DairyLivestockMonthsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyLivestockFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n',
): (typeof documents)['\n  fragment DairyLivestockFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyLivestockMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    dairyClass\n  }\n',
): (typeof documents)['\n  fragment DairyLivestockMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    dairyClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyManureFragment on DairyLivestockActivity {\n    manureMilkingPasturePercentage\n    manureMilkingLagoonPercentage\n    manureMilkingSumpDispersalPercentage\n    manureMilkingPaddocksPercentage\n    manureMilkingSolidStoragePercentage\n    manureOtherPasturePercentage\n    manureOtherLagoonPercentage\n    manureOtherSumpDispersalPercentage\n    manureOtherPaddocksPercentage\n    manureOtherSolidStoragePercentage\n  }\n',
): (typeof documents)['\n  fragment DairyManureFragment on DairyLivestockActivity {\n    manureMilkingPasturePercentage\n    manureMilkingLagoonPercentage\n    manureMilkingSumpDispersalPercentage\n    manureMilkingPaddocksPercentage\n    manureMilkingSolidStoragePercentage\n    manureOtherPasturePercentage\n    manureOtherLagoonPercentage\n    manureOtherSumpDispersalPercentage\n    manureOtherPaddocksPercentage\n    manureOtherSolidStoragePercentage\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityManure($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyManureFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityManure($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyManureFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateDairyLivestockActivityManure(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyManureFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateDairyLivestockActivityManure(\n    $id: ID!\n    $updateDairyLivestockActivityInput: UpdateDairyLivestockActivityInput!\n  ) {\n    updateDairyLivestockActivity(\n      id: $id\n      updateDairyLivestockActivityInput: $updateDairyLivestockActivityInput\n    ) {\n      id\n      ...DairyManureFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      litresPerHeadPerDay\n    }\n  ',
): (typeof documents)['\n    fragment UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity on DairyMonthLivestockActivity {\n      id\n      month\n      year\n      litresPerHeadPerDay\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDairyMonthLivestockActivitiesForMonthlyMilkBreakdown(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateDairyMonthLivestockActivityForMonthlyMilkBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateDairyMonthLivestockActivityForMonthlyMilkBreakdown(\n      $id: ID!\n      $updateDairyMonthLivestockActivityInput: UpdateDairyMonthLivestockActivityInput!\n    ) {\n      updateDairyMonthLivestockActivity(\n        id: $id\n        updateDairyMonthLivestockActivityInput: $updateDairyMonthLivestockActivityInput\n      ) {\n        id\n        ...UseDairyMonthlyMilkBreakdown_DairyMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMilkProductionListFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyMilkSummaryFragment\n  }\n',
): (typeof documents)['\n  fragment DairyMilkProductionListFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n    ...DairyMilkSummaryFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDairyLivestockActivityMilkProduction($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyMilkProductionListFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDairyLivestockActivityMilkProduction($id: ID!) {\n    dairyLivestockActivity(id: $id) {\n      id\n      ...DairyMilkProductionListFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDairyMonthLivestockActivitiesForMilkProduction(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMilkSummaryMonthsFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDairyMonthLivestockActivitiesForMilkProduction(\n      $listDairyMonthLivestockActivitiesInput: ListDairyMonthLivestockActivitiesInput!\n    ) {\n      dairyMonthLivestockActivities(\n        listDairyMonthLivestockActivitiesInput: $listDairyMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DairyMilkSummaryMonthsFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMilkSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n',
): (typeof documents)['\n  fragment DairyMilkSummaryFragment on DairyLivestockActivity {\n    dairyBullsGt1\n    dairyBullsLt1\n    heifersGt1\n    heifersLt1\n    milkingCows\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DairyMilkSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    litresPerHeadPerDay\n    dairyClass\n  }\n',
): (typeof documents)['\n  fragment DairyMilkSummaryMonthsFragment on DairyMonthLivestockActivity {\n    id\n    month\n    year\n    litresPerHeadPerDay\n    dairyClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateDairyLivestockActivity(\n    $propertyId: ID!\n    $createDairyLivestockActivityInput: CreateDairyLivestockActivityInput!\n  ) {\n    createDairyLivestockActivity(\n      propertyId: $propertyId\n      createDairyLivestockActivityInput: $createDairyLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateDairyLivestockActivity(\n    $propertyId: ID!\n    $createDairyLivestockActivityInput: CreateDairyLivestockActivityInput!\n  ) {\n    createDairyLivestockActivity(\n      propertyId: $propertyId\n      createDairyLivestockActivityInput: $createDairyLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerMonthlyForm_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesFawning\n    seasonalFawningRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerMonthlyForm_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesFawning\n    seasonalFawningRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    ...TransactionForm_DeerMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    year\n    month\n    deerClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_DeerMonthLivestockActivityFragment on DeerMonthLivestockActivity {\n    year\n    month\n    deerClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateDeerMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDeerMonthLivestockActivityInput: UpdateDeerMonthLivestockActivityInput!\n    ) {\n      updateDeerMonthLivestockActivity(\n        id: $id\n        updateDeerMonthLivestockActivityInput: $updateDeerMonthLivestockActivityInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateDeerMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateDeerMonthLivestockActivityInput: UpdateDeerMonthLivestockActivityInput!\n    ) {\n      updateDeerMonthLivestockActivity(\n        id: $id\n        updateDeerMonthLivestockActivityInput: $updateDeerMonthLivestockActivityInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createDeerLivestockTransactionEventForUpdateTransaction(\n      $deerMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDeerLivestockTransactionEvent(\n        deerMonthLivestockActivityId: $deerMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createDeerLivestockTransactionEventForUpdateTransaction(\n      $deerMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createDeerLivestockTransactionEvent(\n        deerMonthLivestockActivityId: $deerMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeerActivityForMonthlyBreakdown($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerMonthlyBreakdown_DeerLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDeerActivityForMonthlyBreakdown($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerMonthlyBreakdown_DeerLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDeerMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDeerMonthLivestockActivitiesForMonthlyBreakdown(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerMonthlyBreakdown_DeerMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDeerMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      deerMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DeerMonthLivestockActivityFragment\n        ...UpdateTransaction_DeerMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDeerMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      deerMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_DeerMonthLivestockActivityFragment\n        ...UpdateTransaction_DeerMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerMonthlyBreakdown_DeerLivestockActivityFragment on DeerLivestockActivity {\n    bucksBreeding\n    breedingDoesBreeding\n    otherDoesBreeding\n    fawnBreeding\n    bucksTrading\n    breedingDoesTrading\n    otherDoesTrading\n    fawnTrading\n  }\n',
): (typeof documents)['\n  fragment DeerMonthlyBreakdown_DeerLivestockActivityFragment on DeerLivestockActivity {\n    bucksBreeding\n    breedingDoesBreeding\n    otherDoesBreeding\n    fawnBreeding\n    bucksTrading\n    breedingDoesTrading\n    otherDoesTrading\n    fawnTrading\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerMonthlyBreakdown_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    ...DeerMonthlyForm_DeerMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment DeerMonthlyBreakdown_DeerMonthLivestockActivity on DeerMonthLivestockActivity {\n    ...DeerMonthlyForm_DeerMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment DeerActivityLivestockBreedingDeerLivestockActivity on DeerLivestockActivity {\n      bucksBreeding\n      breedingDoesBreeding\n      otherDoesBreeding\n      fawnBreeding\n    }\n  ',
): (typeof documents)['\n    fragment DeerActivityLivestockBreedingDeerLivestockActivity on DeerLivestockActivity {\n      bucksBreeding\n      breedingDoesBreeding\n      otherDoesBreeding\n      fawnBreeding\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment DeerActivityLivestockTradingDeerLivestockActivity on DeerLivestockActivity {\n      bucksTrading\n      breedingDoesTrading\n      otherDoesTrading\n      fawnTrading\n    }\n  ',
): (typeof documents)['\n    fragment DeerActivityLivestockTradingDeerLivestockActivity on DeerLivestockActivity {\n      bucksTrading\n      breedingDoesTrading\n      otherDoesTrading\n      fawnTrading\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerActivityLivestockDeerLivestockActivity on DeerLivestockActivity {\n    ...DeerActivityLivestockBreedingDeerLivestockActivity\n    ...DeerActivityLivestockTradingDeerLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment DeerActivityLivestockDeerLivestockActivity on DeerLivestockActivity {\n    ...DeerActivityLivestockBreedingDeerLivestockActivity\n    ...DeerActivityLivestockTradingDeerLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeerActivityFeed($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockFeed_DeerLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetDeerActivityFeed($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockFeed_DeerLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerActivityLivestockFeed_DeerLivestockActivityFragment on DeerLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n',
): (typeof documents)['\n  fragment DeerActivityLivestockFeed_DeerLivestockActivityFragment on DeerLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderDeerActivity on DeerLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderDeerActivity on DeerLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeerActivity($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockDeerLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetDeerActivity($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...DeerActivityLivestockDeerLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetDeerMonthLivestockActivitiesForActivity(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetDeerMonthLivestockActivitiesForActivity(\n      $listDeerMonthLivestockActivitiesInput: ListDeerMonthLivestockActivitiesInput!\n    ) {\n      deerMonthLivestockActivities(\n        listDeerMonthLivestockActivitiesInput: $listDeerMonthLivestockActivitiesInput\n      ) {\n        id\n        ...DeerLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetDeerActivityInHeader($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderDeerActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetDeerActivityInHeader($id: ID!) {\n    deerLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderDeerActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateDeerLivestockActivity(\n    $id: ID!\n    $updateDeerLivestockActivityInput: UpdateDeerLivestockActivityInput!\n  ) {\n    updateDeerLivestockActivity(\n      id: $id\n      updateDeerLivestockActivityInput: $updateDeerLivestockActivityInput\n    ) {\n      id\n      ...DeerActivityLivestockBreedingDeerLivestockActivity\n      ...DeerActivityLivestockTradingDeerLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateDeerLivestockActivity(\n    $id: ID!\n    $updateDeerLivestockActivityInput: UpdateDeerLivestockActivityInput!\n  ) {\n    updateDeerLivestockActivity(\n      id: $id\n      updateDeerLivestockActivityInput: $updateDeerLivestockActivityInput\n    ) {\n      id\n      ...DeerActivityLivestockBreedingDeerLivestockActivity\n      ...DeerActivityLivestockTradingDeerLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment DeerLivestockActivity_MonthActivities on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    deerClass\n  }\n',
): (typeof documents)['\n  fragment DeerLivestockActivity_MonthActivities on DeerMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    deerClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createDeerLivestockActivity(\n    $propertyId: ID!\n    $createDeerLivestockActivityInput: CreateDeerLivestockActivityInput!\n  ) {\n    createDeerLivestockActivity(\n      propertyId: $propertyId\n      createDeerLivestockActivityInput: $createDeerLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createDeerLivestockActivity(\n    $propertyId: ID!\n    $createDeerLivestockActivityInput: CreateDeerLivestockActivityInput!\n  ) {\n    createDeerLivestockActivity(\n      propertyId: $propertyId\n      createDeerLivestockActivityInput: $createDeerLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotGroupFeedBreakdownFragment on FeedlotFeedBreakdown {\n    id\n    dryMatterDigestibility\n    crudeProtein\n    netEnergyConcentrationMjPerKg\n    nitrogenRetention\n  }\n',
): (typeof documents)['\n  fragment FeedlotGroupFeedBreakdownFragment on FeedlotFeedBreakdown {\n    id\n    dryMatterDigestibility\n    crudeProtein\n    netEnergyConcentrationMjPerKg\n    nitrogenRetention\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotGroupFeedFragment on FeedlotGroup {\n    id\n    feedBreakdown {\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment FeedlotGroupFeedFragment on FeedlotGroup {\n    id\n    feedBreakdown {\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotGroupFeedBreakdown($groupId: ID!) {\n    feedlotGroup(id: $groupId) {\n      id\n      ...FeedlotGroupFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotGroupFeedBreakdown($groupId: ID!) {\n    feedlotGroup(id: $groupId) {\n      id\n      ...FeedlotGroupFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateFeedBreakdown(\n    $feedlotGroupId: ID!\n    $createFeedlotFeedBreakdownInput: CreateFeedlotFeedBreakdownInput!\n  ) {\n    createFeedlotFeedBreakdown(\n      feedlotGroupId: $feedlotGroupId\n      createFeedlotFeedBreakdownInput: $createFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFeedBreakdown(\n    $feedlotGroupId: ID!\n    $createFeedlotFeedBreakdownInput: CreateFeedlotFeedBreakdownInput!\n  ) {\n    createFeedlotFeedBreakdown(\n      feedlotGroupId: $feedlotGroupId\n      createFeedlotFeedBreakdownInput: $createFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateFeedBreakdown(\n    $id: ID!\n    $updateFeedlotFeedBreakdownInput: UpdateFeedlotFeedBreakdownInput!\n  ) {\n    updateFeedlotFeedBreakdown(\n      id: $id\n      updateFeedlotFeedBreakdownInput: $updateFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFeedBreakdown(\n    $id: ID!\n    $updateFeedlotFeedBreakdownInput: UpdateFeedlotFeedBreakdownInput!\n  ) {\n    updateFeedlotFeedBreakdown(\n      id: $id\n      updateFeedlotFeedBreakdownInput: $updateFeedlotFeedBreakdownInput\n    ) {\n      id\n      ...FeedlotGroupFeedBreakdownFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateFeedComposition(\n    $feedlotActivityId: ID!\n    $createFeedlotFeedCompositionInput: CreateFeedlotFeedCompositionInput!\n  ) {\n    createFeedlotFeedComposition(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotFeedCompositionInput: $createFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateFeedComposition(\n    $feedlotActivityId: ID!\n    $createFeedlotFeedCompositionInput: CreateFeedlotFeedCompositionInput!\n  ) {\n    createFeedlotFeedComposition(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotFeedCompositionInput: $createFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateFeedComposition(\n    $id: ID!\n    $updateFeedlotFeedCompositionInput: UpdateFeedlotFeedCompositionInput!\n  ) {\n    updateFeedlotFeedComposition(\n      id: $id\n      updateFeedlotFeedCompositionInput: $updateFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFeedComposition(\n    $id: ID!\n    $updateFeedlotFeedCompositionInput: UpdateFeedlotFeedCompositionInput!\n  ) {\n    updateFeedlotFeedComposition(\n      id: $id\n      updateFeedlotFeedCompositionInput: $updateFeedlotFeedCompositionInput\n    ) {\n      id\n      ...FeedlotFeedCompositionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotFeedCompositionFragment on FeedlotFeedComposition {\n    id\n    grainsTotalProportion\n    grainsTotalCellulous\n    grainsTotalHemicellulous\n    grainsTotalSolubleResidue\n    grainsTotalNitrogen\n    otherConcentratesProportion\n    otherConcentratesCellulous\n    otherConcentratesHemicellulous\n    otherConcentratesSolubleResidue\n    otherConcentratesNitrogen\n    grassesProportion\n    grassesCellulous\n    grassesHemicellulous\n    grassesSolubleResidue\n    grassesNitrogen\n    legumesProportion\n    legumesCellulous\n    legumesHemicellulous\n    legumesSolubleResidue\n    legumesNitrogen\n  }\n',
): (typeof documents)['\n  fragment FeedlotFeedCompositionFragment on FeedlotFeedComposition {\n    id\n    grainsTotalProportion\n    grainsTotalCellulous\n    grainsTotalHemicellulous\n    grainsTotalSolubleResidue\n    grainsTotalNitrogen\n    otherConcentratesProportion\n    otherConcentratesCellulous\n    otherConcentratesHemicellulous\n    otherConcentratesSolubleResidue\n    otherConcentratesNitrogen\n    grassesProportion\n    grassesCellulous\n    grassesHemicellulous\n    grassesSolubleResidue\n    grassesNitrogen\n    legumesProportion\n    legumesCellulous\n    legumesHemicellulous\n    legumesSolubleResidue\n    legumesNitrogen\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotProductionFeedFragment on FeedlotActivity {\n    id\n    pasture\n    grazingCrops\n    feedComposition {\n      ...FeedlotFeedCompositionFragment\n    }\n    groups {\n      id\n      name\n      feedBreakdown {\n        id\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment FeedlotProductionFeedFragment on FeedlotActivity {\n    id\n    pasture\n    grazingCrops\n    feedComposition {\n      ...FeedlotFeedCompositionFragment\n    }\n    groups {\n      id\n      name\n      feedBreakdown {\n        id\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity on FeedlotActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity on FeedlotActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotActivitySystem($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionSystemFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotActivitySystem($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionSystemFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotActivityTransport($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionTransportFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotActivityTransport($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionTransportFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotActivityLivestock($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionLivestockFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotActivityLivestock($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionLivestockFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateFeedlot(\n    $id: ID!\n    $updateFeedlotActivityInput: UpdateFeedlotActivityInput!\n  ) {\n    updateFeedlotActivity(\n      id: $id\n      updateFeedlotActivityInput: $updateFeedlotActivityInput\n    ) {\n      id\n      ...FeedlotProductionSystemFragment\n      ...FeedlotProductionFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateFeedlot(\n    $id: ID!\n    $updateFeedlotActivityInput: UpdateFeedlotActivityInput!\n  ) {\n    updateFeedlotActivity(\n      id: $id\n      updateFeedlotActivityInput: $updateFeedlotActivityInput\n    ) {\n      id\n      ...FeedlotProductionSystemFragment\n      ...FeedlotProductionFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotActivityFeed($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotActivityFeed($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...FeedlotProductionFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotLivestockGroupFragment on FeedlotGroup {\n    id\n    name\n    numberOfLivestock\n    lengthOfStayDays\n    averageLiveweightKg\n  }\n',
): (typeof documents)['\n  fragment FeedlotLivestockGroupFragment on FeedlotGroup {\n    id\n    name\n    numberOfLivestock\n    lengthOfStayDays\n    averageLiveweightKg\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotProductionLivestockFragment on FeedlotActivity {\n    id\n    groups {\n      id\n      name\n    }\n    purchases {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n    sales {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n  }\n',
): (typeof documents)['\n  fragment FeedlotProductionLivestockFragment on FeedlotActivity {\n    id\n    groups {\n      id\n      name\n    }\n    purchases {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n    sales {\n      id\n      livestockCategory\n      head\n      beefClass\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query FeedlotGroup($id: ID!) {\n    feedlotGroup(id: $id) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n',
): (typeof documents)['\n  query FeedlotGroup($id: ID!) {\n    feedlotGroup(id: $id) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateLivestockGroup(\n    $feedlotActivityId: ID!\n    $createFeedlotGroupInput: CreateFeedlotGroupInput!\n  ) {\n    createFeedlotGroup(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotGroupInput: $createFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateLivestockGroup(\n    $feedlotActivityId: ID!\n    $createFeedlotGroupInput: CreateFeedlotGroupInput!\n  ) {\n    createFeedlotGroup(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotGroupInput: $createFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLivestockGroup(\n    $id: ID!\n    $updateFeedlotGroupInput: UpdateFeedlotGroupInput!\n  ) {\n    updateFeedlotGroup(\n      id: $id\n      updateFeedlotGroupInput: $updateFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLivestockGroup(\n    $id: ID!\n    $updateFeedlotGroupInput: UpdateFeedlotGroupInput!\n  ) {\n    updateFeedlotGroup(\n      id: $id\n      updateFeedlotGroupInput: $updateFeedlotGroupInput\n    ) {\n      id\n      ...FeedlotLivestockGroupFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteLivestockGroup($id: ID!) {\n    deleteFeedlotGroup(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteLivestockGroup($id: ID!) {\n    deleteFeedlotGroup(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotLivestockPurchaseFragment on FeedlotPurchase {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n    source\n  }\n',
): (typeof documents)['\n  fragment FeedlotLivestockPurchaseFragment on FeedlotPurchase {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n    source\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query FeedlotPurchase($id: ID!) {\n    feedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n',
): (typeof documents)['\n  query FeedlotPurchase($id: ID!) {\n    feedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateLivestockPurchase(\n    $feedlotActivityId: ID!\n    $createFeedlotPurchaseInput: CreateFeedlotPurchaseInput!\n  ) {\n    createFeedlotPurchase(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotPurchaseInput: $createFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateLivestockPurchase(\n    $feedlotActivityId: ID!\n    $createFeedlotPurchaseInput: CreateFeedlotPurchaseInput!\n  ) {\n    createFeedlotPurchase(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotPurchaseInput: $createFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLivestockPurchase(\n    $id: ID!\n    $updateFeedlotPurchaseInput: UpdateFeedlotPurchaseInput!\n  ) {\n    updateFeedlotPurchase(\n      id: $id\n      updateFeedlotPurchaseInput: $updateFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLivestockPurchase(\n    $id: ID!\n    $updateFeedlotPurchaseInput: UpdateFeedlotPurchaseInput!\n  ) {\n    updateFeedlotPurchase(\n      id: $id\n      updateFeedlotPurchaseInput: $updateFeedlotPurchaseInput\n    ) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteLivestockPurchase($id: ID!) {\n    deleteFeedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteLivestockPurchase($id: ID!) {\n    deleteFeedlotPurchase(id: $id) {\n      id\n      ...FeedlotLivestockPurchaseFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotLivestockSaleFragment on FeedlotSale {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n  }\n',
): (typeof documents)['\n  fragment FeedlotLivestockSaleFragment on FeedlotSale {\n    id\n    head\n    month\n    year\n    weightPerHeadKg\n    beefClass\n    livestockCategory\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query FeedlotSale($id: ID!) {\n    feedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  query FeedlotSale($id: ID!) {\n    feedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateLivestockSale(\n    $feedlotActivityId: ID!\n    $createFeedlotSaleInput: CreateFeedlotSaleInput!\n  ) {\n    createFeedlotSale(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotSaleInput: $createFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateLivestockSale(\n    $feedlotActivityId: ID!\n    $createFeedlotSaleInput: CreateFeedlotSaleInput!\n  ) {\n    createFeedlotSale(\n      feedlotActivityId: $feedlotActivityId\n      createFeedlotSaleInput: $createFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLivestockSale(\n    $id: ID!\n    $updateFeedlotSaleInput: UpdateFeedlotSaleInput!\n  ) {\n    updateFeedlotSale(\n      id: $id\n      updateFeedlotSaleInput: $updateFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLivestockSale(\n    $id: ID!\n    $updateFeedlotSaleInput: UpdateFeedlotSaleInput!\n  ) {\n    updateFeedlotSale(\n      id: $id\n      updateFeedlotSaleInput: $updateFeedlotSaleInput\n    ) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteLivestockSale($id: ID!) {\n    deleteFeedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteLivestockSale($id: ID!) {\n    deleteFeedlotSale(id: $id) {\n      id\n      ...FeedlotLivestockSaleFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotProductionSystemFragment on FeedlotActivity {\n    id\n    system\n  }\n',
): (typeof documents)['\n  fragment FeedlotProductionSystemFragment on FeedlotActivity {\n    id\n    system\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment FeedlotProductionTransportFragment on FeedlotActivity {\n    id\n    totalDistanceTravelledKm\n    truckType\n  }\n',
): (typeof documents)['\n  fragment FeedlotProductionTransportFragment on FeedlotActivity {\n    id\n    totalDistanceTravelledKm\n    truckType\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createFeedlotActivity(\n    $propertyId: ID!\n    $createFeedlotActivityInput: CreateFeedlotActivityInput!\n  ) {\n    createFeedlotActivity(\n      propertyId: $propertyId\n      createFeedlotActivityInput: $createFeedlotActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createFeedlotActivity(\n    $propertyId: ID!\n    $createFeedlotActivityInput: CreateFeedlotActivityInput!\n  ) {\n    createFeedlotActivity(\n      propertyId: $propertyId\n      createFeedlotActivityInput: $createFeedlotActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFeedlotActivityInHeader($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetFeedlotActivityInHeader($id: ID!) {\n    feedlotActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderFeedlotActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatMonthlyForm_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesKidding\n    seasonalKiddingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatMonthlyForm_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    percentageDoesKidding\n    seasonalKiddingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    ...TransactionForm_GoatMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    year\n    month\n    goatClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_GoatMonthLivestockActivityFragment on GoatMonthLivestockActivity {\n    year\n    month\n    goatClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateGoatMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateGoatMonthLivestockActivityInput: UpdateGoatMonthLivestockActivityInput!\n    ) {\n      updateGoatMonthLivestockActivity(\n        id: $id\n        updateGoatMonthLivestockActivityInput: $updateGoatMonthLivestockActivityInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateGoatMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateGoatMonthLivestockActivityInput: UpdateGoatMonthLivestockActivityInput!\n    ) {\n      updateGoatMonthLivestockActivity(\n        id: $id\n        updateGoatMonthLivestockActivityInput: $updateGoatMonthLivestockActivityInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createGoatLivestockTransactionEventForUpdateTransaction(\n      $goatMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createGoatLivestockTransactionEvent(\n        goatMonthLivestockActivityId: $goatMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createGoatLivestockTransactionEventForUpdateTransaction(\n      $goatMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createGoatLivestockTransactionEvent(\n        goatMonthLivestockActivityId: $goatMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatActivityForMonthlyBreakdown($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatMonthlyBreakdown_GoatLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatActivityForMonthlyBreakdown($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatMonthlyBreakdown_GoatLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetGoatMonthLivestockActivitiesForMonthlyBreakdown(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetGoatMonthLivestockActivitiesForMonthlyBreakdown(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatMonthlyBreakdown_GoatMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetGoatMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      goatMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_GoatMonthLivestockActivityFragment\n        ...UpdateTransaction_GoatMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetGoatMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      goatMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_GoatMonthLivestockActivityFragment\n        ...UpdateTransaction_GoatMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatMonthlyBreakdown_GoatLivestockActivityFragment on GoatLivestockActivity {\n    bucksBreeding\n    wethersBreeding\n    breedingDoesBreeding\n    maidenBreedingDoesBreeding\n    otherDoesBreeding\n    kidsBreeding\n    bucksTrading\n    wethersTrading\n    breedingDoesTrading\n    maidenBreedingDoesTrading\n    otherDoesTrading\n    kidsTrading\n  }\n',
): (typeof documents)['\n  fragment GoatMonthlyBreakdown_GoatLivestockActivityFragment on GoatLivestockActivity {\n    bucksBreeding\n    wethersBreeding\n    breedingDoesBreeding\n    maidenBreedingDoesBreeding\n    otherDoesBreeding\n    kidsBreeding\n    bucksTrading\n    wethersTrading\n    breedingDoesTrading\n    maidenBreedingDoesTrading\n    otherDoesTrading\n    kidsTrading\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatMonthlyBreakdown_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    ...GoatMonthlyForm_GoatMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment GoatMonthlyBreakdown_GoatMonthLivestockActivity on GoatMonthLivestockActivity {\n    ...GoatMonthlyForm_GoatMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment GoatActivityLivestockBreedingGoatLivestockActivity on GoatLivestockActivity {\n      bucksBreeding\n      wethersBreeding\n      breedingDoesBreeding\n      maidenBreedingDoesBreeding\n      otherDoesBreeding\n      kidsBreeding\n    }\n  ',
): (typeof documents)['\n    fragment GoatActivityLivestockBreedingGoatLivestockActivity on GoatLivestockActivity {\n      bucksBreeding\n      wethersBreeding\n      breedingDoesBreeding\n      maidenBreedingDoesBreeding\n      otherDoesBreeding\n      kidsBreeding\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment GoatActivityLivestockTradingGoatLivestockActivity on GoatLivestockActivity {\n      bucksTrading\n      wethersTrading\n      breedingDoesTrading\n      maidenBreedingDoesTrading\n      otherDoesTrading\n      kidsTrading\n    }\n  ',
): (typeof documents)['\n    fragment GoatActivityLivestockTradingGoatLivestockActivity on GoatLivestockActivity {\n      bucksTrading\n      wethersTrading\n      breedingDoesTrading\n      maidenBreedingDoesTrading\n      otherDoesTrading\n      kidsTrading\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatActivityLivestockGoatLivestockActivity on GoatLivestockActivity {\n    ...GoatActivityLivestockBreedingGoatLivestockActivity\n    ...GoatActivityLivestockTradingGoatLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment GoatActivityLivestockGoatLivestockActivity on GoatLivestockActivity {\n    ...GoatActivityLivestockBreedingGoatLivestockActivity\n    ...GoatActivityLivestockTradingGoatLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatLivestockActivityFeedDrySeasonMix($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedDrySeasonMix_GoatLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatLivestockActivityFeedDrySeasonMix($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedDrySeasonMix_GoatLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatFeedDrySeasonMix_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatFeedDrySeasonMix_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatActivityFeed($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockFeed_GoatLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatActivityFeed($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockFeed_GoatLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatActivityLivestockFeed_GoatLivestockActivityFragment on GoatLivestockActivity {\n    id\n    __typename\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatActivityLivestockFeed_GoatLivestockActivityFragment on GoatLivestockActivity {\n    id\n    __typename\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatLivestockActivityFeedMineralBlock($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedMineralBlock_GoatLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatLivestockActivityFeedMineralBlock($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedMineralBlock_GoatLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatFeedMineralBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatFeedMineralBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateGoatFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateGoatFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatLivestockActivityFeedWeaner($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedWeanerBlock_GoatLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatLivestockActivityFeedWeaner($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatFeedWeanerBlock_GoatLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatFeedWeanerBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment GoatFeedWeanerBlock_GoatLivestockActivityFragment on GoatLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGoatActivity on GoatLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGoatActivity on GoatLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatActivity($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockGoatLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatActivity($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatActivityLivestockGoatLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetGoatMonthLivestockActivitiesForActivity(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetGoatMonthLivestockActivitiesForActivity(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatActivityInHeader($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGoatActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatActivityInHeader($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGoatActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateGoatLivestockActivity(\n    $id: ID!\n    $updateGoatLivestockActivityInput: UpdateGoatLivestockActivityInput!\n  ) {\n    updateGoatLivestockActivity(\n      id: $id\n      updateGoatLivestockActivityInput: $updateGoatLivestockActivityInput\n    ) {\n      id\n      ...GoatActivityLivestockBreedingGoatLivestockActivity\n      ...GoatActivityLivestockTradingGoatLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateGoatLivestockActivity(\n    $id: ID!\n    $updateGoatLivestockActivityInput: UpdateGoatLivestockActivityInput!\n  ) {\n    updateGoatLivestockActivity(\n      id: $id\n      updateGoatLivestockActivityInput: $updateGoatLivestockActivityInput\n    ) {\n      id\n      ...GoatActivityLivestockBreedingGoatLivestockActivity\n      ...GoatActivityLivestockTradingGoatLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetGoatMonthLivestockActivitiesForShearing(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatShearingEventMonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetGoatMonthLivestockActivitiesForShearing(\n      $listGoatMonthLivestockActivitiesInput: ListGoatMonthLivestockActivitiesInput!\n    ) {\n      goatMonthLivestockActivities(\n        listGoatMonthLivestockActivitiesInput: $listGoatMonthLivestockActivitiesInput\n      ) {\n        id\n        ...GoatShearingEventMonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatWoolShearingEventsFragment on GoatShearingEvent {\n    id\n    month\n    year\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n',
): (typeof documents)['\n  fragment GoatWoolShearingEventsFragment on GoatShearingEvent {\n    id\n    month\n    year\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatShearingEvents($activityId: ID!) {\n    shearingEventsByGoatLivestockActivityId(\n      goatLivestockActivityId: $activityId\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatShearingEvents($activityId: ID!) {\n    shearingEventsByGoatLivestockActivityId(\n      goatLivestockActivityId: $activityId\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatShearingEvent($id: ID!) {\n    goatShearingEvent(id: $id) {\n      id\n      ...WoolUpdateGoatShearingEventFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatShearingEvent($id: ID!) {\n    goatShearingEvent(id: $id) {\n      id\n      ...WoolUpdateGoatShearingEventFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateGoatShearingEvent(\n    $goatLivestockActivityId: ID!\n    $input: CreateGoatShearingEventInput!\n  ) {\n    createGoatShearingEvent(\n      goatLivestockActivityId: $goatLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateGoatShearingEvent(\n    $goatLivestockActivityId: ID!\n    $input: CreateGoatShearingEventInput!\n  ) {\n    createGoatShearingEvent(\n      goatLivestockActivityId: $goatLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateGoatShearingEvent(\n    $id: ID!\n    $input: UpdateGoatShearingEventInput!\n  ) {\n    updateGoatShearingEvent(id: $id, input: $input) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateGoatShearingEvent(\n    $id: ID!\n    $input: UpdateGoatShearingEventInput!\n  ) {\n    updateGoatShearingEvent(id: $id, input: $input) {\n      id\n      ...GoatWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteGoatShearingEvent($id: ID!) {\n    deleteGoatShearingEvent(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteGoatShearingEvent($id: ID!) {\n    deleteGoatShearingEvent(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGoatActivityForShearing($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatShearingEventGoatLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetGoatActivityForShearing($id: ID!) {\n    goatLivestockActivity(id: $id) {\n      id\n      ...GoatShearingEventGoatLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WoolUpdateGoatShearingEventFragment on GoatShearingEvent {\n    id\n    date\n    goatClass\n    livestockCategory\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n',
): (typeof documents)['\n  fragment WoolUpdateGoatShearingEventFragment on GoatShearingEvent {\n    id\n    date\n    goatClass\n    livestockCategory\n    numberGoatShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    goatLivestockActivityId\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatShearingEventGoatLivestockActivity on GoatLivestockActivity {\n    __typename\n    start\n    end\n  }\n',
): (typeof documents)['\n  fragment GoatShearingEventGoatLivestockActivity on GoatLivestockActivity {\n    __typename\n    start\n    end\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatShearingEventMonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n    livestockCategory\n  }\n',
): (typeof documents)['\n  fragment GoatShearingEventMonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n    livestockCategory\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment GoatLivestockActivity_MonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n  }\n',
): (typeof documents)['\n  fragment GoatLivestockActivity_MonthActivities on GoatMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    goatClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createGoatLivestockActivity(\n    $propertyId: ID!\n    $createGoatLivestockActivityInput: CreateGoatLivestockActivityInput!\n  ) {\n    createGoatLivestockActivity(\n      propertyId: $propertyId\n      createGoatLivestockActivityInput: $createGoatLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createGoatLivestockActivity(\n    $propertyId: ID!\n    $createGoatLivestockActivityInput: CreateGoatLivestockActivityInput!\n  ) {\n    createGoatLivestockActivity(\n      propertyId: $propertyId\n      createGoatLivestockActivityInput: $createGoatLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetActivities($propertyId: ID!) {\n    activities(propertyId: $propertyId) {\n      id\n      ...ActivitiesIndexActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetActivities($propertyId: ID!) {\n    activities(propertyId: $propertyId) {\n      id\n      ...ActivitiesIndexActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ActivitiesIndexActivity on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n',
): (typeof documents)['\n  fragment ActivitiesIndexActivity on Activity {\n    __typename\n    id\n    name\n    start\n    end\n    ... on CroppingActivity {\n      croppingType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateActivityFeed(\n    $activityId: ID!\n    $createFeedInput: CreateFeedInput!\n  ) {\n    createFeed(activityId: $activityId, createFeedInput: $createFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateActivityFeed(\n    $activityId: ID!\n    $createFeedInput: CreateFeedInput!\n  ) {\n    createFeed(activityId: $activityId, createFeedInput: $createFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateActivityFeed($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateActivityFeed($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...ActivityLivestockFeed_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment ActivityLivestockFeed_FeedFragment on Feed {\n    id\n    pasture\n    grazingCrops\n    mineralBlock\n    weanerBlock\n    drySeasonMix\n  }\n',
): (typeof documents)['\n  fragment ActivityLivestockFeed_FeedFragment on Feed {\n    id\n    pasture\n    grazingCrops\n    mineralBlock\n    weanerBlock\n    drySeasonMix\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    transactionType\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    transactionRegion\n    headPurchasedMerino\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_LivestockTransactionEventFragment on LivestockTransactionEvent {\n    transactionType\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    transactionRegion\n    headPurchasedMerino\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetLivestockTransactionEventForUpdateTransaction($id: ID!) {\n      livestockTransactionEvent(id: $id) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetLivestockTransactionEventForUpdateTransaction($id: ID!) {\n      livestockTransactionEvent(id: $id) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteLivestockTransactionEvent($id: ID!) {\n    deleteLivestockTransactionEvent(id: $id) {\n      id\n      ...UpdateTransaction_LivestockTransactionEventFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteLivestockTransactionEvent($id: ID!) {\n    deleteLivestockTransactionEvent(id: $id) {\n      id\n      ...UpdateTransaction_LivestockTransactionEventFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkFeedBlendEditFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    barley\n    beetPulp\n    blendType\n    bloodMeal\n    canolaMeal\n    emissionsIntensityFeedProduction\n    fishMeal\n    meatMeal\n    millMix\n    percentageAdditionalIngredients\n    soybeanMeal\n    tallow\n    wheat\n    wheatBran\n    wheyPowder\n  }\n',
): (typeof documents)['\n  fragment PorkFeedBlendEditFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    barley\n    beetPulp\n    blendType\n    bloodMeal\n    canolaMeal\n    emissionsIntensityFeedProduction\n    fishMeal\n    meatMeal\n    millMix\n    percentageAdditionalIngredients\n    soybeanMeal\n    tallow\n    wheat\n    wheatBran\n    wheyPowder\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkFeedBlend($id: ID!) {\n    porkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkFeedBlend($id: ID!) {\n    porkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePorkFeedBlend($id: ID!) {\n    deletePorkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePorkFeedBlend($id: ID!) {\n    deletePorkFeedBlend(id: $id) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePorkFeedBlend(\n    $id: ID!\n    $updatePorkFeedBlendInput: UpdatePorkFeedBlendInput!\n  ) {\n    updatePorkFeedBlend(\n      id: $id\n      updatePorkFeedBlendInput: $updatePorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePorkFeedBlend(\n    $id: ID!\n    $updatePorkFeedBlendInput: UpdatePorkFeedBlendInput!\n  ) {\n    updatePorkFeedBlend(\n      id: $id\n      updatePorkFeedBlendInput: $updatePorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePorkFeedBlend(\n    $porkLivestockActivityId: ID!\n    $createPorkFeedBlendInput: CreatePorkFeedBlendInput!\n  ) {\n    createPorkFeedBlend(\n      porkLivestockActivityId: $porkLivestockActivityId\n      createPorkFeedBlendInput: $createPorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePorkFeedBlend(\n    $porkLivestockActivityId: ID!\n    $createPorkFeedBlendInput: CreatePorkFeedBlendInput!\n  ) {\n    createPorkFeedBlend(\n      porkLivestockActivityId: $porkLivestockActivityId\n      createPorkFeedBlendInput: $createPorkFeedBlendInput\n    ) {\n      id\n      ...PorkFeedBlendEditFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkActivityFeedBlends($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkLivestockActivityFeedBlendsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkActivityFeedBlends($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkLivestockActivityFeedBlendsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkFeedBlendsFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    blendType\n  }\n',
): (typeof documents)['\n  fragment PorkFeedBlendsFragment on PorkFeedBlend {\n    id\n    amountPurchasedTonnes\n    blendType\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkLivestockActivityFeedBlendsFragment on PorkLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    feedBlends {\n      ...PorkFeedBlendsFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkLivestockActivityFeedBlendsFragment on PorkLivestockActivity {\n    id\n    feed {\n      id\n      pasture\n      grazingCrops\n    }\n    feedBlends {\n      ...PorkFeedBlendsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPorkActivity on PorkLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPorkActivity on PorkLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkActivity($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkActivityLivestockPorkLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkActivity($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkActivityLivestockPorkLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetPorkMonthLivestockActivitiesForActivity(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetPorkMonthLivestockActivitiesForActivity(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkActivityInHeader($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPorkActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkActivityInHeader($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPorkActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updatePorkLivestockActivity(\n    $id: ID!\n    $updatePorkLivestockActivityInput: UpdatePorkLivestockActivityInput!\n  ) {\n    updatePorkLivestockActivity(\n      id: $id\n      updatePorkLivestockActivityInput: $updatePorkLivestockActivityInput\n    ) {\n      id\n      ...PorkActivityLivestockClassesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation updatePorkLivestockActivity(\n    $id: ID!\n    $updatePorkLivestockActivityInput: UpdatePorkLivestockActivityInput!\n  ) {\n    updatePorkLivestockActivity(\n      id: $id\n      updatePorkLivestockActivityInput: $updatePorkLivestockActivityInput\n    ) {\n      id\n      ...PorkActivityLivestockClassesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    ...TransactionForm_PorkMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkMonthlyForm_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment PorkMonthlyForm_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    nonSalePopulationChange\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    year\n    month\n    porkClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_PorkMonthLivestockActivityFragment on PorkMonthLivestockActivity {\n    year\n    month\n    porkClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updatePorkMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePorkMonthLivestockActivityInput: UpdatePorkMonthLivestockActivityInput!\n    ) {\n      updatePorkMonthLivestockActivity(\n        id: $id\n        updatePorkMonthLivestockActivityInput: $updatePorkMonthLivestockActivityInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updatePorkMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePorkMonthLivestockActivityInput: UpdatePorkMonthLivestockActivityInput!\n    ) {\n      updatePorkMonthLivestockActivity(\n        id: $id\n        updatePorkMonthLivestockActivityInput: $updatePorkMonthLivestockActivityInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createPorkLivestockTransactionEventForUpdateTransaction(\n      $porkMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createPorkLivestockTransactionEvent(\n        porkMonthLivestockActivityId: $porkMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createPorkLivestockTransactionEventForUpdateTransaction(\n      $porkMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createPorkLivestockTransactionEvent(\n        porkMonthLivestockActivityId: $porkMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPorkActivityForMonthlyBreakdown($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkMonthlyBreakdown_PorkLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPorkActivityForMonthlyBreakdown($id: ID!) {\n    porkLivestockActivity(id: $id) {\n      id\n      ...PorkMonthlyBreakdown_PorkLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetPorkMonthLivestockActivitiesForMonthlyBreakdown(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetPorkMonthLivestockActivitiesForMonthlyBreakdown(\n      $listPorkMonthLivestockActivitiesInput: ListPorkMonthLivestockActivitiesInput!\n    ) {\n      porkMonthLivestockActivities(\n        listPorkMonthLivestockActivitiesInput: $listPorkMonthLivestockActivitiesInput\n      ) {\n        id\n        ...PorkMonthlyBreakdown_PorkMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetPorkMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      porkMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_PorkMonthLivestockActivityFragment\n        ...UpdateTransaction_PorkMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetPorkMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      porkMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_PorkMonthLivestockActivityFragment\n        ...UpdateTransaction_PorkMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkMonthlyBreakdown_PorkLivestockActivityFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n',
): (typeof documents)['\n  fragment PorkMonthlyBreakdown_PorkLivestockActivityFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkMonthlyBreakdown_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    ...PorkMonthlyForm_PorkMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment PorkMonthlyBreakdown_PorkMonthLivestockActivity on PorkMonthLivestockActivity {\n    ...PorkMonthlyForm_PorkMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkActivityLivestockClassesFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n',
): (typeof documents)['\n  fragment PorkActivityLivestockClassesFragment on PorkLivestockActivity {\n    sows\n    boars\n    gilts\n    weaners\n    growers\n    suckers\n    slaughterPigs\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkActivityLivestockPorkLivestockActivity on PorkLivestockActivity {\n    ...PorkActivityLivestockClassesFragment\n  }\n',
): (typeof documents)['\n  fragment PorkActivityLivestockPorkLivestockActivity on PorkLivestockActivity {\n    ...PorkActivityLivestockClassesFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PorkLivestockActivity_MonthActivities on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    porkClass\n  }\n',
): (typeof documents)['\n  fragment PorkLivestockActivity_MonthActivities on PorkMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    porkClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createPorkLivestockActivity(\n    $propertyId: ID!\n    $createPorkLivestockActivityInput: CreatePorkLivestockActivityInput!\n  ) {\n    createPorkLivestockActivity(\n      propertyId: $propertyId\n      createPorkLivestockActivityInput: $createPorkLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createPorkLivestockActivity(\n    $propertyId: ID!\n    $createPorkLivestockActivityInput: CreatePorkLivestockActivityInput!\n  ) {\n    createPorkLivestockActivity(\n      propertyId: $propertyId\n      createPorkLivestockActivityInput: $createPorkLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersBatchFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersBatchFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersBatchFeedBreakdownFragment on PoultryFeedBreakdown {\n    dryMatterIntakeKgPerHeadPerDay\n    dryMatterDigestibilityPercent\n    crudeProteinPercent\n    manureAshPercent\n    nitrogenRetentionPercent\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersBatchFeedBreakdownFragment on PoultryFeedBreakdown {\n    dryMatterIntakeKgPerHeadPerDay\n    dryMatterDigestibilityPercent\n    crudeProteinPercent\n    manureAshPercent\n    nitrogenRetentionPercent\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryGrowerBatchFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n',
): (typeof documents)['\n  fragment PoultryGrowerBatchFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryGrowerBatchFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryGrowerBatchFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateGrowerBatchFeedBlend(\n    $batchId: ID!\n    $createPoultryGrowerBatchFeedBlendInput: CreatePoultryGrowerBatchFeedBlendInput!\n  ) {\n    createPoultryGrowerBatchFeedBlend(\n      batchId: $batchId\n      createPoultryGrowerBatchFeedBlendInput: $createPoultryGrowerBatchFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateGrowerBatchFeedBlend(\n    $batchId: ID!\n    $createPoultryGrowerBatchFeedBlendInput: CreatePoultryGrowerBatchFeedBlendInput!\n  ) {\n    createPoultryGrowerBatchFeedBlend(\n      batchId: $batchId\n      createPoultryGrowerBatchFeedBlendInput: $createPoultryGrowerBatchFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateGrowerBatchFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateGrowerBatchFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryGrowerBatchFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePoultryGrowerBatchFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePoultryGrowerBatchFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersBatchFeedFragment on PoultryGrowerBatch {\n    id\n    pasture\n    grazingCrops\n    growers\n    layers\n    other\n    feedBlends {\n      id\n      amountPurchasedTonnes\n      blendType\n    }\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersBatchFeedFragment on PoultryGrowerBatch {\n    id\n    pasture\n    grazingCrops\n    growers\n    layers\n    other\n    feedBlends {\n      id\n      amountPurchasedTonnes\n      blendType\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n  ) {\n    poultryGrowerBatchFeedBreakdown(batchId: $batchId, feedType: $feedType) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n  ) {\n    poultryGrowerBatchFeedBreakdown(batchId: $batchId, feedType: $feedType) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryGrowerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n    $updatePoultryGrowerBatchFeedBreakdownInput: UpdatePoultryGrowerBatchFeedBreakdownInput!\n  ) {\n    updatePoultryGrowerBatchFeedBreakdown(\n      batchId: $batchId\n      feedType: $feedType\n      updatePoultryGrowerBatchFeedBreakdownInput: $updatePoultryGrowerBatchFeedBreakdownInput\n    ) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryGrowerBatchFeedBreakdown(\n    $batchId: ID!\n    $feedType: PoultryGrowerBatchFeedBreakdownType!\n    $updatePoultryGrowerBatchFeedBreakdownInput: UpdatePoultryGrowerBatchFeedBreakdownInput!\n  ) {\n    updatePoultryGrowerBatchFeedBreakdown(\n      batchId: $batchId\n      feedType: $feedType\n      updatePoultryGrowerBatchFeedBreakdownInput: $updatePoultryGrowerBatchFeedBreakdownInput\n    ) {\n      ...PoultryBroilersBatchFeedBreakdownFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersBatchDetailsFragment on PoultryGrowerBatchDetails {\n    totalNumberOfBirds\n    averageLengthOfStayDaysL1\n    averageLiveweightPerHeadKgW1\n    averageLengthOfStayDaysL2\n    averageLiveweightPerHeadKgW2\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersBatchDetailsFragment on PoultryGrowerBatchDetails {\n    totalNumberOfBirds\n    averageLengthOfStayDaysL1\n    averageLiveweightPerHeadKgW1\n    averageLengthOfStayDaysL2\n    averageLiveweightPerHeadKgW2\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersBatchFlockFragment on PoultryGrowerBatch {\n    growers\n    layers\n    other\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersBatchFlockFragment on PoultryGrowerBatch {\n    growers\n    layers\n    other\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatchFlockType(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n  ) {\n    poultryGrowerBatchDetails(batchId: $batchId, detailsType: $detailsType) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatchFlockType(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n  ) {\n    poultryGrowerBatchDetails(batchId: $batchId, detailsType: $detailsType) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryGrowerBatchFlockDetails(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n    $updatePoultryGrowerBatchDetailsInput: UpdatePoultryGrowerBatchDetailsInput!\n  ) {\n    updatePoultryGrowerBatchDetails(\n      batchId: $batchId\n      detailsType: $detailsType\n      updatePoultryGrowerBatchDetailsInput: $updatePoultryGrowerBatchDetailsInput\n    ) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryGrowerBatchFlockDetails(\n    $batchId: ID!\n    $detailsType: PoultryGrowerBatchDetailsType!\n    $updatePoultryGrowerBatchDetailsInput: UpdatePoultryGrowerBatchDetailsInput!\n  ) {\n    updatePoultryGrowerBatchDetails(\n      batchId: $batchId\n      detailsType: $detailsType\n      updatePoultryGrowerBatchDetailsInput: $updatePoultryGrowerBatchDetailsInput\n    ) {\n      ...PoultryBroilersBatchDetailsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersGrowerBatchLayoutFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersGrowerBatchLayoutFragment on PoultryGrowerBatch {\n    name\n    startDate\n    endDate\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryActivityInGrowerBatchHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryActivityInGrowerBatchHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderGrowerBatch\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatchHeader($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersGrowerBatchLayoutFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatchHeader($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersGrowerBatchLayoutFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatchFlock($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFlockFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatchFlock($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFlockFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatchFeed($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatchFeed($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryGrowerBatch(\n    $id: ID!\n    $updatePoultryGrowerBatchInput: UpdatePoultryGrowerBatchInput!\n  ) {\n    updatePoultryGrowerBatch(\n      id: $id\n      updatePoultryGrowerBatchInput: $updatePoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n      ...PoultryBroilersBatchFlockFragment\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryGrowerBatch(\n    $id: ID!\n    $updatePoultryGrowerBatchInput: UpdatePoultryGrowerBatchInput!\n  ) {\n    updatePoultryGrowerBatch(\n      id: $id\n      updatePoultryGrowerBatchInput: $updatePoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n      ...PoultryBroilersBatchFlockFragment\n      ...PoultryBroilersBatchFeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilers_BatchesFragment on PoultryBroilerActivity {\n    growerBatches {\n      id\n      name\n      startDate\n      endDate\n    }\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilers_BatchesFragment on PoultryBroilerActivity {\n    growerBatches {\n      id\n      name\n      startDate\n      endDate\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatch($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatch($id: ID!) {\n    poultryGrowerBatch(id: $id) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateGrowerBatch(\n    $poultryActivityId: ID!\n    $createPoultryGrowerBatchInput: CreatePoultryGrowerBatchInput!\n  ) {\n    createPoultryGrowerBatch(\n      poultryActivityId: $poultryActivityId\n      createPoultryGrowerBatchInput: $createPoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateGrowerBatch(\n    $poultryActivityId: ID!\n    $createPoultryGrowerBatchInput: CreatePoultryGrowerBatchInput!\n  ) {\n    createPoultryGrowerBatch(\n      poultryActivityId: $poultryActivityId\n      createPoultryGrowerBatchInput: $createPoultryGrowerBatchInput\n    ) {\n      id\n      ...PoultryBroilersBatchFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePoultryGrowerBatch($id: ID!) {\n    deletePoultryGrowerBatch(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePoultryGrowerBatch($id: ID!) {\n    deletePoultryGrowerBatch(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity on PoultryBroilerActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerBatches($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilers_BatchesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerBatches($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilers_BatchesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerTransactions($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersTransactionsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerTransactions($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersTransactionsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryActivityInHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryActivityInHeader($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderPoultryActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersManureLitterFragment on PoultryBroilerActivity {\n    percentManureAddedToPasture\n    percentSolidManure\n    percentageLitterRecycled\n    cyclesPerYear\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersManureLitterFragment on PoultryBroilerActivity {\n    percentManureAddedToPasture\n    percentSolidManure\n    percentageLitterRecycled\n    cyclesPerYear\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerManureLitter($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerManureLitter($id: ID!) {\n    poultryBroilerActivity(id: $id) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateBroilerManureLitter(\n    $id: ID!\n    $updatePoultryBroilerActivityInput: UpdatePoultryBroilerActivityInput!\n  ) {\n    updatePoultryBroilerActivity(\n      id: $id\n      updatePoultryBroilerActivityInput: $updatePoultryBroilerActivityInput\n    ) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateBroilerManureLitter(\n    $id: ID!\n    $updatePoultryBroilerActivityInput: UpdatePoultryBroilerActivityInput!\n  ) {\n    updatePoultryBroilerActivity(\n      id: $id\n      updatePoultryBroilerActivityInput: $updatePoultryBroilerActivityInput\n    ) {\n      id\n      ...PoultryBroilersManureLitterFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryBroilerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryBroilerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryBroilersTransactionsFragment on PoultryBroilerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n',
): (typeof documents)['\n  fragment PoultryBroilersTransactionsFragment on PoultryBroilerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryBroilerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryBroilerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateBroilerTransaction(\n    $poultryBroilerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryBroilerTransactionEvent(\n      poultryBroilerActivityId: $poultryBroilerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBroilerTransaction(\n    $poultryBroilerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryBroilerTransactionEvent(\n      poultryBroilerActivityId: $poultryBroilerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryBroilersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePoultryTransaction($id: ID!) {\n    deletePoultryTransactionEvent(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePoultryTransaction($id: ID!) {\n    deletePoultryTransactionEvent(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createPoultryBroilerActivity(\n    $propertyId: ID!\n    $createPoultryBroilerActivityInput: CreatePoultryBroilerActivityInput!\n  ) {\n    createPoultryBroilerActivity(\n      propertyId: $propertyId\n      createPoultryBroilerActivityInput: $createPoultryBroilerActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createPoultryBroilerActivity(\n    $propertyId: ID!\n    $createPoultryBroilerActivityInput: CreatePoultryBroilerActivityInput!\n  ) {\n    createPoultryBroilerActivity(\n      propertyId: $propertyId\n      createPoultryBroilerActivityInput: $createPoultryBroilerActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerEggSalesFragment on PoultryLayerActivity {\n    totalLayerEggsProduced\n    averageLayerEggWeightGrams\n    totalMeatChickenLayerEggsProduced\n    averageMeatChickenLayerEggWeightGrams\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerEggSalesFragment on PoultryLayerActivity {\n    totalLayerEggsProduced\n    averageLayerEggWeightGrams\n    totalMeatChickenLayerEggsProduced\n    averageMeatChickenLayerEggWeightGrams\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerEggSales($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerEggSales($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLayerEggSales(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLayerEggSales(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerEggSalesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerActivityFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerActivityFeedBlendFragment on PoultryFeedBlend {\n    blendType\n    barleyWhole\n    millRun\n    sorghum\n    soybean\n    wheat\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityFeedProduction\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerActivityFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerActivityFeedBlend($id: ID!) {\n    poultryFeedBlend(id: $id) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePoultryLayerFeedBlend(\n    $poultryLayerActivityId: ID!\n    $createPoultryLayerFeedBlendInput: CreatePoultryLayerFeedBlendInput!\n  ) {\n    createPoultryLayerFeedBlend(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryLayerFeedBlendInput: $createPoultryLayerFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePoultryLayerFeedBlend(\n    $poultryLayerActivityId: ID!\n    $createPoultryLayerFeedBlendInput: CreatePoultryLayerFeedBlendInput!\n  ) {\n    createPoultryLayerFeedBlend(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryLayerFeedBlendInput: $createPoultryLayerFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryLayerFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryLayerFeedBlend(\n    $id: ID!\n    $updatePoultryFeedBlendInput: UpdatePoultryFeedBlendInput!\n  ) {\n    updatePoultryFeedBlend(\n      id: $id\n      updatePoultryFeedBlendInput: $updatePoultryFeedBlendInput\n    ) {\n      id\n      ...PoultryLayerActivityFeedBlendFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePoultryLayerActivityFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePoultryLayerActivityFeedBlend($id: ID!) {\n    deletePoultryFeedBlend(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerFeedBlendsFragment on PoultryLayerActivity {\n    id\n    feedBlends {\n      id\n      blendType\n      amountPurchasedTonnes\n    }\n    pasture\n    grazingCrops\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerFeedBlendsFragment on PoultryLayerActivity {\n    id\n    feedBlends {\n      id\n      blendType\n      amountPurchasedTonnes\n    }\n    pasture\n    grazingCrops\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerFeedBlends($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerFeedBlends($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryLayerFeed(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryLayerFeed(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      ...PoultryLayerFeedBlendsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerActivityInfoFragment on PoultryLayerActivity {\n    poultryLayerMonthActivities {\n      ...PoultryLayerFlockFormMonthlyActivity\n    }\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerActivityInfoFragment on PoultryLayerActivity {\n    poultryLayerMonthActivities {\n      ...PoultryLayerFlockFormMonthlyActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerFlockFormMonthlyActivity on PoultryLayerMonthActivity {\n    id\n    month\n    year\n    layerCount\n    meatChickenLayerCount\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerFlockFormMonthlyActivity on PoultryLayerMonthActivity {\n    id\n    month\n    year\n    layerCount\n    meatChickenLayerCount\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updatePoultryLayerMonthActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePoultryLayerMonthActivityInput: UpdatePoultryLayerMonthActivityInput!\n    ) {\n      updatePoultryLayerMonthActivity(\n        id: $id\n        updatePoultryLayerMonthActivityInput: $updatePoultryLayerMonthActivityInput\n      ) {\n        id\n        ...PoultryLayerFlockFormMonthlyActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updatePoultryLayerMonthActivityForMonthlyBreakdown(\n      $id: ID!\n      $updatePoultryLayerMonthActivityInput: UpdatePoultryLayerMonthActivityInput!\n    ) {\n      updatePoultryLayerMonthActivity(\n        id: $id\n        updatePoultryLayerMonthActivityInput: $updatePoultryLayerMonthActivityInput\n      ) {\n        id\n        ...PoultryLayerFlockFormMonthlyActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerActivityHeaderFragment on PoultryLayerActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerActivityHeaderFragment on PoultryLayerActivity {\n    __typename\n    id\n    name\n    start\n    end\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerInfo($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerInfo($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerInHeader($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityHeaderFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerInHeader($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityHeaderFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerManureLitterFragment on PoultryLayerActivity {\n    percentManureAddedToPasture\n    percentageLitterRecycled\n    percentSolidManure\n    cyclesPerYear\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerManureLitterFragment on PoultryLayerActivity {\n    percentManureAddedToPasture\n    percentageLitterRecycled\n    percentSolidManure\n    cyclesPerYear\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerManureLitter($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerManureLitter($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateLayerManureLitter(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateLayerManureLitter(\n    $id: ID!\n    $updatePoultryLayerActivityInput: UpdatePoultryLayerActivityInput!\n  ) {\n    updatePoultryLayerActivity(\n      id: $id\n      updatePoultryLayerActivityInput: $updatePoultryLayerActivityInput\n    ) {\n      id\n      ...PoultryLayerManureLitterFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n',
): (typeof documents)['\n  fragment PoultryLayersTransactionFragment on PoultryTransactionEvent {\n    date\n    transactionType\n    class\n    date\n    livestockCount\n    totalWeightKg\n    averageWeightKg\n    percentPurchasedFreeRange\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePoultryLayerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePoultryLayerTransaction(\n    $id: ID!\n    $updatePoultryTransactionEventInput: UpdatePoultryTransactionEventInput!\n  ) {\n    updatePoultryTransactionEvent(\n      id: $id\n      updatePoultryTransactionEventInput: $updatePoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PoultryLayerActivityTransactionsFragment on PoultryLayerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n',
): (typeof documents)['\n  fragment PoultryLayerActivityTransactionsFragment on PoultryLayerActivity {\n    transactionEvents {\n      id\n      class\n      transactionType\n      date\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerTransactions($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityTransactionsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerTransactions($id: ID!) {\n    poultryLayerActivity(id: $id) {\n      id\n      ...PoultryLayerActivityTransactionsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPoultryLayerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPoultryLayerTransaction($id: ID!) {\n    poultryTransactionEvent(id: $id) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateLayerTransaction(\n    $poultryLayerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryLayerTransactionEvent(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateLayerTransaction(\n    $poultryLayerActivityId: ID!\n    $createPoultryTransactionEventInput: CreatePoultryTransactionEventInput!\n  ) {\n    createPoultryLayerTransactionEvent(\n      poultryLayerActivityId: $poultryLayerActivityId\n      createPoultryTransactionEventInput: $createPoultryTransactionEventInput\n    ) {\n      id\n      ...PoultryLayersTransactionFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createPoultryLayerActivity(\n    $propertyId: ID!\n    $createPoultryLayerActivityInput: CreatePoultryLayerActivityInput!\n  ) {\n    createPoultryLayerActivity(\n      propertyId: $propertyId\n      createPoultryLayerActivityInput: $createPoultryLayerActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createPoultryLayerActivity(\n    $propertyId: ID!\n    $createPoultryLayerActivityInput: CreatePoultryLayerActivityInput!\n  ) {\n    createPoultryLayerActivity(\n      propertyId: $propertyId\n      createPoultryLayerActivityInput: $createPoultryLayerActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UpdateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment UpdateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment CreateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n',
): (typeof documents)['\n  fragment CreateTransaction_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    ...TransactionForm_SheepMonthLivestockActivityFragment\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepMonthlyForm_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageEwesLambing\n    seasonalLambingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepMonthlyForm_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    previousMonthLiveweightKg\n    nonSalePopulationChange\n    percentageEwesLambing\n    seasonalLambingRate\n    keyEvents\n    headAtStart\n    livestockTransactionEvents {\n      id\n      transactionType\n      livestockCount\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment TransactionForm_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    year\n    month\n    sheepClass\n  }\n',
): (typeof documents)['\n  fragment TransactionForm_SheepMonthLivestockActivityFragment on SheepMonthLivestockActivity {\n    year\n    month\n    sheepClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateSheepMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateSheepMonthLivestockActivityForMonthlyBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation createSheepLivestockTransactionEventForUpdateTransaction(\n      $sheepMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createSheepLivestockTransactionEvent(\n        sheepMonthLivestockActivityId: $sheepMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  ',
): (typeof documents)['\n    mutation createSheepLivestockTransactionEventForUpdateTransaction(\n      $sheepMonthLivestockActivityId: ID!\n      $createLivestockTransactionEventInput: CreateLivestockTransactionEventInput!\n    ) {\n      createSheepLivestockTransactionEvent(\n        sheepMonthLivestockActivityId: $sheepMonthLivestockActivityId\n        createLivestockTransactionEventInput: $createLivestockTransactionEventInput\n      ) {\n        id\n        ...UpdateTransaction_LivestockTransactionEventFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivityForMonthlyBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepMonthlyBreakdown_SheepLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivityForMonthlyBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepMonthlyBreakdown_SheepLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetSheepMonthLivestockActivitiesForMonthlyBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetSheepMonthLivestockActivitiesForMonthlyBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepMonthlyBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetSheepMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      sheepMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_SheepMonthLivestockActivityFragment\n        ...UpdateTransaction_SheepMonthLivestockActivityFragment\n      }\n    }\n  ',
): (typeof documents)['\n    query GetSheepMonthLivestockActivityForCreateUpdateTransaction($id: ID!) {\n      sheepMonthLivestockActivity(id: $id) {\n        id\n        ...CreateTransaction_SheepMonthLivestockActivityFragment\n        ...UpdateTransaction_SheepMonthLivestockActivityFragment\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepMonthlyBreakdown_SheepLivestockActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n  }\n',
): (typeof documents)['\n  fragment SheepMonthlyBreakdown_SheepLivestockActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepMonthlyBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    ...SheepMonthlyForm_SheepMonthLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment SheepMonthlyBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n    ...SheepMonthlyForm_SheepMonthLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SheepActivityLivestockBreedingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Breeding\n      eweLambsLt1Breeding\n      otherEwesGt2Breeding\n      maidenBreedingEwes1To2Breeding\n      wetherLambsLt1Breeding\n      wethersGt1Breeding\n      ramsBreeding\n    }\n  ',
): (typeof documents)['\n    fragment SheepActivityLivestockBreedingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Breeding\n      eweLambsLt1Breeding\n      otherEwesGt2Breeding\n      maidenBreedingEwes1To2Breeding\n      wetherLambsLt1Breeding\n      wethersGt1Breeding\n      ramsBreeding\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment SheepActivityLivestockTradingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Trading\n      eweLambsLt1Trading\n      otherEwesGt2Trading\n      maidenBreedingEwes1To2Trading\n      wetherLambsLt1Trading\n      wethersGt1Trading\n      ramsTrading\n    }\n  ',
): (typeof documents)['\n    fragment SheepActivityLivestockTradingSheepLivestockActivity on SheepLivestockActivity {\n      ewesGt2Trading\n      eweLambsLt1Trading\n      otherEwesGt2Trading\n      maidenBreedingEwes1To2Trading\n      wetherLambsLt1Trading\n      wethersGt1Trading\n      ramsTrading\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepActivityLivestockSheepLivestockActivity on SheepLivestockActivity {\n    ...SheepActivityLivestockBreedingSheepLivestockActivity\n    ...SheepActivityLivestockTradingSheepLivestockActivity\n  }\n',
): (typeof documents)['\n  fragment SheepActivityLivestockSheepLivestockActivity on SheepLivestockActivity {\n    ...SheepActivityLivestockBreedingSheepLivestockActivity\n    ...SheepActivityLivestockTradingSheepLivestockActivity\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseSheepMonthlyFeedBreakdown_ActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    businessActivity {\n      id\n      state\n    }\n  }\n',
): (typeof documents)['\n  fragment UseSheepMonthlyFeedBreakdown_ActivityFragment on SheepLivestockActivity {\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    businessActivity {\n      id\n      state\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n      feedAvailabilityTonnesPerHectare\n    }\n  ',
): (typeof documents)['\n    fragment UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity on SheepMonthLivestockActivity {\n      id\n      month\n      year\n      crudeProteinPercentage\n      dryMatterDigestibilityPercentage\n      feedAvailabilityTonnesPerHectare\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    mutation updateSheepMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    mutation updateSheepMonthLivestockActivityForMonthlyFeedBreakdown(\n      $id: ID!\n      $updateSheepMonthLivestockActivityInput: UpdateSheepMonthLivestockActivityInput!\n    ) {\n      updateSheepMonthLivestockActivity(\n        id: $id\n        updateSheepMonthLivestockActivityInput: $updateSheepMonthLivestockActivityInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivityForMonthlyFeedBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...UseSheepMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivityForMonthlyFeedBreakdown($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...UseSheepMonthlyFeedBreakdown_ActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  ',
): (typeof documents)['\n    query GetSheepMonthLivestockActivitiesForMonthlyFeedBreakdown(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...UseSheepMonthlyFeedBreakdown_SheepMonthLivestockActivity\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepLivestockActivityFeedDrySeasonMix($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedDrySeasonMix_SheepLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepLivestockActivityFeedDrySeasonMix($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedDrySeasonMix_SheepLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepFeedDrySeasonMix_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepFeedDrySeasonMix_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedDrySeasonMix_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivityFeed($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockFeed_SheepLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivityFeed($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockFeed_SheepLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepActivityLivestockFeed_SheepLivestockActivityFragment on SheepLivestockActivity {\n    id\n    __typename\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepActivityLivestockFeed_SheepLivestockActivityFragment on SheepLivestockActivity {\n    id\n    __typename\n    ewesGt2Breeding\n    eweLambsLt1Breeding\n    otherEwesGt2Breeding\n    maidenBreedingEwes1To2Breeding\n    wetherLambsLt1Breeding\n    wethersGt1Breeding\n    ramsBreeding\n    ewesGt2Trading\n    eweLambsLt1Trading\n    otherEwesGt2Trading\n    maidenBreedingEwes1To2Trading\n    wetherLambsLt1Trading\n    wethersGt1Trading\n    ramsTrading\n    feed {\n      id\n      pasture\n      grazingCrops\n      mineralBlock\n      weanerBlock\n      drySeasonMix\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepLivestockActivityFeedMineralBlock($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedMineralBlock_SheepLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepLivestockActivityFeedMineralBlock($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedMineralBlock_SheepLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepFeedMineralBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepFeedMineralBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedMineralBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateSheepFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateSheepFeedWeaner($id: ID!, $updateFeedInput: UpdateFeedInput!) {\n    updateFeed(id: $id, updateFeedInput: $updateFeedInput) {\n      id\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepLivestockActivityFeedWeaner($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedWeanerBlock_SheepLivestockActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepLivestockActivityFeedWeaner($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepFeedWeanerBlock_SheepLivestockActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepFeedWeanerBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n',
): (typeof documents)['\n  fragment SheepFeedWeanerBlock_SheepLivestockActivityFragment on SheepLivestockActivity {\n    feed {\n      ...FeedWeanerBlock_FeedFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderSheepActivity on SheepLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderSheepActivity on SheepLivestockActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivity($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockSheepLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivity($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepActivityLivestockSheepLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetSheepMonthLivestockActivitiesForActivity(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepLivestockActivity_MonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetSheepMonthLivestockActivitiesForActivity(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepLivestockActivity_MonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivityInHeader($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderSheepActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivityInHeader($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderSheepActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation updateSheepLivestockActivity(\n    $id: ID!\n    $updateSheepLivestockActivityInput: UpdateSheepLivestockActivityInput!\n  ) {\n    updateSheepLivestockActivity(\n      id: $id\n      updateSheepLivestockActivityInput: $updateSheepLivestockActivityInput\n    ) {\n      id\n      ...SheepActivityLivestockBreedingSheepLivestockActivity\n      ...SheepActivityLivestockTradingSheepLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  mutation updateSheepLivestockActivity(\n    $id: ID!\n    $updateSheepLivestockActivityInput: UpdateSheepLivestockActivityInput!\n  ) {\n    updateSheepLivestockActivity(\n      id: $id\n      updateSheepLivestockActivityInput: $updateSheepLivestockActivityInput\n    ) {\n      id\n      ...SheepActivityLivestockBreedingSheepLivestockActivity\n      ...SheepActivityLivestockTradingSheepLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepWoolShearingEventsFragment on SheepShearingEvent {\n    id\n    month\n    year\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n',
): (typeof documents)['\n  fragment SheepWoolShearingEventsFragment on SheepShearingEvent {\n    id\n    month\n    year\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepShearingEvents($activityId: ID!) {\n    shearingEventsBySheepLivestockActivityId(\n      sheepLivestockActivityId: $activityId\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepShearingEvents($activityId: ID!) {\n    shearingEventsBySheepLivestockActivityId(\n      sheepLivestockActivityId: $activityId\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepShearingEvent($id: ID!) {\n    sheepShearingEvent(id: $id) {\n      id\n      ...WoolUpdateSheepShearingEventFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepShearingEvent($id: ID!) {\n    sheepShearingEvent(id: $id) {\n      id\n      ...WoolUpdateSheepShearingEventFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateSheepShearingEvent(\n    $sheepLivestockActivityId: ID!\n    $input: CreateSheepShearingEventInput!\n  ) {\n    createSheepShearingEvent(\n      sheepLivestockActivityId: $sheepLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateSheepShearingEvent(\n    $sheepLivestockActivityId: ID!\n    $input: CreateSheepShearingEventInput!\n  ) {\n    createSheepShearingEvent(\n      sheepLivestockActivityId: $sheepLivestockActivityId\n      input: $input\n    ) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateSheepShearingEvent(\n    $id: ID!\n    $input: UpdateSheepShearingEventInput!\n  ) {\n    updateSheepShearingEvent(id: $id, input: $input) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateSheepShearingEvent(\n    $id: ID!\n    $input: UpdateSheepShearingEventInput!\n  ) {\n    updateSheepShearingEvent(id: $id, input: $input) {\n      id\n      ...SheepWoolShearingEventsFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteSheepShearingEvent($id: ID!) {\n    deleteSheepShearingEvent(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteSheepShearingEvent($id: ID!) {\n    deleteSheepShearingEvent(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetSheepActivityForShearing($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepShearingEventSheepLivestockActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetSheepActivityForShearing($id: ID!) {\n    sheepLivestockActivity(id: $id) {\n      id\n      ...SheepShearingEventSheepLivestockActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    query GetSheepMonthLivestockActivitiesForShearing(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepShearingEventMonthActivities\n      }\n    }\n  ',
): (typeof documents)['\n    query GetSheepMonthLivestockActivitiesForShearing(\n      $listSheepMonthLivestockActivitiesInput: ListSheepMonthLivestockActivitiesInput!\n    ) {\n      sheepMonthLivestockActivities(\n        listSheepMonthLivestockActivitiesInput: $listSheepMonthLivestockActivitiesInput\n      ) {\n        id\n        ...SheepShearingEventMonthActivities\n      }\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WoolUpdateSheepShearingEventFragment on SheepShearingEvent {\n    id\n    date\n    sheepClass\n    livestockCategory\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n',
): (typeof documents)['\n  fragment WoolUpdateSheepShearingEventFragment on SheepShearingEvent {\n    id\n    date\n    sheepClass\n    livestockCategory\n    numberSheepShorn\n    woolPerHeadKg\n    cleanWoolYieldKg\n    sheepLivestockActivityId\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepShearingEventSheepLivestockActivity on SheepLivestockActivity {\n    __typename\n    start\n    end\n  }\n',
): (typeof documents)['\n  fragment SheepShearingEventSheepLivestockActivity on SheepLivestockActivity {\n    __typename\n    start\n    end\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepShearingEventMonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    sheepClass\n    livestockCategory\n  }\n',
): (typeof documents)['\n  fragment SheepShearingEventMonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    headAtStart\n    sheepClass\n    livestockCategory\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment SheepLivestockActivity_MonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    sheepClass\n  }\n',
): (typeof documents)['\n  fragment SheepLivestockActivity_MonthActivities on SheepMonthLivestockActivity {\n    id\n    month\n    year\n    averageLiveweightKg\n    headAtStart\n    sheepClass\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createSheepLivestockActivity(\n    $propertyId: ID!\n    $createSheepLivestockActivityInput: CreateSheepLivestockActivityInput!\n  ) {\n    createSheepLivestockActivity(\n      propertyId: $propertyId\n      createSheepLivestockActivityInput: $createSheepLivestockActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createSheepLivestockActivity(\n    $propertyId: ID!\n    $createSheepLivestockActivityInput: CreateSheepLivestockActivityInput!\n  ) {\n    createSheepLivestockActivity(\n      propertyId: $propertyId\n      createSheepLivestockActivityInput: $createSheepLivestockActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment VegetationInfoFragment on VegetationActivity {\n    name\n    region\n    soilType\n    species\n    averageAge\n    treesPerHectare\n    totalPlantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n',
): (typeof documents)['\n  fragment VegetationInfoFragment on VegetationActivity {\n    name\n    region\n    soilType\n    species\n    averageAge\n    treesPerHectare\n    totalPlantingAreaSquareMetres\n    plantingAreaGeometry\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity on VegetationActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity on VegetationActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createVegetationActivity(\n    $propertyId: ID!\n    $createVegetationActivityInput: CreateVegetationActivityInput!\n  ) {\n    createVegetationActivity(\n      propertyId: $propertyId\n      createVegetationActivityInput: $createVegetationActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createVegetationActivity(\n    $propertyId: ID!\n    $createVegetationActivityInput: CreateVegetationActivityInput!\n  ) {\n    createVegetationActivity(\n      propertyId: $propertyId\n      createVegetationActivityInput: $createVegetationActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateVegetation(\n    $id: ID!\n    $updateVegetationActivityInput: UpdateVegetationActivityInput!\n  ) {\n    updateVegetationActivity(\n      id: $id\n      updateVegetationActivityInput: $updateVegetationActivityInput\n    ) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateVegetation(\n    $id: ID!\n    $updateVegetationActivityInput: UpdateVegetationActivityInput!\n  ) {\n    updateVegetationActivity(\n      id: $id\n      updateVegetationActivityInput: $updateVegetationActivityInput\n    ) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetVegetationInfo($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetVegetationInfo($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...VegetationInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetVegetationActivityInHeader($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetVegetationActivityInHeader($id: ID!) {\n    vegetationActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderVegetationActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryBaitBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryBaitBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBaitPurchases($activityId: ID!) {\n    baitPurchasesByWildSeaFisheryActivityId(\n      wildSeaFisheryActivityId: $activityId\n    ) {\n      id\n      ...WildSeaFisheryBaitBaitPurchasesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBaitPurchases($activityId: ID!) {\n    baitPurchasesByWildSeaFisheryActivityId(\n      wildSeaFisheryActivityId: $activityId\n    ) {\n      id\n      ...WildSeaFisheryBaitBaitPurchasesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBaitPurchase($id: ID!) {\n    fisheryBaitPurchase(id: $id) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBaitPurchase($id: ID!) {\n    fisheryBaitPurchase(id: $id) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateBaitPurchase(\n    $wildSeaFisheryActivityId: ID!\n    $input: CreateFisheryBaitPurchaseInput!\n  ) {\n    createFisheryBaitPurchase(\n      wildSeaFisheryActivityId: $wildSeaFisheryActivityId\n      input: $input\n    ) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBaitPurchase(\n    $wildSeaFisheryActivityId: ID!\n    $input: CreateFisheryBaitPurchaseInput!\n  ) {\n    createFisheryBaitPurchase(\n      wildSeaFisheryActivityId: $wildSeaFisheryActivityId\n      input: $input\n    ) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateBaitPurchase(\n    $id: ID!\n    $input: UpdateFisheryBaitPurchaseInput!\n  ) {\n    updateFisheryBaitPurchase(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateBaitPurchase(\n    $id: ID!\n    $input: UpdateFisheryBaitPurchaseInput!\n  ) {\n    updateFisheryBaitPurchase(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryBaitPurchasesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteBaitPurchase($id: ID!) {\n    deleteFisheryBaitPurchase(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteBaitPurchase($id: ID!) {\n    deleteFisheryBaitPurchase(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryBaitPurchasesFragment on FisheryBaitPurchase {\n    id\n    baitType\n    amountPurchasedTonnes\n    percentageAdditionalIngredients\n    emissionsIntensityBaitProduct\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryActivityInfoFragment on WildSeaFisheryActivity {\n    id\n    productionSystem\n    totalWeightCaughtKg\n    offsetsPurchasedTonnes\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryActivityInfoFragment on WildSeaFisheryActivity {\n    id\n    productionSystem\n    totalWeightCaughtKg\n    offsetsPurchasedTonnes\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetWildSeaFisheryInfo($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetWildSeaFisheryInfo($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateWildSeaFisheryActivityInfo(\n    $id: ID!\n    $updateWildSeaFisheryActivityInput: UpdateWildSeaFisheryActivityInput!\n  ) {\n    updateWildSeaFisheryActivity(\n      id: $id\n      updateWildSeaFisheryActivityInput: $updateWildSeaFisheryActivityInput\n    ) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateWildSeaFisheryActivityInfo(\n    $id: ID!\n    $updateWildSeaFisheryActivityInput: UpdateWildSeaFisheryActivityInput!\n  ) {\n    updateWildSeaFisheryActivity(\n      id: $id\n      updateWildSeaFisheryActivityInput: $updateWildSeaFisheryActivityInput\n    ) {\n      id\n      ...WildSeaFisheryActivityInfoFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n    fragment PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity on WildSeaFisheryActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  ',
): (typeof documents)['\n    fragment PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity on WildSeaFisheryActivity {\n      __typename\n      id\n      name\n      start\n      end\n    }\n  '];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryTransportRoutesFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryTransportRoutesFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetTransportRoutes($activityId: ID!) {\n    routesByActivityId(activityId: $activityId) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetTransportRoutes($activityId: ID!) {\n    routesByActivityId(activityId: $activityId) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetTransportRoute($id: ID!) {\n    route(id: $id) {\n      id\n      ...WildSeaFisheryTransportRouteFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetTransportRoute($id: ID!) {\n    route(id: $id) {\n      id\n      ...WildSeaFisheryTransportRouteFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateTransportRoute($activityId: ID!, $input: CreateRouteInput!) {\n    createRoute(activityId: $activityId, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateTransportRoute($activityId: ID!, $input: CreateRouteInput!) {\n    createRoute(activityId: $activityId, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateTransportRoute($id: ID!, $input: UpdateRouteInput!) {\n    updateRoute(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateTransportRoute($id: ID!, $input: UpdateRouteInput!) {\n    updateRoute(id: $id, input: $input) {\n      id\n      ...WildSeaFisheryTransportRoutesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteTransportRoute($id: ID!) {\n    deleteRoute(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteTransportRoute($id: ID!) {\n    deleteRoute(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetFisheryActivityForTransport($wildSeaFisheryId: ID!) {\n    wildSeaFisheryActivity(id: $wildSeaFisheryId) {\n      id\n      ...WildSeaFisheryActivityForTransportFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetFisheryActivityForTransport($wildSeaFisheryId: ID!) {\n    wildSeaFisheryActivity(id: $wildSeaFisheryId) {\n      id\n      ...WildSeaFisheryActivityForTransportFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryTransportRouteFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryTransportRouteFragment on Route {\n    id\n    startPoint\n    endPoint\n    vehicleType\n    fuelType\n    distanceStartToEndKm\n    numberOfTrips\n    totalDistanceTravelledKm\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment WildSeaFisheryActivityForTransportFragment on WildSeaFisheryActivity {\n    id\n    start\n    end\n  }\n',
): (typeof documents)['\n  fragment WildSeaFisheryActivityForTransportFragment on WildSeaFisheryActivity {\n    id\n    start\n    end\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation createWildSeaFisheryActivity(\n    $propertyId: ID!\n    $createWildSeaFisheryActivityInput: CreateWildSeaFisheryActivityInput!\n  ) {\n    createWildSeaFisheryActivity(\n      propertyId: $propertyId\n      createWildSeaFisheryActivityInput: $createWildSeaFisheryActivityInput\n    ) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation createWildSeaFisheryActivity(\n    $propertyId: ID!\n    $createWildSeaFisheryActivityInput: CreateWildSeaFisheryActivityInput!\n  ) {\n    createWildSeaFisheryActivity(\n      propertyId: $propertyId\n      createWildSeaFisheryActivityInput: $createWildSeaFisheryActivityInput\n    ) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetWildSeaFisheryActivityInHeader($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity\n    }\n  }\n',
): (typeof documents)['\n  query GetWildSeaFisheryActivityInHeader($id: ID!) {\n    wildSeaFisheryActivity(id: $id) {\n      id\n      ...PropertiesBusinessActivitiesActivitiesHeaderWildSeaFisheryActivity\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EditPropertyPage_PropertyFragment on Property {\n    id\n    name\n    addressLine1\n    addressLine2\n    city\n    state\n    postcode\n    country\n  }\n',
): (typeof documents)['\n  fragment EditPropertyPage_PropertyFragment on Property {\n    id\n    name\n    addressLine1\n    addressLine2\n    city\n    state\n    postcode\n    country\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment EditPropertyPage_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    propertyNorthOfTropicOfCapricorn\n    rainfallAbove600\n    state\n  }\n',
): (typeof documents)['\n  fragment EditPropertyPage_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    propertyNorthOfTropicOfCapricorn\n    rainfallAbove600\n    state\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateProperty(\n    $id: ID!\n    $updatePropertyInput: UpdatePropertyInput!\n  ) {\n    updateProperty(id: $id, input: $updatePropertyInput) {\n      id\n      ...EditPropertyPage_PropertyFragment\n      ...PropertyLayoutParcelGeometries_PropertyFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateProperty(\n    $id: ID!\n    $updatePropertyInput: UpdatePropertyInput!\n  ) {\n    updateProperty(id: $id, input: $updatePropertyInput) {\n      id\n      ...EditPropertyPage_PropertyFragment\n      ...PropertyLayoutParcelGeometries_PropertyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateBusinessActivity(\n    $updateBusinessActivityInput: UpdateBusinessActivityInput!\n  ) {\n    updateBusinessActivity(input: $updateBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateBusinessActivity(\n    $updateBusinessActivityInput: UpdateBusinessActivityInput!\n  ) {\n    updateBusinessActivity(input: $updateBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyForEdit($id: ID!) {\n    property(id: $id) {\n      id\n      ...EditPropertyPage_PropertyFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyForEdit($id: ID!) {\n    property(id: $id) {\n      id\n      ...EditPropertyPage_PropertyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBusinessActivityForProperty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBusinessActivityForProperty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteProperty($id: ID!) {\n    deleteProperty(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteProperty($id: ID!) {\n    deleteProperty(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertiesFilesFileFragment on File {\n    id\n    name\n    description\n    uploadStatus\n    extension\n    mimeType\n    bytes\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertiesFilesFileFragment on File {\n    id\n    name\n    description\n    uploadStatus\n    extension\n    mimeType\n    bytes\n    createdAt\n    creator {\n      firstName\n      lastName\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyFile($id: ID!) {\n    file(id: $id) {\n      id\n      uploadStatus\n      name\n      ...PropertiesFilesFileFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyFile($id: ID!) {\n    file(id: $id) {\n      id\n      uploadStatus\n      name\n      ...PropertiesFilesFileFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePropertyFile($id: ID!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePropertyFile($id: ID!) {\n    deleteFile(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyFileForUploadFragment on FileForUpload {\n    presignedPostUrl\n    presignedPostFields {\n      key\n      value\n    }\n    file {\n      id\n      name\n      description\n      bytes\n      createdAt\n      updatedAt\n      extension\n      signedUrl\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyFileForUploadFragment on FileForUpload {\n    presignedPostUrl\n    presignedPostFields {\n      key\n      value\n    }\n    file {\n      id\n      name\n      description\n      bytes\n      createdAt\n      updatedAt\n      extension\n      signedUrl\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyFilesFragment on Property {\n    files {\n      id\n      name\n      description\n      uploadStatus\n      bytes\n      extension\n      createdAt\n      updatedAt\n      signedUrl\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyFilesFragment on Property {\n    files {\n      id\n      name\n      description\n      uploadStatus\n      bytes\n      extension\n      createdAt\n      updatedAt\n      signedUrl\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyFiles($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyFilesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyFiles($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyFilesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePropertyFile($createFileInput: CreateFileInput!) {\n    createPropertyFile(input: $createFileInput) {\n      ...PropertyFileForUploadFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePropertyFile($createFileInput: CreateFileInput!) {\n    createPropertyFile(input: $createFileInput) {\n      ...PropertyFileForUploadFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePropertyFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      id\n      ...PropertiesFilesFileFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePropertyFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      id\n      ...PropertiesFilesFileFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyPage_PropertyFragment on Property {\n    name\n  }\n',
): (typeof documents)['\n  fragment PropertyPage_PropertyFragment on Property {\n    name\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetProperty($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyPage_PropertyFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetProperty($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyPage_PropertyFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetBusinessActivityForProeprty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...UseBusinessActivityForProperty_BusinessActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetBusinessActivityForProeprty($propertyId: ID!) {\n    businessActivityForProperty(propertyId: $propertyId) {\n      id\n      ...UseBusinessActivityForProperty_BusinessActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseBusinessActivityForProperty_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    state\n  }\n',
): (typeof documents)['\n  fragment UseBusinessActivityForProperty_BusinessActivityFragment on BusinessActivity {\n    id\n    enabledActivityTypes\n    state\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MapShapesEditIndex_MapShapeFragment on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n',
): (typeof documents)['\n  fragment MapShapesEditIndex_MapShapeFragment on MapShape {\n    id\n    type\n    name\n    geometry\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetMapShape($id: ID!) {\n    mapShape(id: $id) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetMapShape($id: ID!) {\n    mapShape(id: $id) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdateMapShape($id: ID!, $input: UpdateMapShapeInput!) {\n    updateMapShape(id: $id, input: $input) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdateMapShape($id: ID!, $input: UpdateMapShapeInput!) {\n    updateMapShape(id: $id, input: $input) {\n      id\n      ...MapShapesEditIndex_MapShapeFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeleteMapShape($id: ID!) {\n    deleteMapShape(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeleteMapShape($id: ID!) {\n    deleteMapShape(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment MapShapesIndex_MapShape on MapShape {\n    geometry\n    name\n    ...MapShapesList_MapShape\n  }\n',
): (typeof documents)['\n  fragment MapShapesIndex_MapShape on MapShape {\n    geometry\n    name\n    ...MapShapesList_MapShape\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetMapShapes($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n',
): (typeof documents)['\n  query GetMapShapes($propertyId: ID!) {\n    mapShapes(propertyId: $propertyId) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateMapShape($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateMapShape($input: CreateMapShapeInput!) {\n    createMapShape(input: $input) {\n      id\n      ...MapShapesIndex_MapShape\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertiesNotesNoteFragment on PropertyNote {\n    id\n    title\n    text\n  }\n',
): (typeof documents)['\n  fragment PropertiesNotesNoteFragment on PropertyNote {\n    id\n    title\n    text\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyNote($id: ID!) {\n    propertyNote(id: $id) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyNote($id: ID!) {\n    propertyNote(id: $id) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation UpdatePropertyNote($id: ID!, $input: UpdatePropertyNoteInput!) {\n    updatePropertyNote(id: $id, input: $input) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation UpdatePropertyNote($id: ID!, $input: UpdatePropertyNoteInput!) {\n    updatePropertyNote(id: $id, input: $input) {\n      id\n      ...PropertiesNotesNoteFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation DeletePropertyNote($id: ID!) {\n    deletePropertyNote(id: $id) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation DeletePropertyNote($id: ID!) {\n    deletePropertyNote(id: $id) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment PropertyNotesFragment on Property {\n    propertyNotes {\n      id\n      title\n      text\n      createdAt\n      updatedAt\n    }\n  }\n',
): (typeof documents)['\n  fragment PropertyNotesFragment on Property {\n    propertyNotes {\n      id\n      title\n      text\n      createdAt\n      updatedAt\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertyNotes($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyNotesFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertyNotes($id: ID!) {\n    property(id: $id) {\n      id\n      ...PropertyNotesFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreatePropertyNote(\n    $createPropertyNoteInput: CreatePropertyNoteInput!\n  ) {\n    createPropertyNote(input: $createPropertyNoteInput) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreatePropertyNote(\n    $createPropertyNoteInput: CreatePropertyNoteInput!\n  ) {\n    createPropertyNote(input: $createPropertyNoteInput) {\n      id\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment AllTeamPropertiesPageProperty on Property {\n    id\n    name\n    addressLine1\n    city\n    state\n    createdAt\n    updatedAt\n    centroid\n    createdBy\n  }\n',
): (typeof documents)['\n  fragment AllTeamPropertiesPageProperty on Property {\n    id\n    name\n    addressLine1\n    city\n    state\n    createdAt\n    updatedAt\n    centroid\n    createdBy\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetProperties($teamId: ID!) {\n    properties(teamId: $teamId) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n',
): (typeof documents)['\n  query GetProperties($teamId: ID!) {\n    properties(teamId: $teamId) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertiesWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesSearchByPage(teamId: $teamId, searchPage: $searchPage) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n',
): (typeof documents)['\n  query GetPropertiesWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesSearchByPage(teamId: $teamId, searchPage: $searchPage) {\n      id\n      ...AllTeamPropertiesPageProperty\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetPropertiesCountWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesCount(teamId: $teamId, searchPage: $searchPage)\n  }\n',
): (typeof documents)['\n  query GetPropertiesCountWithSearch(\n    $teamId: ID!\n    $searchPage: PropertySearchByPageInput!\n  ) {\n    propertiesCount(teamId: $teamId, searchPage: $searchPage)\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateBusinessActivity(\n    $createBusinessActivityInput: CreateBusinessActivityInput!\n  ) {\n    createBusinessActivity(input: $createBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateBusinessActivity(\n    $createBusinessActivityInput: CreateBusinessActivityInput!\n  ) {\n    createBusinessActivity(input: $createBusinessActivityInput) {\n      id\n      ...EditPropertyPage_BusinessActivityFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetParcelForUseEditPropertyBoundary($id: ID!) {\n    parcel(id: $id) {\n      id\n      ...UseEditPropertyBoundary_ParcelFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetParcelForUseEditPropertyBoundary($id: ID!) {\n    parcel(id: $id) {\n      id\n      ...UseEditPropertyBoundary_ParcelFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query GetGeographicInformationForPointForUseEditPropertyBoundary(\n    $point: GeoJSONPoint!\n  ) {\n    geographicInformationForPoint(point: $point) {\n      ...UseEditPropertyBoundary_GeographicInformationFragment\n    }\n  }\n',
): (typeof documents)['\n  query GetGeographicInformationForPointForUseEditPropertyBoundary(\n    $point: GeoJSONPoint!\n  ) {\n    geographicInformationForPoint(point: $point) {\n      ...UseEditPropertyBoundary_GeographicInformationFragment\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  query SearchParcels($input: ParcelSearchInput!) {\n    searchParcels(input: $input) {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  query SearchParcels($input: ParcelSearchInput!) {\n    searchParcels(input: $input) {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseEditPropertyBoundary_ParcelFragment on Parcel {\n    id\n    geoFeature {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n',
): (typeof documents)['\n  fragment UseEditPropertyBoundary_ParcelFragment on Parcel {\n    id\n    geoFeature {\n      id\n      type\n      geometry\n      properties {\n        id\n        name\n      }\n    }\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  fragment UseEditPropertyBoundary_GeographicInformationFragment on GeographicInformation {\n    state\n  }\n',
): (typeof documents)['\n  fragment UseEditPropertyBoundary_GeographicInformationFragment on GeographicInformation {\n    state\n  }\n'];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(
  source: '\n  mutation CreateProperty($createPropertyInput: CreatePropertyInput!) {\n    createProperty(input: $createPropertyInput) {\n      id\n    }\n  }\n',
): (typeof documents)['\n  mutation CreateProperty($createPropertyInput: CreatePropertyInput!) {\n    createProperty(input: $createPropertyInput) {\n      id\n    }\n  }\n'];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
